<template>
        <Header></Header>
        <div class="pageTitle" > 
            <font-awesome-icon icon="caret-right" /> 
            Gestion des acheteurs
        </div>
        
        
    <div class="pageBodyContainer">
        <LoadPdfScore ref="LoadPdfScore"></LoadPdfScore> 

        <Dialog v-model:visible="openOmpicDialog" header="Import Ompic global" style="width:1024px">
            <ImportOmpicGlobal></ImportOmpicGlobal>
        </Dialog>

        <Dialog v-model:visible="showHistoriqueBilan" 
            :header="'Historique bilans - '+selectedGridItem.raisonSociale" 
            :modal="true" class="p-fluid"
            :closable="true">  
            <HistoriqueBilanSociete :key="selectedGridItem.id" :selectedSociete="selectedGridItem"></HistoriqueBilanSociete>
        </Dialog>
        
        <div>
            <div class="card">
                <DataTable ref="dt" :value="gridData"  dataKey="id"
                    style="font-size:0.8rem"
                    :paginator="true" 
                    :rows="10"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10,50,100]"
                    currentPageReportTemplate="Lignes de {first} à {last} sur un total de {totalRecords}" 

                    :filters="filters" class="p-datatable-sm"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row">
                            <div style="display: flex;">
                                <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                    <P-Button v-if="currentUser.jsonUserRights.gestionAcheteurs"  label="Nouvel acheteur" icon="pi pi-plus" class="p-button-outlined p-button-success p-mr-2" @click="openEditDialog({id:0})" />
                                </h5>
                                <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                    <ClientBilanLoadCsv style="margin-left:5px" v-if="currentUser.jsonUserRights.gestionAcheteurs"  @BilanLoaded="onBilanLoaded"></ClientBilanLoadCsv>
                                </h5>
                                <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                    &nbsp;<P-Button label="Télécharger modèle" v-if="currentUser.jsonUserRights.gestionAcheteurs"  class="p-button-outlined p-button-success p-mr-2" @click = "downloadItem('/societe.xlsx')"  icon="pi pi-question-circle" v-tooltip="'Télécharger un modèle XLSX pour l\'import'"></P-Button>
                                </h5>
                                <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                    &nbsp;<P-Button label="Export Excel" v-if="currentUser.jsonUserRights.gestionAcheteurs"  class="p-button-outlined p-button-success p-mr-2" @click = "exportData"  icon="pi pi-file-excel" v-tooltip="'Export xls'"></P-Button>
                                </h5>
                                <h5 class="p-mb-2 p-m-md-0 p-as-md-center" :tooltip="ompicActivated?'Importer tout les ompics téléchargés liés à votre client':'La fonctionnalité Ompic n\'est pas activée sur votre compte'">
                                    &nbsp;<P-Button label="Import global Ompic" :disabled="!ompicActivated"  class="p-button-outlined p-button-success p-mr-2" @click = "openOmpicDialog = true"  icon="pi pi-arrow-up-right" ></P-Button>
                                </h5>

                                
                                <!-- <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                    &nbsp;<P-Button  label="Sources externes" icon="pi pi-external-link" class="p-button-outlined p-button-success p-mr-2" @click="openSourcesExternes()" />
                                </h5> -->
                                <span class="p-input-icon-left" style="margin-left:20px">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Recherche..." />
                                </span>
                            </div>
                            <!-- <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Recherche..." />
                            </span> -->
                        </div>
                    </template>

                    <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column> -->
                    <Column :exportable="false" style="width:120px;" >
                        <template #body="slotProps">
                            <P-Button v-if="currentUser.jsonUserRights.gestionAcheteurs" icon="pi pi-pencil" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click="openEditDialog(slotProps.data)" />
                     
                            <P-Button v-if="currentUser.jsonUserRights.gestionAcheteurs" icon="pi pi-trash" class="p-button-outlined p-button-rounded p-button-danger" @click.prevent="confirmDeleteSelected(slotProps.data)" />
                        </template>
                    </Column>
                    <Column field="raisonSociale" header="Raison sociale" :sortable="true" style="min-width:5rem">
                    <template #body="slotProps">
                        {{slotProps.data.groupe?slotProps.data.raisonSociale+"("+slotProps.data.groupe+")":slotProps.data.raisonSociale}}
                    </template>
                    </Column>
                    <Column field="ice" header="ICE" :sortable="true" style="min-width:8rem"></Column>
                   
                    <Column header="Ville" :sortable="true" style="min-width:6rem">
                     <template #body="slotProps">
                        <div v-if="slotProps.data.ville">
                            {{slotProps.data.ville.nomVille}} / {{slotProps.data.ville.nomVilleArabe}}
                        </div>
                     </template>
                    </Column>
                    <Column field="codeInterne" header="Code interne" :sortable="true" style="min-width:5rem"></Column>

                    <Column field="prospect" header="Prospect" :sortable="true" style="min-width:5rem">
                    <template #body="slotProps">
                        {{slotProps.data.prospect?"Prospect":"Client"}}
                    </template>
                    </Column>
                    <!-- <Column field="createur" header="Createur" :sortable="true" style="min-width:16rem"></Column> -->
                    <!-- <Column field="dateCreation" header="Date création" :sortable="true" style="min-width:8rem" > 
                    <template #body="slotProps">
                        {{slotProps.data.dateCreation?this.formatDate(slotProps.data.dateCreation):""}}
                    </template>
                    </Column> -->

                    <!-- <Column :exportable="false"  style="min-width:2rem" class="hasSeparator2 p-mx-auto">
                        <template #body="slotProps">
                            <P-Button v-if="slotProps.data.dernierScore" icon="pi pi-print" class="p-button-outlined p-button-rounded" @click.prevent="scorePdf(slotProps.data.dernierScore.id)" />
                        </template>
                    </Column> -->
                    
                    <!-- Dernier Score -->
                    <Column field="dernierScore.score" header="Dernier score publié" :sortable="false" style="min-width:5rem; width:150px" class="p-text-center hasSeparator2 p-align-center" >
                    <template #body="slotProps">
                        <div class="tdSeparator p-ml-4" style="display:flex;justify-content: center;">
                            <div>
                                <P-Button v-if="slotProps.data.dernierScore" icon="pi pi-print" class="p-button-outlined p-button-rounded p-mr-2" @click.prevent="scorePdf(slotProps.data.dernierScore.id)" />
                            </div>
                            &nbsp;
                            <div style="margin-top:6%;font-weight:bold" >
                                {{slotProps.data.dernierScore?slotProps.data.dernierScore.score+'/10':""}}
                            </div>
                        </div>
                    </template>
                    </Column>

                    <Column field="dernierScore.dateValidite" header="Date validité" :sortable="false" style="min-width:6rem" class="hasSeparator1"> 
                    <template #body="slotProps">
                        <div :style="{color:dateValiditeColor(slotProps.data.dernierScore)}">{{slotProps.data.dernierScore?this.formatDate(slotProps.data.dernierScore.dateValidite):""}}</div>
                    </template>
                    </Column>

                    <!-- Dernier Bilan -->
                    <Column field="dernierBilan1.dateBilan" header="Derniers bilans" :sortable="false" style="min-width:4rem;" class="hasSeparator2"> 
                    <template #body="slotProps">
                        {{slotProps.data.dernierBilan1?slotProps.data.dernierBilan1.dateBilan:""}} 
                        {{slotProps.data.dernierBilan2?" - " +slotProps.data.dernierBilan2.dateBilan:""}}
                        <span :tooltip="'Consulter les bilans du client '+ slotProps.data.raisonSociale" v-if="slotProps.data.dernierBilan1">
                            <i style="cursor:pointer" 
                            class="pi pi-search-plus" 
                            @click="showHistoriqueBilanModal(slotProps.data)">
                            </i>
                        </span>
                    </template>
                    </Column>


                  
                    <Column  class="hasSeparator2" style="min-width:8rem" header="">
                        <template #body="slotProps">
                            <div :id="'ButtonClickedId_'+slotProps.data.id">
                                <P-Button v-if="currentUser.jsonUserRights.scoringQualitatif || currentUser.jsonUserRights.scoringFinancier" style="font-size: 0.8rem;" class="p-button" label="Nouveau Score" icon="pi pi-star" @click="showScoring(slotProps.data.id)"/>
                                <SplitButton v-if="currentUser.jsonUserRights.gestionBilan" class='splitButtonAdd' label="Nouveau Bilan" icon="pi pi-plus" @click="openEdit(slotProps.data)" :model="items" style="min-width:8rem;"></SplitButton>
                            </div>
                        </template>
                    </Column>
                    
                    <template #footer>
                        Total {{gridData.length}}
                    </template>
                </DataTable>
            </div>

        </div>

        <!-- Delete Dialog -->
        
        
        <ClientBilanDialog :inforisk="inforisk" :ompic="ompic" :key="showBilanDialogFlag" ref="clientBilanDialog" :societe="selectedGridItem" :selectedBilanItemProp="selectedBilanItem" :showBilanDialogFlag="showBilanDialogFlag" @saved="savedBilanDialog" @close="closeBilanDialog"></ClientBilanDialog>
        <!-- Edit Dialog -->
        <Dialog v-model:visible="showEditDialogFlag" 
            :style="{width: '450px'}" 
            :header="(selectedGridItem.id)?'Edition':'Création d\'une nouvelle societe'" 
            :modal="true" class="p-fluid"
            @hide="closeEditDialog"
        >    
            <V-Form @submit="onSubmit" :validation-schema="myValidationSchema" autocomplete="off">
                <div class="p-field">
                    <label for="raisonSociale">Raison sociale</label>
                    <V-Field as="InputText" name="raisonSociale" v-model.trim="selectedGridItem.raisonSociale"    />
                    <ErrorMessage name="raisonSociale" class="error-feedback" />
                    
                </div>

                <div class="p-field">
                        <label for="ice">ICE</label>
                        <V-Field name="ice"  v-model="selectedGridItem.ice" >  <!--  v-slot="{ field, errors, errorMessage }" -->
                            
                            <InputMask name="ice" :modelValue="selectedGridItem.ice" v-model="selectedGridItem.ice"   mask="999999999-9999-99" :autoClear="false" :unmask="true"  />
                        </V-Field> 
                        
                        <ErrorMessage name="ice" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="rc" class="p-mb-3">RC</label>
                    <V-Field as="InputText" name="rc" v-model.trim="selectedGridItem.rc"   />
                    <ErrorMessage name="rc" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="ville" class="p-mb-3">Ville</label>
                    <!-- <V-Field as="InputText" name="ville" v-model.trim="selectedGridItem.ville"   /> -->
                    
                    <Dropdown v-model="selectedGridItem.villeId" :options="optionsVilles" optionValue="id" optionLabel="nomVille" optionDisabled="disabled" placeholder="Selection de la ville..."/>
                    <ErrorMessage name="ville" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="codeInterne" class="p-mb-3">Code interne</label>
                    <V-Field as="InputText" name="codeInterne" v-model.trim="selectedGridItem.codeInterne"   />
                    <ErrorMessage name="codeInterne" class="error-feedback" />
                </div>
                
                <div class="p-field">
                    <label for="prospect" class="p-mb-3">Prospect/Client</label>
                    <div style="display: flex">
                        <div class="p-field-radiobutton">
                            <RadioButton name="client" value="Client" v-model="prospect" />
                            <label for="client">Client</label>
                        </div>
                        <div>&nbsp;&nbsp;</div>
                        <div class="p-field-radiobutton">
                            <RadioButton name="prospect" value="Prospect" v-model="prospect" />
                            <label for="prospect">Prospect</label>
                        </div>
                    </div>
                    
                    
                    <ErrorMessage name="prospect" class="error-feedback" />
                </div>
                <br/>
               <div class="p-d-flex p-jc-end">
                   
                    <button class="btn btn-danger btn-block"  @click="closeEditDialog">
                        <span>Annuler</span>
                    </button> &nbsp;
                    <button class="btn btn-primary btn-block" type="submit">
                        <span>Enregistrer</span>
                    </button>
                </div>

            </V-Form>
            
        </Dialog>

        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    </div>

    
    
</template>

<script>

import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import { Button } from '@progress/kendo-vue-buttons';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import {_} from 'vue-underscore';
import RadioButton from 'primevue/radiobutton';
import HistoriqueBilanSociete from "./BilanHistorique/HistoriqueBilanSociete.vue";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import ConfirmDialog from 'primevue/confirmdialog';
import ClientBilanDialog from "./ClientScoring/ClientBilanDialog.vue";
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import Axios from 'axios';
import ImportOmpicGlobal from "./ImportOmpicGlobal.vue";

import LoadCsv from "./LoadCsv.vue";
import CsvToDb from "./ClientCSVtoDB.service";
import { excelParser } from "../1-common-components/excelService";

import LoadPdfScore from "./LoadPdfScore.vue";

import SplitButton from 'primevue/splitbutton';
import crudService from '../1-common-components/crud.service';


export default {
  name: "ClientSocietes",
  components: {
    Header, 
    'k-button': Button,
    'kendo-grid': Grid,
    'kendo-grid-column': GridColumn,
    'kendo-datasource': KendoDataSource,
    'ClientBilanLoadCsv' : LoadCsv,
    Dialog,
    ConfirmDialog,
    'V-Field':Field,
    'V-Form':Form,
    ClientBilanDialog,
    ErrorMessage,
    LoadPdfScore,
    RadioButton,
    SplitButton,
    ImportOmpicGlobal,
    HistoriqueBilanSociete
  },
  data() {
        const myValidationSchema = yup.object().shape({
        raisonSociale: yup.string().max(50,"Maximum 50").required("Raison sociale est obligatoire !"),
        ice: yup.string().min(15,"ICE doit comporter 15 numéros").max(15,"ICE doit comporter 15 numéros").required("ICE est obligatoire !"),
        // rc: yup.string().max(15,"Maximum 15").required("RC est obligatoire !"),
        // codeVille: yup.string().max(50,"Maximum 50").required("Ville est obligatoire !"),
        });
        return {
            gridData: [],
            openOmpicDialog:false,
            ompicActivated:false,
            inforisk:false,
            ompic:false,
            showEditDialogFlag: false,
            selectedGridItem: {id:0},
            selectedGridInitialState: {id:0},
            showBilanDialogFlag: false,
            selectedBilanItem: {id:0},
            showHistoriqueBilan:false,
            items: [
                    {
                        label: 'Manuel',
                        icon: 'pi pi-pencil',
                        command: () => {
                            this.openEditBilanDialog({id:0,societeId:this.selectedGridItem.id, dateBilan:new Date()}, this.selectedGridItem, false)
                        }
                    },
                    {
                        label: 'Inforisk',
                        icon: 'pi pi-cloud-download',
                        command: () => {
                            this.openEditBilanDialog({id:0,societeId:this.selectedGridItem.id, dateBilan:new Date()}, this.selectedGridItem , true, false)
                        }
                    },
                    {
                        label: 'Ompic',
                        icon: 'pi pi-cloud-download',
                        command: () => {
                            this.openEditBilanDialog({id:0,societeId:this.selectedGridItem.id, dateBilan:new Date()}, this.selectedGridItem , false, true)
                        }
                    }
                ],
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            errorMessage: "",
            myValidationSchema,
            prospect:"Client",
            optionsVilles:[],
            currentClient:{},

        } // end result
    }, //end data()
    computed: {
        selectedId: function()
        {
            return this.selectedItem.id;
        },
        // selectedLogin: function()
        // {
        //     return this.selectedItem.username;
        // },
        noSelection: function()
        {
            return !this.selectedItem.id;
        },
        prospectValue: function()
        {
            return this.prospect=="Client"?0:1;
        }
    },
  mounted() {

    crudService.list("getRefVilles").then((response) => {
        this.optionsVilles = response.data
    })
    this.loadGridData();
    this.fnLoadCurrentClient()
  },
   // -------- grid init
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");
        this.$store.commit('clientScoringStoreModule/reset');
    },
  //----------Methods---------------------
  methods:{  
     fnLoadCurrentClient()
     {
        var endpoint = "currentclientcustom";
        
        return CRUDService.get(0, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadClient reponse");
                this.log(response.data);
                // this.currentClient = response.data

                if(!response.data.activerOmpic)
                {
                    let indexOmpic = this.items.findIndex(x => x.label == 'Ompic',)
                    this.items[indexOmpic]['disabled'] = true
                }

                if(!response.data.activerInforisk)
                {
                    let indexInforisk = this.items.findIndex(x => x.label == 'Inforisk',)
                    this.items[indexInforisk]['disabled'] = true 
                }

                
                this.ompicActivated = response.data.activerOmpic
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
        );
      }, 
      async ImportsOmpic()
      {
        try
        {
            var response = await CRUDService.listData('AddAllBilansForSocietes')
            console.log(response);
            this.toastSuccess("Les bilans Ompic ont été importés!");
        }
        catch(e)
        {
            this.toastError("Echec imports Ompic !");
            console.log(response);
        }
      },
      exportData() {
        var ref = this;
        // excelParser().exportDataFromJSON(this.gridData, null, null);
        var data = this.gridData.map(function(d){
            var obj = {...d}
            delete obj.id
            delete obj.villeId;
            

            obj['Raison sociale'] = obj.raisonSociale;
            delete obj.raisonSociale

            obj['ICE'] = obj.ice;
            delete obj.ice

            obj['ville'] = obj.ville?obj.ville.nomVille:null;
            delete obj.ville

            obj['Code interne'] = obj.codeInterne;
            delete obj.codeInterne

            delete obj.rc

            obj['Prospect'] = obj.prospect?"Oui":"Non";
            delete obj.prospect

            obj['Dernier score publié'] = obj.dernierScore?obj.dernierScore.score:null
            obj['Date dernier score'] = obj.dernierScore?new Date(obj.dernierScore.dateScore):""
            obj['Date validité'] = obj.dernierScore?new Date(obj.dernierScore.dateValidite):""
            
            // debugger;
            delete obj.dernierScore
            delete obj.score

            // obj['Créateur'] = obj.createur;
            delete obj.createur

            // obj['Date création'] = obj.dateCreation;
            delete obj.dateCreation

            var dernierBilanString = (obj.dernierBilan1?obj.dernierBilan1.dateBilan:"")+(obj.dernierBilan2?" - " +obj.dernierBilan2.dateBilan:"")
            obj['Derniers bilans'] = dernierBilanString

            delete obj.dernierBilan1
            delete obj.dernierBilan2
            
            return obj
        })

        // debugger;

        var headers = Object.keys(data[0]).map(function(d){
            return {v:d}
        })
        var fileName = 'Acheteurs_'+new Date().toISOString()+".xlsx"
        excelParser().exportDataWithSheetJS(fileName,headers,data)
      },
      dateValiditeColor: function(dernierScore)
      {
          
          if(!dernierScore)
          {
              return "black";
          }
          if(new Date(dernierScore.dateValidite) < new Date())
          {
              return "red";
          }
          else {
              return "#689F38";
          }
      },
      openSourcesExternes: function()
      {
          this.$router.push('/client-sourcesexternes');
      },
    showHistoriqueBilanModal(selectedItem)
    {
        this.selectedGridItem = selectedItem
        this.showHistoriqueBilan=true
    },
    showScoring : function(id)
    {
      var str = '/client-scoring/'+id + '/0'
      this.$router.push(str);
    },
    showBilan : function(id)
    {
      var str = '/client-bilan/'+id
      this.$router.push(str);
    },
    onBilanLoaded: function(e)
    {
        var event = e;
        var ref = this;
        this.log("this.myValidationSchema")
        var validation = Object.keys(this.myValidationSchema.fields)
        
        
        CsvToDb.CSVToDBFunctionHorizontal('societeascorerclient',e.files[0],{clientId:this.currentUser.clientId},validation,this.optionsVilles)
        .then(
        (response) => {
            $(event.target).val("");

            for(var i=0;i<response.length;i++)
            {
                ref.gridData.push(response[i]);
            }
            ref.toastSuccess("Succés de l'ajout par Excel");
        },
        (error) => {
            ref.toastWarn("Erreur: Validations manquantes");
            console.log(error);
        })
    },
    downloadItem (url) {
            Axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = "ModeleImportAcheteurs.xlsx"
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
    },
    onSubmit(values) {
            this.log("onSubmit");
            this.log(values);
            this.saveGridItem();
        },
    saveGridItem()
    {
        this.log("saveGridItem");
        this.log(this.selectedGridItem);
        this.selectedGridItem.clientId = this.currentUser.clientId;
        this.selectedGridItem.prospect = this.prospectValue;

        if(this.selectedGridItem.id > 0)
        {
            CRUDService.update(this.selectedGridItem,'societeascorerclient').then(
                (response) => {
                    this.log(response.data);
                    this.toastSuccess("societe "+ this.selectedGridItem.raisonSociale +" modifié avec succès");
                    var lastScoreValues = this.selectedGridItemInitialState;
                    this.selectedGridItemInitialState = response.data;
                    this.selectedGridItemInitialState.dateValidite = lastScoreValues.dateValidite;
                    this.selectedGridItemInitialState.dernierBilan1 = lastScoreValues.dernierBilan1;
                    this.selectedGridItemInitialState.dernierBilan2 = lastScoreValues.dernierBilan2;
                    this.selectedGridItemInitialState.dernierScore = lastScoreValues.dernierScore;

                    this.loadGridData();
                    this.showEditDialogFlag = false;
                },
                (error) => {
                    this.toastError("Echec sauvegarde de l''enregistrement !");
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        }
        else
        {
            this.selectedGridItem.clientId = this.currentUser.clientId
            CRUDService.create(this.selectedGridItem,'societeascorerclient').then(
                (response) => {
                    this.log(response.data);
                    this.gridData.unshift(response.data);
                    this.toastSuccess("societe "+ this.selectedGridItem.raisonSociale +" créé avec succès");
                    this.selectedGridItemInitialState = response.data;
                    this.showEditDialogFlag = false;
                },
                (error) => {
                    this.toastError("Echec création de l''enregistrement !");
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        }
    },
    openEditDialog(selectedItem){
        ;
        this.log("openEditDialog");
        this.log(selectedItem);
        this.showEditDialogFlag = true;
        this.selectedGridItem = selectedItem;
        
        this.prospect = this.selectedGridItem.prospect==1?"Prospect":"Client";
        this.selectedGridItemInitialState = { ...this.selectedGridItem }; //this is an object copy using decomposition
    },
    closeEditDialog(){
        this.log("closeEditDialog");
        
        for (let index = 0; index < this.gridData.length; index++) {
            var element = this.gridData[index];
            if(element.id == this.selectedGridItem.id)
            {
                this.log(this.selectedGridItemInitialState);
                this.gridData[index] = this.selectedGridItemInitialState;
                break;
            }
        }

        this.showEditDialogFlag = false;
    },
    confirmDeleteSelected(selectedItem){
        this.log("confirmDeleteSelected: " + selectedItem);
        this.log(selectedItem);
        this.selectedGridItem = selectedItem

        this.$confirm.require({
            message: 'Etes-vous sûr de vouloir supprimer la societe '+this.selectedGridItem.raisonSociale+'?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptLabel:'Oui',
            rejectLabel:'Non',
            acceptClass: 'p-button-danger',
            accept: () => {
                this.deleteGridItem();
                this.$confirm.close();
            },
            reject: () => {
                this.$confirm.close();
            }
        });
    },
    deleteGridItem()
    {
        this.log("deleteGridItem");
        CRUDService.delete(this.selectedGridItem,'societeascorerclient').then(
            (response) => {
                this.log(response.data);
                this.gridData = this.gridData.filter(d => d.id != this.selectedGridItem.id);
                this.toastSuccess("societe "+ this.selectedGridItem.raisonSociale +" supprimé avec succès");
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
        );
    },
    loadGridData: function()
    {
        this.log("------loadGridData");
        CRUDService.get(this.currentUser.clientId, 'societeascorerbyclientidcustomv2').then(
            (response) => {
                this.log(response.data);
                this.gridData = response.data;
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
        );
        
    },
    onActifChange(selectedItem)
    {
        this.selectedGridItem = selectedItem;
        this.saveGridItem();
    },
    openEdit(selectedItem)
    {
        if($('.p-tieredmenu').length == 0)
        {
            setTimeout(function(){
                $("#ButtonClickedId_"+selectedItem.id+" > div > button.p-button.p-component.p-button-icon-only.p-splitbutton-menubutton").click()
            },0)
            
            this.selectedGridItem = selectedItem
        }
        
    },
    openEditBilanDialog(selectedItem, selectedSociete , inforisk, Ompic){
        this.selectedGridItem = selectedSociete
        this.inforisk = inforisk
        this.ompic = Ompic
        this.log("openEditBilanDialog");
        this.log(selectedItem);

        this.showBilanDialogFlag = true;
        this.selectedBilanItem = selectedItem;
    },
    DialogBilanFalse()
    {
        this.showBilanDialogFlag = false;
    },
    closeBilanDialog(){
        this.savedBilanDialog(true)
    },
    savedBilanDialog(closeDialog){
        var ref=this
        this.log("savedBilanDialog"); 
        // this.selectedGridItem = {id:0}
        // this.fnLoadBilans();
        if(closeDialog)
        {
            this.$nextTick(function(){
                ref.DialogBilanFalse()
            });
        }
        

        this.loadGridData();
    },
    scorePdf : function(id)
    {
        this.$refs.LoadPdfScore.loadPdfFromSociete(id);
    },
  }
};
</script>


<!-- ########################## <Style> ########################## -->
<style >



div>label[for="createur"], div>label[for="createur"] + div {
    display: none;
}


.hasSeparator2
{
    border-left-color: lightgrey !important;
    border-left-style: solid !important;
    border-left-width: 2px !important;
}

.hasSeparator1
{
    border-left-color: lightgrey !important;
    border-left-style: solid !important;
    border-left-width: 1px !important;
}

.p-splitbutton-menubutton
{
    visibility:hidden
} 

.splitButtonAdd button{
    font-size:0.8rem
}

div.p-datatable-wrapper > table > thead > tr > th .pi
{
    font-size:0.5rem
}
</style>