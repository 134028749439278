<!-- ########################## <Template> ########################## -->
<template>
 <!-- {{ selectedBilansOmpic }} -->
<DataTable :value="bilansOmpic" responsiveLayout="scroll"
    :selection="selectedBilansOmpic" 
    selectionMode="multiple"
    :globalFilterFields="['ice','raisonSociale']"
    @rowSelect="onRowSelect"
    :filters="filters"
    dataKey="id">

    <template #header>
        <Dropdown v-model="filters['annee'].value" :options="uniqueYears"></Dropdown>
        <div class="flex justify-content-end">
                <InputText v-model="filters['global'].value" placeholder="Recherche (par ICE,RS)" />
        </div>
    </template>

    <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

    <Column field="ice" header="ICE"></Column>

    <Column field="raisonSociale" header="Raison sociale"></Column>

    <Column field="annee" header="Annee"></Column>

    <Column field="importeOmpic" header="">
        <template #body="slotProps">
            <div v-if="slotProps.data.importeOmpic">
                Deja importé
            </div>
            <div v-else>
                Non importé
            </div>
        </template>
    </Column>

    
    <Column header="Etat">
        <template #body="slotProps">
            <div v-if="slotProps.data.etat">
                <div v-if="slotProps.data.etat == 'En cours'">
                    <Tag severity="secondary" :value="slotProps.data.etat" rounded></Tag><i class="pi pi-spin pi-spinner"></i>
                </div>
                <Tag v-else :severity="slotProps.data.etat=='Erreur'?'danger':'success'" :value="slotProps.data.etat" rounded></Tag>
            </div>
        </template>
    </Column>

    <template #footer><div style="text-align: right;"><P-Button label="Importer" @click="loopImportsOmpic"/></div> </template>
</DataTable>

</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------

import CRUDService from "../1-common-components/crud.service";
import Tag from 'primevue/tag';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dropdown from 'primevue/dropdown';

export default {
    //---------------------------- props ------------------------------
    
    //--------------------------- Components --------------------------
    components: {
        Tag,
        Dropdown
    },
    //--------------------------- Data --------------------------
    data() {
        return {
            selectedBilansOmpic:[],
            bilansOmpic:[
                    // {
                    //     "ICE": "123456789",
                    //     "RaisonSociale": "Entreprise A",
                    //     "importeOmpic": true,
                    //     "etat": "En cours"
                    // },
                    // {
                    //     "ICE": "987654321",
                    //     "RaisonSociale": "Entreprise B",
                    //     "importeOmpic": false,
                    //     "etat": "Validé"
                    // },
                    // {
                    //     "ICE": "456123789",
                    //     "RaisonSociale": "Entreprise C",
                    //     "importeOmpic": false,
                    //     "etat": "Erreur"
                    // },
                    // {
                    //     "ICE": "789456123",
                    //     "RaisonSociale": "Entreprise D",
                    //     "importeOmpic": true,
                    //     "etat": "Validé"
                    // },
                    // {
                    //     "ICE": "321654987",
                    //     "RaisonSociale": "Entreprise E",
                    //     "importeOmpic": true,
                    //     "etat": "En cours"
                    // }
                ],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                annee: { value: null, matchMode: FilterMatchMode.EQUALS  }
            },

        } // end result
    }, //end data()
    //--------------------------- emits -----------------------------
    emits: ["close"],
    //--------------------------- computed --------------------------
    computed:
    {
        uniqueYears(){
            let yearSet = new Set(bilansOmpic.map(obj => obj.annee))
            return [...yearSet]
        }
    },
    //--------------------------- Mounted --------------------------
    mounted() {
        this.log("----------- ImportOmpicGlobal  mounted()");
        CRUDService.list('GetBilansOmpicImportGlobal').then( //ActiverWorkflowValidation
                (response) => {
                    this.log(response.data);
                    this.bilansOmpic = response.data;
                    
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );

  }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- ImportOmpicGlobal created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        onRowSelect(e)
        {
            let indexSelect = this.selectedBilansOmpic.findIndex(b => b.id == e.data.id)
            if(indexSelect>-1)
            {
                this.selectedBilansOmpic.splice(indexSelect,1)
                let index = this.bilansOmpic.findIndex(b => b.id == e.data.id)
                if(index>-1)
                {
                    this.bilansOmpic[index].etat = null
                }
            }
            else
            {
                let indexAttr = this.selectedBilansOmpic.length
                e.data["index"] = indexAttr
                this.selectedBilansOmpic.push(e.data)


                let index = this.bilansOmpic.findIndex(b => b.id == e.data.id)
                if(index>-1)
                {
                    this.bilansOmpic[index].etat = "Selectionné"
                }
                console.log(e)
            }

        },
        async loopImportsOmpic()
        {
            // var ref = this

            for(var i=0;i<this.bilansOmpic.length;i++)
            {
                if(this.bilansOmpic[i].etat == "Selectionné")
                {
                    this.ImportOmpic(i)
                }
            }
        },
        async ImportOmpic(index)
        {
            
            this.bilansOmpic[index].etat = "En cours"
            try
            {
                
                var response = await CRUDService.listData('AddOrUpdateNewBilan/'+this.bilansOmpic[index].societe.id+'/'+this.bilansOmpic[index].annee ,{})
                // var recordObject = response.data;
                this.bilansOmpic[index].etat = "Succés"
            }
            catch(e)
            {
                console.log(e)
                this.bilansOmpic[index].etat = "Erreur"
            }
        }
        
    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >

.green
{
    color:green;
}

.red
{
    color:red;
}

</style>