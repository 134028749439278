<template>
    <!-- {{ champCalcule }} vs {{ calculatedValue }} -->
    <!-- {{ calculatedValue }} -->
    <!-- <div v-if="">{{ selectedBilanItemAnneeN1 }}</div> -->
    <div v-if="champCalcule!=null && areTheyNotEqual()" v-tooltip.top="'Valeur calculée = '+formatDecimal2(champCalcule)" placeholder="Left">
        <i style="color:orange" class="pi pi-exclamation-triangle"></i>

    </div>

</template>

<script>

export default{
    data()
    {
        return{
            mappingObject:{},
            bilanType:'',
            codeInforisk:'',
            jsonResult:{}
        }
    },
    props:['codeTechnique','selectedBilanItemAnneeN1','selectedBilanItem','refMappingBilanListeChamps','champCalcule'], //BDD
    mounted()
    {
        
    },
    methods:{
        getDiff()
        {
            var inforiskValueLocal = this.inforiskValue;
            var calculatedValueLocal = this.calculatedValue;
            // fix values if they are not numbers 
            if(Math.abs(inforiskValueLocal) == 0 || isNaN(Math.abs(inforiskValueLocal)) )
            {
                inforiskValueLocal = 0;
            }
            if(Math.abs(calculatedValueLocal) == 0 || isNaN(Math.abs(calculatedValueLocal)) )
            {
                calculatedValueLocal = 0;
            }
            return Math.abs(inforiskValueLocal - calculatedValueLocal) ;       
        },
        areTheyNotEqual()
        {
            var calculValueLocal = this.champCalcule?this.champCalcule:0;
            var calculatedValueLocal = this.calculatedValue;
            // fix values if they are not numbers 
            if(Math.abs(calculValueLocal) == 0 || isNaN(Math.abs(calculValueLocal)) )
            {
                calculValueLocal = 0;
            }
            if(Math.abs(calculatedValueLocal) == 0 || isNaN(Math.abs(calculatedValueLocal)) )
            {
                calculatedValueLocal = 0;
            }
            if( Math.abs(calculValueLocal - calculatedValueLocal) >= 0.01)
            {
                // debugger;
                return true
            }
            return false       
        },
    },
    computed:
    {
        calculatedValue()
        {
            if(this.codeTechnique)
            {
                // .substring(0,'11154545454ssAnneeN1'.lastIndexOf('AnneeN1'))
                let codeTechniqueParsed = this.codeTechnique;
                let selectedBilan = this.selectedBilanItem
                if(this.codeTechnique.includes('AnneeN1'))
                {
                    codeTechniqueParsed = codeTechniqueParsed.substring(0,codeTechniqueParsed.lastIndexOf('AnneeN1'))
                    // debugger;
                    if(!this.selectedBilanItemAnneeN1)
                    {
                        //returns the same value so that it disables the exclamation icon
                        return this.champCalcule
                    }
                    selectedBilan = this.selectedBilanItemAnneeN1
                }

                if(codeTechniqueParsed in selectedBilan && !isNaN(selectedBilan[codeTechniqueParsed]))
                {
                    return selectedBilan[codeTechniqueParsed]
                }
                let codeTechniqueCamlCase = codeTechniqueParsed[0].toLowerCase() + codeTechniqueParsed.substring(1)
                if(selectedBilan[codeTechniqueCamlCase]!=null && !isNaN(selectedBilan[codeTechniqueCamlCase]))
                {
                    return selectedBilan[codeTechniqueCamlCase]
                }
                
            }
            return 0
        }
    }
}
</script>

<style scoped>

</style>
