<template>

<!-- <Toast /> -->

<Dialog v-model:visible="showBilanDialogFlagLocal" :style="{width: '1200px'}"  @hide="closeDialog" :modal="true" >
            <!-- <UploadBilan></UploadBilan> -->
            <template #header>
                <div style="color: #2195f3;display:flex;"> 
                <div style="display: flex;align-items:center;vertical-align:center" v-if="inforiskLocal || (selectedBilanItemLocal['inforisk'] && selectedBilanItemLocal['inforisk'] == 1)">
                    <img  src="../../../src/assets/inforisk.png"  style="width:100px;" />
                    <div v-if="selectedBilanItemLocal.id" style="font-size:16px;font-weight:bold;padding-left: 5px;padding-top: 5px;">
                        <i class="pi pi-pencil"></i>
                    </div>
                    <div v-else style="font-size:16px;font-weight:bold;padding-left: 5px;padding-top: 5px;">
                        <i class="pi pi-plus-circle"></i>
                    </div>
                    <div style="font-size:16px;font-weight:bold;padding-top: 5px;">
                        &nbsp;{{titleHeader}}
                    </div>
                </div>
                <div style="font-size:16px;font-weight:bold;display: flex;align-items: center;" v-else>
                    <div v-if="selectedBilanItemLocal.id" style="font-size:16px;font-weight:bold;padding-left: 5px;">
                        <i class="pi pi-pencil"></i>
                    </div>
                    <div v-else style="font-size:16px;font-weight:bold;padding-left: 5px;">
                        <i class="pi pi-plus-circle"></i>
                    </div>
                    <div>
                    &nbsp;{{titleHeader}}
                    </div>
                </div>
                </div>
            </template>

            <V-Form > 
                <div class="" style="padding-bottom:5px">
                    
                    <div v-if="ompicLocal && !disabledButtons">
                        <b>Import OMPIC:</b>
                        <br/>
                        <label for="dateBilan">Date du bilan: </label> &nbsp;
                        <Dropdown :disabled="disabledButtons" v-model="selectedBilanItemLocal.dateBilan" :options="years" optionValue="annee" optionLabel="texte" placeholder="Date du bilan"/>
                        &nbsp;
                        <P-Button :disabled="disabledButtons || !selectedBilanItemLocal || !selectedBilanItemLocal.dateBilan" label="Créer Bilan" class="p-button-raised"  :loading="isSaving"  @click="ImporterBilanOmpic"/>
                    </div>

                    <div v-if="!ompicLocal && !selectedBilanItemLocal.id && !createBilanFlag">
                    <label for="dateBilan">Date du bilan: </label> &nbsp;
                    <Dropdown v-model="selectedBilanItemLocal.dateBilan" :options="years" optionValue="annee" optionLabel="annee" optionDisabled="disabled" placeholder="Date du bilan"/>
                    &nbsp;
                    <P-Button :disabled="!selectedBilanItemLocal.dateBilan" v-if="!inforiskLocal" label="Initialiser Bilan" class="p-button-raised"  :loading="isSaving"  @click="createBilanEmpty()"/>
                    <!-- <PCalendar :modelValue="new Date(selectedBilanItemLocal.dateBilan)"  v-model="selectedBilanItemLocal.dateBilan" view="year" dateFormat="yy" :disabledDates="anneesbilan"/> -->
                    <br/>
                    &nbsp;
                    <!-- {{selectedBilanItemLocal.dateBilan}} -->
                    <div v-if="!ompicLocal && inforiskLocal && !selectedBilanItemLocal.id">
                        <div style="display:grid;width:300px;border: 1px solid blue;padding:20px">
                        <b>Login:</b>
                        <InputText v-model="credentials.username"/>
                        <b>Mot de passe:</b>
                        <Password :feedback="false" :promptLabel="null" v-model="credentials.password" toggleMask/>
                        </div>
                        <br/>
                        <Button @click="ImporterBilanInforiskBoutonAction" :style="InforiskLoading?'width:200px; height:35px':'width:150px; height:35px'" :loading="InforiskLoading" loadingIcon="pi pi-spinner pi-spin" :disabled="!selectedBilanItemLocal.dateBilan">Importer&nbsp;Bilan<i v-if="InforiskLoading" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></Button>
                    </div>
                    
                    <div v-if="companyInforisk && companyInforisk._sDenomination">
                        Vous allez importer un bilan de l'entreprise suivante: {{companyInforisk._sDenomination}}
                        <br/>
                        <b>ICE</b>({{companyInforisk._sICE}})

                        <span>Voulez vous continuer?</span>
                        <br/>
                        <Button @click="ImporterBilanInforiskAvecEntreprisevalidee">OUI</Button>
                        &nbsp;
                        <Button @click="companyInforisk=null;InforiskLoading=false;">NON</Button>
                    </div>
                </div>
                <!-- <Button v-if="(inforiskLocal || selectedBilanItemLocal.inforisk) && selectedBilanItemLocal.id" @click="switchToManuel" :style="'height:35px'" :loading="InforiskLoading" >Rendre manuel</Button> -->
                <!-- res:{{jsonResult}} -->
                </div>
                <!-- {{selectedBilanItemLocal}} -->
                <div class="row p-fluid" style="margin-bottom: 10px;" v-if="selectedBilanItemLocal.id>0 || createBilanFlag">
                    <!-- ========================== first Column =========================== -->
                    <div class="col-12">
                        <div class="card p-2">
                            <!-- <div class="k-state-active tabStribHeader">
                                <span class="pi pi-book" style="font-size:20px;margin-top:-4px;"></span>
                                Actif
                            </div> -->
                            <component :is="printable ? 'LoadPdfBilans' : 'kendo-tabstrip'" ref="refKendoTabStrip" >
                                <ul v-if="!printable">
                                    <li class="k-state-active tabStribHeader" style="vertical-align:middle;border-bottom-color: #0d6efd;border-width: 2px;width: 33%;">
                                        <!-- <span class="k-icon k-i-greyscale"></span> -->
                                            Actif
                                    </li>
                                    <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #f31700;border-width: 2px;width: 33%;">
                                            <!-- <span class="k-icon k-i-dollar"></span> -->
                                            Passif
                                    </li>
                                    <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #37b400;border-width: 2px;width: 33%;">
                                            <!-- <span class="k-icon k-i-saturation"></span> -->
                                            CPC
                                    </li>
                                </ul>
                                <div class="bilanActif pageBreak">
                                    <!-- *********************** BILAN (ACTIF) ******************************* -->
                                    <table style="width:100%">
                                        <tr v-if="!inforiskLocal">
                                            <th></th>
                                            <th></th>
                                            <th>Brut</th>
                                            <th>Amortissement/Provisions</th>
                                            <th>Net</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr v-else>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>Net</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="26" class="bilanMargeVerticale"> A <br>C<br>T<br>I<br>F<br> <br> I<br>M<br>M<br>O<br>B<br>I<br>L<br>I<br>S<br>E<br>S</td>
                                            <td class="titleCol titleGroupCol"> IMMOBILISATION EN NON VALEUR (a)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifAImmobiliseImmobilisationEnNonValeurBrut'   v-model='selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeurBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifAImmobiliseImmobilisationEnNonValeurAmrtProv'   v-model='selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeurAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifAImmobiliseImmobilisationEnNonValeur' v-model='selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeur'  mode='decimal'  :minFractionDigits='2'  />
                                                    <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifAImmobiliseImmobilisationEnNonValeur"
                                                    :champCalcule="getCalculValue('actifAImmobiliseImmobilisationEnNonValeur')" 
                                                    :selectedBilanItem="selectedBilanItemLocal"
                                                    
                                                    :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifAImmobiliseImmobilisationEnNonValeurAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifAImmobiliseImmobilisationEnNonValeur'  mode='decimal'  :minFractionDigits='2'  />
                                                    <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifAImmobiliseImmobilisationEnNonValeurAnneeN1"
                                                    :champCalcule="getCalculValue('actifAImmobiliseImmobilisationEnNonValeurAnneeN1')" 
                                                    :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                    :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Frais préliminaires</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA1ImmobiliseFraisPreliminairesBrut'  v-model='selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminairesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA1ImmobiliseFraisPreliminairesAmrtProv'  v-model='selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminairesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifA1ImmobiliseFraisPreliminaires'  v-model='selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifA1ImmobiliseFraisPreliminairesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifA1ImmobiliseFraisPreliminaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Charges à répartir sur plusieurs exercices</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut'  v-model='selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA2ImmobiliseChargesArepartirSurplusieursExercicesAmrtProv'  v-model='selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercicesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifA2ImmobiliseChargesArepartirSurplusieursExercices'  v-model='selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercices'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifA2ImmobiliseChargesArepartirSurplusieursExercicesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifA2ImmobiliseChargesArepartirSurplusieursExercices'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Primes de remboursement des obligations</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut'  v-model='selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA3ImmobilisePrimesDeRemboursementDesObligationsAmrtProv'  v-model='selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligationsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifA3ImmobilisePrimesDeRemboursementDesObligations'  v-model='selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligations'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifA3ImmobilisePrimesDeRemboursementDesObligationsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifA3ImmobilisePrimesDeRemboursementDesObligations'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> IMMOBILISATIONS INCORPORELLES (b)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifBImmobiliseImmobilisationsIncorporellesBrut'   v-model='selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporellesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifBImmobiliseImmobilisationsIncorporellesAmrtProv'   v-model='selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporellesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifBImmobiliseImmobilisationsIncorporelles'   v-model='selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporelles'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifBImmobiliseImmobilisationsIncorporelles"
                                                :champCalcule="getCalculValue('actifBImmobiliseImmobilisationsIncorporelles')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifBImmobiliseImmobilisationsIncorporellesAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifBImmobiliseImmobilisationsIncorporelles'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifBImmobiliseImmobilisationsIncorporellesAnneeN1"
                                                :champCalcule="getCalculValue('actifBImmobiliseImmobilisationsIncorporellesAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Immobilisations en recherche et développement</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut'  v-model='selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAmrtProv'  v-model='selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement'  v-model='selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Brevets, marques, droits et valeurs similaires</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut'  v-model='selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAmrtProv'  v-model='selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires'  v-model='selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Fonds commercial</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB3ImmobiliseFondsCommercialBrut'  v-model='selectedBilanItemLocal.actifB3ImmobiliseFondsCommercialBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB3ImmobiliseFondsCommercialAmrtProv'  v-model='selectedBilanItemLocal.actifB3ImmobiliseFondsCommercialAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifB3ImmobiliseFondsCommercial'  v-model='selectedBilanItemLocal.actifB3ImmobiliseFondsCommercial'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifB3ImmobiliseFondsCommercialAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifB3ImmobiliseFondsCommercial'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres immobilisations incorporelles</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut'  v-model='selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB4ImmobiliseAutresImmobilisationsIncorporellesAmrtProv'  v-model='selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporellesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifB4ImmobiliseAutresImmobilisationsIncorporelles'  v-model='selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporelles'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifB4ImmobiliseAutresImmobilisationsIncorporellesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifB4ImmobiliseAutresImmobilisationsIncorporelles'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> IMMOBILISATIONS CORPORELLES (c)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifCImmobiliseImmobilisationsCorporellesBrut'   v-model='selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporellesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifCImmobiliseImmobilisationsCorporellesAmrtProv'   v-model='selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporellesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifCImmobiliseImmobilisationsCorporelles'   v-model='selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporelles'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifCImmobiliseImmobilisationsCorporelles"
                                                :champCalcule="getCalculValue('actifCImmobiliseImmobilisationsCorporelles')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifCImmobiliseImmobilisationsCorporellesAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifCImmobiliseImmobilisationsCorporelles'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifCImmobiliseImmobilisationsCorporellesAnneeN1"
                                                :champCalcule="getCalculValue('actifCImmobiliseImmobilisationsCorporellesAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Terrains</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC1ImmobiliseTerrainsBrut'  v-model='selectedBilanItemLocal.actifC1ImmobiliseTerrainsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC1ImmobiliseTerrainsAmrtProv'  v-model='selectedBilanItemLocal.actifC1ImmobiliseTerrainsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifC1ImmobiliseTerrains'  v-model='selectedBilanItemLocal.actifC1ImmobiliseTerrains'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC1ImmobiliseTerrainsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC1ImmobiliseTerrains'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Constructions</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC2ImmobiliseConstructionsBrut'  v-model='selectedBilanItemLocal.actifC2ImmobiliseConstructionsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC2ImmobiliseConstructionsAmrtProv'  v-model='selectedBilanItemLocal.actifC2ImmobiliseConstructionsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifC2ImmobiliseConstructions'  v-model='selectedBilanItemLocal.actifC2ImmobiliseConstructions'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC2ImmobiliseConstructionsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC2ImmobiliseConstructions'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Installations techniques, matériel et outillage</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut'  v-model='selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAmrtProv'  v-model='selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifC3ImmobiliseInstallationsTechniqueMaterielOutillage'  v-model='selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillage'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC3ImmobiliseInstallationsTechniqueMaterielOutillage'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Matériel de transport</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC4ImmobiliseMaterielDeTransportBrut'  v-model='selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransportBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC4ImmobiliseMaterielDeTransportAmrtProv'  v-model='selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransportAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifC4ImmobiliseMaterielDeTransport'  v-model='selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransport'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC4ImmobiliseMaterielDeTransportAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC4ImmobiliseMaterielDeTransport'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Mobiliers, matériel de bureau et aménagements divers</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut'  v-model='selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAmrtProv'  v-model='selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers'  v-model='selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres immobilisations corporelles</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC6ImmobiliseAutresImmobilisationsCorporellesBrut'  v-model='selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporellesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC6ImmobiliseAutresImmobilisationsCorporellesAmrtProv'  v-model='selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporellesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifC6ImmobiliseAutresImmobilisationsCorporelles'  v-model='selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporelles'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC6ImmobiliseAutresImmobilisationsCorporellesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC6ImmobiliseAutresImmobilisationsCorporelles'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Immobilisations corporelles en cours</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut'  v-model='selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC7ImmobiliseImmobilisationsCorporellesEnCoursAmrtProv'  v-model='selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCoursAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifC7ImmobiliseImmobilisationsCorporellesEnCours'  v-model='selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCours'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC7ImmobiliseImmobilisationsCorporellesEnCoursAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC7ImmobiliseImmobilisationsCorporellesEnCours'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> IMMOBILISATIONS FINANCIERES (d)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifDImmobiliseImmobilisationsFinancieresBrut'   v-model='selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieresBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifDImmobiliseImmobilisationsFinancieresAmrtProv'   v-model='selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieresAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifDImmobiliseImmobilisationsFinancieres'   v-model='selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieres'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifDImmobiliseImmobilisationsFinancieres"
                                                :champCalcule="getCalculValue('actifDImmobiliseImmobilisationsFinancieres')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifDImmobiliseImmobilisationsFinancieresAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifDImmobiliseImmobilisationsFinancieres'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifDImmobiliseImmobilisationsFinancieresAnneeN1"
                                                :champCalcule="getCalculValue('actifDImmobiliseImmobilisationsFinancieresAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Prêts immobilises</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD1ImmobilisePretsImmobilisesBrut'  v-model='selectedBilanItemLocal.actifD1ImmobilisePretsImmobilisesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD1ImmobilisePretsImmobilisesAmrtProv'  v-model='selectedBilanItemLocal.actifD1ImmobilisePretsImmobilisesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifD1ImmobilisePretsImmobilises'  v-model='selectedBilanItemLocal.actifD1ImmobilisePretsImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifD1ImmobilisePretsImmobilisesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifD1ImmobilisePretsImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres créances financières</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD2ImmobiliseAutresCreancesFinancieresBrut'  v-model='selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieresBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD2ImmobiliseAutresCreancesFinancieresAmrtProv'  v-model='selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieresAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifD2ImmobiliseAutresCreancesFinancieres'  v-model='selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifD2ImmobiliseAutresCreancesFinancieresAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifD2ImmobiliseAutresCreancesFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Titres de participation</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD3ImmobiliseTitresDeParticipationBrut'  v-model='selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipationBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD3ImmobiliseTitresDeParticipationAmrtProv'  v-model='selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipationAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifD3ImmobiliseTitresDeParticipation'  v-model='selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifD3ImmobiliseTitresDeParticipationAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifD3ImmobiliseTitresDeParticipation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres titres immobilisés</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD4ImmobiliseAutresTitresImmobilisesBrut'  v-model='selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilisesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD4ImmobiliseAutresTitresImmobilisesAmrtProv'  v-model='selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilisesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifD4ImmobiliseAutresTitresImmobilises'  v-model='selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifD4ImmobiliseAutresTitresImmobilisesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifD4ImmobiliseAutresTitresImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> ECARTS DE CONVERSION - ACTIF (e)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifEImmobiliseEcartsDeConversionBrut'   v-model='selectedBilanItemLocal.actifEImmobiliseEcartsDeConversionBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifEImmobiliseEcartsDeConversionAmrtProv'   v-model='selectedBilanItemLocal.actifEImmobiliseEcartsDeConversionAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifEImmobiliseEcartsDeConversion'   v-model='selectedBilanItemLocal.actifEImmobiliseEcartsDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifEImmobiliseEcartsDeConversion"
                                                :champCalcule="getCalculValue('actifEImmobiliseEcartsDeConversion')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifEImmobiliseEcartsDeConversionAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifEImmobiliseEcartsDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifEImmobiliseEcartsDeConversionAnneeN1"
                                                :champCalcule="getCalculValue('actifEImmobiliseEcartsDeConversionAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Diminution des créances immobilisées</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut'  v-model='selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAmrtProv'  v-model='selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifE1ImmobiliseDiminutionDesCreancesImmobilisees'  v-model='selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobilisees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifE1ImmobiliseDiminutionDesCreancesImmobilisees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Augmentation des dettes de financement</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut'  v-model='selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifE2ImmobiliseAugmentationDesDettesDeFinancementAmrtProv'  v-model='selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancementAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifE2ImmobiliseAugmentationDesDettesDeFinancement'  v-model='selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifE2ImmobiliseAugmentationDesDettesDeFinancementAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifE2ImmobiliseAugmentationDesDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol"> TOTAL  I   ( a+b+c+d+e)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTotalIImmobiliseTotalBrut'   v-model='selectedBilanItemLocal.actifTotalIImmobiliseTotalBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTotalIImmobiliseTotalAmrtProv'   v-model='selectedBilanItemLocal.actifTotalIImmobiliseTotalAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifTotalIImmobiliseTotal'   v-model='selectedBilanItemLocal.actifTotalIImmobiliseTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalIImmobiliseTotal"
                                                :champCalcule="getCalculValue('actifTotalIImmobiliseTotal')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTotalIImmobiliseTotalAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifTotalIImmobiliseTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalIImmobiliseTotalAnneeN1"
                                                :champCalcule="getCalculValue('actifTotalIImmobiliseTotalAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td rowspan="17" style="text-align:center" class="bilanMargeVerticale"> A<br>C<br>T<br>I<br>F <br><br> C<br>I<br>R<br>C<br>U<br>L<br>A<br>N<br>T</td>
                                            <td class="titleCol titleGroupCol"> STOCKS (f)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifFCirculantStocksBrut'   v-model='selectedBilanItemLocal.actifFCirculantStocksBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifFCirculantStocksAmrtProv'   v-model='selectedBilanItemLocal.actifFCirculantStocksAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifFCirculantStocks'   v-model='selectedBilanItemLocal.actifFCirculantStocks'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifFCirculantStocks"
                                                :champCalcule="getCalculValue('actifFCirculantStocks')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifFCirculantStocksAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifFCirculantStocks'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifFCirculantStocksAnneeN1"
                                                :champCalcule="getCalculValue('actifFCirculantStocksAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Marchandises</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF1CirculantMarchandisesBrut'  v-model='selectedBilanItemLocal.actifF1CirculantMarchandisesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF1CirculantMarchandisesAmrtProv'  v-model='selectedBilanItemLocal.actifF1CirculantMarchandisesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifF1CirculantMarchandises'  v-model='selectedBilanItemLocal.actifF1CirculantMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF1CirculantMarchandisesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF1CirculantMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Matières et fournitures consommables</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF2CirculantMatieresEtFournituresConsommablesBrut'  v-model='selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommablesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF2CirculantMatieresEtFournituresConsommablesAmrtProv'  v-model='selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommablesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifF2CirculantMatieresEtFournituresConsommables'  v-model='selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommables'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF2CirculantMatieresEtFournituresConsommablesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF2CirculantMatieresEtFournituresConsommables'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Produits en cours</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF3CirculantProduitsEnCoursBrut'  v-model='selectedBilanItemLocal.actifF3CirculantProduitsEnCoursBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF3CirculantProduitsEnCoursAmrtProv'  v-model='selectedBilanItemLocal.actifF3CirculantProduitsEnCoursAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifF3CirculantProduitsEnCours'  v-model='selectedBilanItemLocal.actifF3CirculantProduitsEnCours'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF3CirculantProduitsEnCoursAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF3CirculantProduitsEnCours'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Produits intermédiaires et produits résiduels</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut'  v-model='selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAmrtProv'  v-model='selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifF4CirculantProduitsIntermediairesEtProduitsResiduels'  v-model='selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduels'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF4CirculantProduitsIntermediairesEtProduitsResiduels'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Produits finis</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF5CirculantProduitsFinisBrut'  v-model='selectedBilanItemLocal.actifF5CirculantProduitsFinisBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF5CirculantProduitsFinisAmrtProv'  v-model='selectedBilanItemLocal.actifF5CirculantProduitsFinisAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifF5CirculantProduitsFinis'  v-model='selectedBilanItemLocal.actifF5CirculantProduitsFinis'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF5CirculantProduitsFinisAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF5CirculantProduitsFinis'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> CREANCES DE L'ACTIF CIRCULANT (g)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifGCirculantCreancesCirculantBrut'   v-model='selectedBilanItemLocal.actifGCirculantCreancesCirculantBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifGCirculantCreancesCirculantAmrtProv'   v-model='selectedBilanItemLocal.actifGCirculantCreancesCirculantAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifGCirculantCreancesCirculant'   v-model='selectedBilanItemLocal.actifGCirculantCreancesCirculant'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifGCirculantCreancesCirculant"
                                                :champCalcule="getCalculValue('actifGCirculantCreancesCirculant')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifGCirculantCreancesCirculantAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifGCirculantCreancesCirculant'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifGCirculantCreancesCirculantAnneeN1"
                                                :champCalcule="getCalculValue('actifGCirculantCreancesCirculantAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Fournis. débiteurs, avances et acomptes</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut'  v-model='selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG1CirculantFournisseursdebiteursAvancesAcomptesAmrtProv'  v-model='selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifG1CirculantFournisseursdebiteursAvancesAcomptes'  v-model='selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG1CirculantFournisseursdebiteursAvancesAcomptesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG1CirculantFournisseursdebiteursAvancesAcomptes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Clients et comptes rattachés</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG2CirculantClientsEtComptesRattachesBrut'  v-model='selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattachesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG2CirculantClientsEtComptesRattachesAmrtProv'  v-model='selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattachesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifG2CirculantClientsEtComptesRattaches'  v-model='selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattaches'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG2CirculantClientsEtComptesRattachesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG2CirculantClientsEtComptesRattaches'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Personnel</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG3CirculantPersonnelBrut'  v-model='selectedBilanItemLocal.actifG3CirculantPersonnelBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG3CirculantPersonnelAmrtProv'  v-model='selectedBilanItemLocal.actifG3CirculantPersonnelAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifG3CirculantPersonnel'  v-model='selectedBilanItemLocal.actifG3CirculantPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG3CirculantPersonnelAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG3CirculantPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Etat</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG4CirculantEtatBrut'  v-model='selectedBilanItemLocal.actifG4CirculantEtatBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG4CirculantEtatAmrtProv'  v-model='selectedBilanItemLocal.actifG4CirculantEtatAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifG4CirculantEtat'  v-model='selectedBilanItemLocal.actifG4CirculantEtat'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG4CirculantEtatAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG4CirculantEtat'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Comptes d'associés</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG5CirculantCompteAssociesBrut'  v-model='selectedBilanItemLocal.actifG5CirculantCompteAssociesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG5CirculantCompteAssociesAmrtProv'  v-model='selectedBilanItemLocal.actifG5CirculantCompteAssociesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifG5CirculantCompteAssocies'  v-model='selectedBilanItemLocal.actifG5CirculantCompteAssocies'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG5CirculantCompteAssociesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG5CirculantCompteAssocies'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres débiteurs</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG6CirculantAutresDebiteursBrut'  v-model='selectedBilanItemLocal.actifG6CirculantAutresDebiteursBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG6CirculantAutresDebiteursAmrtProv'  v-model='selectedBilanItemLocal.actifG6CirculantAutresDebiteursAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifG6CirculantAutresDebiteurs'  v-model='selectedBilanItemLocal.actifG6CirculantAutresDebiteurs'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG6CirculantAutresDebiteursAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG6CirculantAutresDebiteurs'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Compte de régularisation actif</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG7CirculantCompteRegularisationBrut'  v-model='selectedBilanItemLocal.actifG7CirculantCompteRegularisationBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG7CirculantCompteRegularisationAmrtProv'  v-model='selectedBilanItemLocal.actifG7CirculantCompteRegularisationAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifG7CirculantCompteRegularisation'  v-model='selectedBilanItemLocal.actifG7CirculantCompteRegularisation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG7CirculantCompteRegularisationAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG7CirculantCompteRegularisation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> TITRES ET VALEUR DE PLACEMENT (h)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifHCirculantTitresEtValeursDePlacementBrut'  v-model='selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacementBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifHCirculantTitresEtValeursDePlacementAmrtProv'  v-model='selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacementAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifHCirculantTitresEtValeursDePlacement'  v-model='selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacement'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifHCirculantTitresEtValeursDePlacement"
                                                :champCalcule="getCalculValue('actifHCirculantTitresEtValeursDePlacement')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifHCirculantTitresEtValeursDePlacementAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifHCirculantTitresEtValeursDePlacement'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifHCirculantTitresEtValeursDePlacementAnneeN1"
                                                :champCalcule="getCalculValue('actifHCirculantTitresEtValeursDePlacementAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> ECART DE CONVERSION - ACTIF (i)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifICirculantEcartDeConversionBrut'  v-model='selectedBilanItemLocal.actifICirculantEcartDeConversionBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifICirculantEcartDeConversionAmrtProv'  v-model='selectedBilanItemLocal.actifICirculantEcartDeConversionAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifICirculantEcartDeConversion'  v-model='selectedBilanItemLocal.actifICirculantEcartDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifICirculantEcartDeConversion"
                                                :champCalcule="getCalculValue('actifICirculantEcartDeConversion')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifICirculantEcartDeConversionAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifICirculantEcartDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifICirculantEcartDeConversionAnneeN1"
                                                :champCalcule="getCalculValue('actifICirculantEcartDeConversionAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol"> TOTAL  II   ( f+g+h+i)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTotalIICirculantTotalBrut'   v-model='selectedBilanItemLocal.actifTotalIICirculantTotalBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTotalIICirculantTotalAmrtProv'   v-model='selectedBilanItemLocal.actifTotalIICirculantTotalAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifTotalIICirculantTotal'   v-model='selectedBilanItemLocal.actifTotalIICirculantTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalIICirculantTotal"
                                                :champCalcule="getCalculValue('actifTotalIICirculantTotal')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTotalIICirculantTotalAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifTotalIICirculantTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalIICirculantTotalAnneeN1"
                                                :champCalcule="getCalculValue('actifTotalIICirculantTotalAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="4" style="text-align:center" class="bilanMargeVerticale"> T<br>R<br>E<br>S<br>O</td>
                                            <td class="titleCol">Chèques et valeurs à encaisser</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor1TresorerieChequesEtValeursAEncaisserBrut'  v-model='selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisserBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor1TresorerieChequesEtValeursAEncaisserAmrtProv'  v-model='selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisserAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifTresor1TresorerieChequesEtValeursAEncaisser'  v-model='selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisser'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTresor1TresorerieChequesEtValeursAEncaisserAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifTresor1TresorerieChequesEtValeursAEncaisser'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Banques, T.G & CP</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor2TresorerieBanquesTGEtCPBrut'  v-model='selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCPBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor2TresorerieBanquesTGEtCPAmrtProv'  v-model='selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCPAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifTresor2TresorerieBanquesTGEtCP'  v-model='selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCP'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTresor2TresorerieBanquesTGEtCPAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifTresor2TresorerieBanquesTGEtCP'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Caisses, régies d'avances et accréditifs</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut'  v-model='selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAmrtProv'  v-model='selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs'  v-model='selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol"> TOTAL  III</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresorTresorerieTotalBrut'   v-model='selectedBilanItemLocal.actifTresorTresorerieTotalBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresorTresorerieTotalAmrtProv'   v-model='selectedBilanItemLocal.actifTresorTresorerieTotalAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div>
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifTresorTresorerieTotal'   v-model='selectedBilanItemLocal.actifTresorTresorerieTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTresorTresorerieTotal"
                                                :champCalcule="getCalculValue('actifTresorTresorerieTotal')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div>
                                                <InputNumber v-show="true" v-if="selectedBilanItemLocalAnneeN1" :disabled="!bilanFieldsN1Enabled" name='actifTresorTresorerieTotalAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifTresorTresorerieTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTresorTresorerieTotalAnneeN1"
                                                :champCalcule="getCalculValue('actifTresorTresorerieTotalAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanTotalGeneral">
                                            <td rowspan="1" style="text-align:center"> </td>
                                            <td class="titleCol titleGroupCol"> TOTAL GENERAL  I+II+III</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTotalGeneralBrut'   v-model='selectedBilanItemLocal.actifTotalGeneralBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTotalGeneralAmrtProv'   v-model='selectedBilanItemLocal.actifTotalGeneralAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='actifTotalGeneral'   v-model='selectedBilanItemLocal.actifTotalGeneral'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalGeneral"
                                                :champCalcule="getCalculValue('actifTotalGeneral')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTotalGeneralAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.actifTotalGeneral'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalGeneralAnneeN1"
                                                :champCalcule="getCalculValue('actifTotalGeneralAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    </div>

                                    <div class="bilanPassif pageBreak">
                                    <!-- *********************** BILAN (PASSIF) ******************************* -->
                                    <table style="width:100%">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>Net</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="23" class="bilanMargeVerticale"> F<br>I<br>N<br>A<br>N<br>C<br>E<br>M<br>E<br>N<br>T<br> <br>P<br>E<br>R<br>M<br>A<br>N<br>E<br>N<br>T       </td>
                                            <td class="titleCol titleGroupCol"> CAPITAUX PROPRES</td>
                                            <td class="YearN"><div><InputNumber v-show="true"  name='passifAFinancementPermanentCapitauxPropres'  v-model='selectedBilanItemLocal.passifAFinancementPermanentCapitauxPropres'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifAFinancementPermanentCapitauxPropres"
                                                :champCalcule="getCalculValue('passifAFinancementPermanentCapitauxPropres')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true"  name='passifAFinancementPermanentCapitauxPropresAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.passifAFinancementPermanentCapitauxPropres'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifAFinancementPermanentCapitauxPropresAnneeN1"
                                                :champCalcule="getCalculValue('passifAFinancementPermanentCapitauxPropresAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Capital social ou personnel (1)</td>
                                            <td><InputNumber v-show="true" name='passifA2FinancementPermanentCapitalPersonnel' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA2FinancementPermanentCapitalPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA2FinancementPermanentCapitalPersonnelAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA2FinancementPermanentCapitalPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">moins: Actionnaires, capital souscrit non appelé dont versé</td>
                                            <td><InputNumber v-show="true" name='passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverseAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Prime d'émission, de fusion, d'apport</td>
                                            <td><InputNumber v-show="true" name='passifA6FinancementPermanentPrimeEmissionFusionApport' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA6FinancementPermanentPrimeEmissionFusionApport'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA6FinancementPermanentPrimeEmissionFusionApportAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA6FinancementPermanentPrimeEmissionFusionApport'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Ecarts de réévaluation</td>
                                            <td><InputNumber v-show="true" name='passifA7FinancementPermanentEcartsDeReevaluation' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA7FinancementPermanentEcartsDeReevaluation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA7FinancementPermanentEcartsDeReevaluationAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA7FinancementPermanentEcartsDeReevaluation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Réserve légale</td>
                                            <td><InputNumber v-show="true" name='passifA8FinancementPermanentReserveLegale' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA8FinancementPermanentReserveLegale'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA8FinancementPermanentReserveLegaleAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA8FinancementPermanentReserveLegale'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres réserves</td>
                                            <td><InputNumber v-show="true" name='passifA9FinancementPermanentAutresReserves' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA9FinancementPermanentAutresReserves'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA9FinancementPermanentAutresReservesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA9FinancementPermanentAutresReserves'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Report à nouveau (2)</td>
                                            <td><InputNumber v-show="true" name='passifA10FinancementPermanentReportANouveau' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA10FinancementPermanentReportANouveau'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA10FinancementPermanentReportANouveauAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA10FinancementPermanentReportANouveau'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Résultat nets en instance d'affectation (2)</td>
                                            <td><InputNumber v-show="true" name='passifA11FinancementPermanentResultatNetsEnInstanceDAffectation' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA11FinancementPermanentResultatNetsEnInstanceDAffectation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA11FinancementPermanentResultatNetsEnInstanceDAffectationAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA11FinancementPermanentResultatNetsEnInstanceDAffectation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Résultat net de l'exercice (2)</td>
                                            <td><InputNumber v-show="true" name='passifA12FinancementPermanentResultatNetDeLexercice' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA12FinancementPermanentResultatNetDeLexercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA12FinancementPermanentResultatNetDeLexerciceAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA12FinancementPermanentResultatNetDeLexercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> CAPITAUX PROPRES ASSIMILES (b)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifBFinancementPermanentCapitauxPropresAssimiles'   v-model='selectedBilanItemLocal.passifBFinancementPermanentCapitauxPropresAssimiles'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifAFinancementPermanentCapitauxPropres"
                                                :champCalcule="getCalculValue('passifAFinancementPermanentCapitauxPropres')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifBFinancementPermanentCapitauxPropresAssimilesAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.passifBFinancementPermanentCapitauxPropresAssimiles'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifBFinancementPermanentCapitauxPropresAssimilesAnneeN1"
                                                :champCalcule="getCalculValue('passifBFinancementPermanentCapitauxPropresAssimilesAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Subventions d'investissement</td>
                                            <td><InputNumber v-show="true" name='passifB1FinancementPermanentSubventionInvestissement' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifB1FinancementPermanentSubventionInvestissement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifB1FinancementPermanentSubventionInvestissementAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifB1FinancementPermanentSubventionInvestissement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Provisions réglementées</td>
                                            <td><InputNumber v-show="true" name='passifB2FinancementPermanentProvisionReglementees' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifB2FinancementPermanentProvisionReglementees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifB2FinancementPermanentProvisionReglementeesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifB2FinancementPermanentProvisionReglementees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> DETTES DE FINANCEMENT (c)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifCFinancementPermanentDettesDeFinancement'   v-model='selectedBilanItemLocal.passifCFinancementPermanentDettesDeFinancement'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifCFinancementPermanentDettesDeFinancement"
                                                :champCalcule="getCalculValue('passifCFinancementPermanentDettesDeFinancement')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifCFinancementPermanentDettesDeFinancementAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.passifCFinancementPermanentDettesDeFinancement'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifCFinancementPermanentDettesDeFinancementAnneeN1"
                                                :champCalcule="getCalculValue('passifCFinancementPermanentDettesDeFinancementAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Emprunts obligataires</td>
                                            <td><InputNumber v-show="true" name='passifC1FinancementPermanentEmpruntsObligataires' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifC1FinancementPermanentEmpruntsObligataires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifC1FinancementPermanentEmpruntsObligatairesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifC1FinancementPermanentEmpruntsObligataires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres dettes de financement</td>
                                            <td><InputNumber v-show="true" name='passifC2FinancementPermanentAutresDettesDeFinancement' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifC2FinancementPermanentAutresDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifC2FinancementPermanentAutresDettesDeFinancementAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifC2FinancementPermanentAutresDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> PROVISIONS DURABLES POUR RISQUES ET CHARGES (d)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges'  v-model='selectedBilanItemLocal.passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges"
                                                :champCalcule="getCalculValue('passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifDFinancementPermanentProvisionsDurablesPourRisquesEtChargesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifDFinancementPermanentProvisionsDurablesPourRisquesEtChargesAnneeN1"
                                                :champCalcule="getCalculValue('passifDFinancementPermanentProvisionsDurablesPourRisquesEtChargesAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Provisions pour risques</td>
                                            <td><InputNumber v-show="true" name='passifD1FinancementPermanentProvisionsPourRisques' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifD1FinancementPermanentProvisionsPourRisques'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifD1FinancementPermanentProvisionsPourRisquesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifD1FinancementPermanentProvisionsPourRisques'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol"> Provisions pour charges</td>
                                            <td><InputNumber v-show="true" name='passifD2FinancementPermanentProvisionsPourCharges' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifD2FinancementPermanentProvisionsPourCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifD2FinancementPermanentProvisionsPourChargesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifD2FinancementPermanentProvisionsPourCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">ECARTS DE CONVERSION - PASSIF (e)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifEFinancementPermanentEcartsDeConversion'  v-model='selectedBilanItemLocal.passifEFinancementPermanentEcartsDeConversion'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifEFinancementPermanentEcartsDeConversion"
                                                :champCalcule="getCalculValue('passifEFinancementPermanentEcartsDeConversion')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifEFinancementPermanentEcartsDeConversionAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.passifEFinancementPermanentEcartsDeConversion'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifEFinancementPermanentEcartsDeConversionAnneeN1"
                                                :champCalcule="getCalculValue('passifEFinancementPermanentEcartsDeConversionAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Augmentation des créances immobilisées</td>
                                            <td><InputNumber v-show="true" name='passifE1FinancementPermanentAugmentationDesCreancesImmobilisees' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifE1FinancementPermanentAugmentationDesCreancesImmobilisees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifE1FinancementPermanentAugmentationDesCreancesImmobiliseesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifE1FinancementPermanentAugmentationDesCreancesImmobilisees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Diminution des dettes de financement</td>
                                            <td><InputNumber v-show="true" name='passifE2FinancementPermanentDiminutionDesDettesDeFinancement' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifE2FinancementPermanentDiminutionDesDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifE2FinancementPermanentDiminutionDesDettesDeFinancementAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifE2FinancementPermanentDiminutionDesDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> TOTAL  I  ( a+b+c+d+e )</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTotalIFinancementPermanentTotal'  v-model='selectedBilanItemLocal.passifTotalIFinancementPermanentTotal'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTotalIFinancementPermanentTotal"
                                                :champCalcule="getCalculValue('passifTotalIFinancementPermanentTotal')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTotalIFinancementPermanentTotalAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.passifTotalIFinancementPermanentTotal'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTotalIFinancementPermanentTotalAnneeN1"
                                                :champCalcule="getCalculValue('passifTotalIFinancementPermanentTotalAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="12" class="bilanMargeVerticale"> P<br/>A<br/>S<br/>S<br/>I<br/>F<br/> <br/>C<br/>I<br/>R<br/>C<br/>U     </td>
                                            <td class="titleCol titleGroupCol"> DETTES DU PASSIF CIRCULANT (f)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifFCirculantDettesDuCirculant'  v-model='selectedBilanItemLocal.passifFCirculantDettesDuCirculant'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifFCirculantDettesDuCirculant"
                                                :champCalcule="getCalculValue('passifFCirculantDettesDuCirculant')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifFCirculantDettesDuCirculantAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.passifFCirculantDettesDuCirculant'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifFCirculantDettesDuCirculantAnneeN1"
                                                :champCalcule="getCalculValue('passifFCirculantDettesDuCirculantAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Fournisseurs et comptes rattachés</td>
                                            <td><InputNumber v-show="true" name='passifF1CirculantFournisseursEtComptesRattaches' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF1CirculantFournisseursEtComptesRattaches'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF1CirculantFournisseursEtComptesRattachesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF1CirculantFournisseursEtComptesRattaches'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Clients créditeurs, avances et acomptes</td>
                                            <td><InputNumber v-show="true" name='passifF2CirculantClientsCrediteursAvancesEtAcomptes' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF2CirculantClientsCrediteursAvancesEtAcomptes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF2CirculantClientsCrediteursAvancesEtAcomptesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF2CirculantClientsCrediteursAvancesEtAcomptes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Personnel</td>
                                            <td><InputNumber v-show="true" name='passifF3CirculantPersonnel' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF3CirculantPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF3CirculantPersonnelAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF3CirculantPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Organismes sociaux</td>
                                            <td><InputNumber v-show="true" name='passifF4CirculantOrganismesSociaux' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF4CirculantOrganismesSociaux'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF4CirculantOrganismesSociauxAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF4CirculantOrganismesSociaux'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Etat</td>
                                            <td><InputNumber v-show="true" name='passifF5CirculantEtat' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF5CirculantEtat'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF5CirculantEtatAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF5CirculantEtat'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Comptes d'associés</td>
                                            <td><InputNumber v-show="true" name='passifF6CirculantComptesAssocies' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF6CirculantComptesAssocies'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF6CirculantComptesAssociesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF6CirculantComptesAssocies'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres créanciers</td>
                                            <td><InputNumber v-show="true" name='passifF7CirculantAutresCreanciers' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF7CirculantAutresCreanciers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF7CirculantAutresCreanciersAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF7CirculantAutresCreanciers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Comptes de régularisation - passif</td>
                                            <td><InputNumber v-show="true" name='passifF8CirculantComptesDeRegularisation' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF8CirculantComptesDeRegularisation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF8CirculantComptesDeRegularisationAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF8CirculantComptesDeRegularisation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> AUTRES PROVISIONS POUR RISQUES ET CHARGES (g)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifGCirculantAutresProvisionsPourRisquesEtCharges' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifGCirculantAutresProvisionsPourRisquesEtCharges'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifGCirculantAutresProvisionsPourRisquesEtCharges"
                                                :champCalcule="getCalculValue('passifGCirculantAutresProvisionsPourRisquesEtCharges')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifGCirculantAutresProvisionsPourRisquesEtChargesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifGCirculantAutresProvisionsPourRisquesEtCharges'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifGCirculantAutresProvisionsPourRisquesEtChargesAnneeN1"
                                                :champCalcule="getCalculValue('passifGCirculantAutresProvisionsPourRisquesEtChargesAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> ECARTS DE CONVERSION - PASSIF (h)( Eléments circulants )</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifHCirculantEcartsDeConversion' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifHCirculantEcartsDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifHCirculantEcartsDeConversion"
                                                :champCalcule="getCalculValue('passifHCirculantEcartsDeConversion')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifHCirculantEcartsDeConversionAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifHCirculantEcartsDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifHCirculantEcartsDeConversionAnneeN1"
                                                :champCalcule="getCalculValue('passifHCirculantEcartsDeConversionAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> TOTAL  II  ( f+g+h )</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTotalIICirculantTotal'  v-model='selectedBilanItemLocal.passifTotalIICirculantTotal'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTotalIICirculantTotal"
                                                :champCalcule="getCalculValue('passifTotalIICirculantTotal')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTotalIICirculantTotalAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.passifTotalIICirculantTotal'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTotalIICirculantTotalAnneeN1"
                                                :champCalcule="getCalculValue('passifTotalIICirculantTotalAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="5" class="bilanMargeVerticale"> T<br/>R<br/>E<br/>S<br/>O        </td>
                                            <td class="titleCol titleGroupCol"> TRESORERIE PASSIF</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTresorTresorerieTresoreriePassif'  v-model='selectedBilanItemLocal.passifTresorTresorerieTresoreriePassif'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTresorTresorerieTresoreriePassif"
                                                :champCalcule="getCalculValue('passifTresorTresorerieTresoreriePassif')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTresorTresorerieTresoreriePassifAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.passifTresorTresorerieTresoreriePassif'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTresorTresorerieTresoreriePassifAnneeN1"
                                                :champCalcule="getCalculValue('passifTresorTresorerieTresoreriePassifAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Crédits d'escompte</td>
                                            <td><InputNumber v-show="true" name='passifTresor1TresorerieCreditsEscompte' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifTresor1TresorerieCreditsEscompte'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifTresor1TresorerieCreditsEscompteAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifTresor1TresorerieCreditsEscompte'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Crédit de trésorerie</td>
                                            <td><InputNumber v-show="true" name='passifTresor2TresorerieCreditDeTresorerie' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifTresor2TresorerieCreditDeTresorerie'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifTresor2TresorerieCreditDeTresorerieAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifTresor2TresorerieCreditDeTresorerie'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Banques ( soldes créditeurs )</td>
                                            <td><InputNumber v-show="true" name='passifTresor3TresorerieBanques' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifTresor3TresorerieBanques'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifTresor3TresorerieBanquesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifTresor3TresorerieBanques'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> TOTAL  III</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTresorTresorerieTotalIII'  v-model='selectedBilanItemLocal.passifTresorTresorerieTotalIII'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="TresorTresorerieTotalIII"
                                                :champCalcule="getCalculValue('TresorTresorerieTotalIII')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTresorTresorerieTotalIIIAnneeN1'  v-model='TresorTresorerieTotalIII'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTresorTresorerieTotalIIIAnneeN1"
                                                :champCalcule="getCalculValue('passifTresorTresorerieTotalIIIAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanTotalGeneral">
                                            <td style="text-align:center" rowspan="1" class="bilanMargeVerticale">      </td>
                                            <td class="titleCol titleGroupCol"> TOTAL   I+II+III</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTotalGeneral'  v-model='selectedBilanItemLocal.passifTotalGeneral'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsEnabled" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="passifTotalGeneral"
                                                :champCalcule="getCalculValue('passifTotalGeneral')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTotalGeneralAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.passifTotalGeneral'  mode='decimal'  :minFractionDigits='2' :disabled="!bilanFieldsN1Enabled" />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="passifTotalGeneralAnneeN1"
                                                :champCalcule="getCalculValue('passifTotalGeneralAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                
                                    </div>
                                    
                                    <div class="bilanCPC pageBreak">
                                    <!-- *********************** BILAN (CPC) ******************************* -->
                                    <table>
                                        <tr v-if="!inforiskLocal">
                                            <th></th>
                                            <th></th>
                                            <th>Exercice</th>
                                            <th>Exercice précedent</th>
                                            <th>TOTAUX EXERCICE</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr v-else>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>TOTAUX EXERCICE</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="18" class="bilanMargeVerticale"> E<br>X<br>P<br>L<br>O<br>I<br>T<br>A<br>T<br>I<br>O<br>N<br></td>
                                            <td class="titleCol titleGroupCol">I PRODUITS D'EXPLOITATION</td>        
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIExploitationProduitsExploitationExercice' v-model='selectedBilanItemLocal.cpcIExploitationProduitsExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIExploitationProduitsExploitationPrecedent' v-model='selectedBilanItemLocal.cpcIExploitationProduitsExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIExploitationProduitsExploitation' v-model='selectedBilanItemLocal.cpcIExploitationProduitsExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIExploitationProduitsExploitation"
                                                :champCalcule="getCalculValue('cpcIExploitationProduitsExploitation')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIExploitationProduitsExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIExploitationProduitsExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIExploitationProduitsExploitationAnneeN1"
                                                :champCalcule="getCalculValue('cpcIExploitationProduitsExploitationAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Ventes de marchandises</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI1ExploitationVentesDeMarchandisesExercice' v-model='selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandisesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI1ExploitationVentesDeMarchandisesPrecedent' v-model='selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandisesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcI1ExploitationVentesDeMarchandises' v-model='selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI1ExploitationVentesDeMarchandisesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI1ExploitationVentesDeMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Ventes de biens et services produits</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI2ExploitationVentesBiensEtServicesProduitsExercice' v-model='selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduitsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI2ExploitationVentesBiensEtServicesProduitsPrecedent' v-model='selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduitsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcI2ExploitationVentesBiensEtServicesProduits' v-model='selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI2ExploitationVentesBiensEtServicesProduitsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI2ExploitationVentesBiensEtServicesProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr style="background-color: rgb(227, 227, 227);">
                                            <td class="titleCol">Chiffres d'affaires</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI3ExploitationChiffresAffairesExercice' v-model='selectedBilanItemLocal.cpcI3ExploitationChiffresAffairesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI3ExploitationChiffresAffairesPrecedent' v-model='selectedBilanItemLocal.cpcI3ExploitationChiffresAffairesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcI3ExploitationChiffresAffaires' v-model='selectedBilanItemLocal.cpcI3ExploitationChiffresAffaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI3ExploitationChiffresAffairesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI3ExploitationChiffresAffaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Variation de stock de produits</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI4ExploitationVariationDeStockDeProduitsExercice' v-model='selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduitsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI4ExploitationVariationDeStockDeProduitsPrecedent' v-model='selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduitsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcI4ExploitationVariationDeStockDeProduits' v-model='selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI4ExploitationVariationDeStockDeProduitsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI4ExploitationVariationDeStockDeProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Immobilisations produites pour l'Ese p/elle même</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice' v-model='selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI5ExploitationImmobilisationsProduitesPourElleMemePrecedent' v-model='selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMemePrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcI5ExploitationImmobilisationsProduitesPourElleMeme' v-model='selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMeme'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI5ExploitationImmobilisationsProduitesPourElleMemeAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI5ExploitationImmobilisationsProduitesPourElleMeme'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Subvention d'exploitation</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI6ExploitationSubventionDExploitationExercice' v-model='selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI6ExploitationSubventionDExploitationPrecedent' v-model='selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcI6ExploitationSubventionDExploitation' v-model='selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI6ExploitationSubventionDExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI6ExploitationSubventionDExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres produits d'exploitation</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI7ExploitationAutresProduitsExploitationExercice' v-model='selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI7ExploitationAutresProduitsExploitationPrecedent' v-model='selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcI7ExploitationAutresProduitsExploitation' v-model='selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI7ExploitationAutresProduitsExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI7ExploitationAutresProduitsExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Reprises d'exploitation; transfert de charges</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice' v-model='selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI8ExploitationReprisesExploitationTransfertDeChargesPrecedent' v-model='selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeChargesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcI8ExploitationReprisesExploitationTransfertDeCharges' v-model='selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI8ExploitationReprisesExploitationTransfertDeChargesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI8ExploitationReprisesExploitationTransfertDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">II CHARGES D'EXPLOITATION</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIIExploitationChargesExploitationExercice'  v-model='selectedBilanItemLocal.cpcIIExploitationChargesExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIIExploitationChargesExploitationPrecedent'  v-model='selectedBilanItemLocal.cpcIIExploitationChargesExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIIExploitationChargesExploitation'  v-model='selectedBilanItemLocal.cpcIIExploitationChargesExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIIExploitationChargesExploitation"
                                                :champCalcule="getCalculValue('cpcIIExploitationChargesExploitation')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIIExploitationChargesExploitationAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcIIExploitationChargesExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIIExploitationChargesExploitationAnneeN1"
                                                :champCalcule="getCalculValue('cpcIIExploitationChargesExploitationAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Achats revendus de marchandises</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII1ExploitationAchatsRevendusDeMarchandisesExercice' v-model='selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandisesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII1ExploitationAchatsRevendusDeMarchandisesPrecedent' v-model='selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandisesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcII1ExploitationAchatsRevendusDeMarchandises' v-model='selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII1ExploitationAchatsRevendusDeMarchandisesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII1ExploitationAchatsRevendusDeMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Achat consommes de matières et de fournitures</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice' v-model='selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresPrecedent' v-model='selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures' v-model='selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres charges externes</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII3ExploitationAutresChargesExternesExercice' v-model='selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII3ExploitationAutresChargesExternesPrecedent' v-model='selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcII3ExploitationAutresChargesExternes' v-model='selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII3ExploitationAutresChargesExternesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII3ExploitationAutresChargesExternes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Impôts et taxes</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII4ExploitationImpotsEtTaxesExercice' v-model='selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII4ExploitationImpotsEtTaxesPrecedent' v-model='selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcII4ExploitationImpotsEtTaxes' v-model='selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII4ExploitationImpotsEtTaxesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII4ExploitationImpotsEtTaxes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Charges de personnel</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII5ExploitationChargesPersonnelExercice' v-model='selectedBilanItemLocal.cpcII5ExploitationChargesPersonnelExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII5ExploitationChargesPersonnelPrecedent' v-model='selectedBilanItemLocal.cpcII5ExploitationChargesPersonnelPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcII5ExploitationChargesPersonnel' v-model='selectedBilanItemLocal.cpcII5ExploitationChargesPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII5ExploitationChargesPersonnelAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII5ExploitationChargesPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres charges d'exploitation</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII6ExploitationAutresChargesExploitationExercice' v-model='selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII6ExploitationAutresChargesExploitationPrecedent' v-model='selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcII6ExploitationAutresChargesExploitation' v-model='selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII6ExploitationAutresChargesExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII6ExploitationAutresChargesExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Dotations d'exploitation</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII7ExploitationDotationsExploitationExercice' v-model='selectedBilanItemLocal.cpcII7ExploitationDotationsExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII7ExploitationDotationsExploitationPrecedent' v-model='selectedBilanItemLocal.cpcII7ExploitationDotationsExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcII7ExploitationDotationsExploitation' v-model='selectedBilanItemLocal.cpcII7ExploitationDotationsExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII7ExploitationDotationsExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII7ExploitationDotationsExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">III RESULTAT D'EXPLOITATION  ( I - II )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIIIExploitationResultatExploitationExercice'  v-model='selectedBilanItemLocal.cpcIIIExploitationResultatExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIIIExploitationResultatExploitationPrecedent'  v-model='selectedBilanItemLocal.cpcIIIExploitationResultatExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIIIExploitationResultatExploitation'  v-model='selectedBilanItemLocal.cpcIIIExploitationResultatExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIIIExploitationResultatExploitation"
                                                :champCalcule="getCalculValue('cpcIIIExploitationResultatExploitation')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIIIExploitationResultatExploitationAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcIIIExploitationResultatExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIIIExploitationResultatExploitationAnneeN1"
                                                :champCalcule="getCalculValue('cpcIIIExploitationResultatExploitationAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="11" class="bilanMargeVerticale"> F<br>I<br>N<br>A<br>N<br>C<br>I<br>E<br>R<br></td>
                                            <td class="titleCol titleGroupCol">IV PRODUITS FINANCIERS</td>        
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIVFinancierProduitsFinanciersExercice'  v-model='selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersExercice'  mode='decimal'  :minFractionDigits='2'   /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIVFinancierProduitsFinanciersPrecedent'  v-model='selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersPrecedent'  mode='decimal'  :minFractionDigits='2'   /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIVFinancierProduitsFinanciers'  v-model='selectedBilanItemLocal.cpcIVFinancierProduitsFinanciers'  mode='decimal'  :minFractionDigits='2'   />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIVFinancierProduitsFinanciers"
                                                :champCalcule="getCalculValue('cpcIVFinancierProduitsFinanciers')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIVFinancierProduitsFinanciersAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcIVFinancierProduitsFinanciers'  mode='decimal'  :minFractionDigits='2'   />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIVFinancierProduitsFinanciersAnneeN1"
                                                :champCalcule="getCalculValue('cpcIVFinancierProduitsFinanciersAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            </tr>
                                        <tr>
                                            <td class="titleCol">Produits des titres de participation et autres titres immobilises</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice' v-model='selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesPrecedent' v-model='selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises' v-model='selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Gains de change</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV2FinancierGainsDeChangeExercice' v-model='selectedBilanItemLocal.cpcIV2FinancierGainsDeChangeExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV2FinancierGainsDeChangePrecedent' v-model='selectedBilanItemLocal.cpcIV2FinancierGainsDeChangePrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIV2FinancierGainsDeChange' v-model='selectedBilanItemLocal.cpcIV2FinancierGainsDeChange'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIV2FinancierGainsDeChangeAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIV2FinancierGainsDeChange'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Intérêts et autres produits financiers</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice' v-model='selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV3FinancierInteretsEtAutresProduitsFinanciersPrecedent' v-model='selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciersPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIV3FinancierInteretsEtAutresProduitsFinanciers' v-model='selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIV3FinancierInteretsEtAutresProduitsFinanciersAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIV3FinancierInteretsEtAutresProduitsFinanciers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Reprises financières; transfert de charges</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice' v-model='selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV4FinancierReprisesFinancieresTransfertDeChargesPrecedent' v-model='selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeChargesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIV4FinancierReprisesFinancieresTransfertDeCharges' v-model='selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIV4FinancierReprisesFinancieresTransfertDeChargesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIV4FinancierReprisesFinancieresTransfertDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">V CHARGES FINANCIERES</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVFinancierChargesFinancieresExercice'  v-model='selectedBilanItemLocal.cpcVFinancierChargesFinancieresExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVFinancierChargesFinancieresPrecedent'  v-model='selectedBilanItemLocal.cpcVFinancierChargesFinancieresPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVFinancierChargesFinancieres'  v-model='selectedBilanItemLocal.cpcVFinancierChargesFinancieres'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVFinancierChargesFinancieres"
                                                :champCalcule="getCalculValue('cpcVFinancierChargesFinancieres')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVFinancierChargesFinancieresAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcVFinancierChargesFinancieres'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVFinancierChargesFinancieresAnneeN1"
                                                :champCalcule="getCalculValue('cpcVFinancierChargesFinancieresAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Charges d'intérêts</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV1FinancierChargesInteretExercice' v-model='selectedBilanItemLocal.cpcV1FinancierChargesInteretExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV1FinancierChargesInteretPrecedent' v-model='selectedBilanItemLocal.cpcV1FinancierChargesInteretPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcV1FinancierChargesInteret' v-model='selectedBilanItemLocal.cpcV1FinancierChargesInteret'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcV1FinancierChargesInteretAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcV1FinancierChargesInteret'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Pertes de changes</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV2FinancierPertesDeChangesExercice' v-model='selectedBilanItemLocal.cpcV2FinancierPertesDeChangesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV2FinancierPertesDeChangesPrecedent' v-model='selectedBilanItemLocal.cpcV2FinancierPertesDeChangesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcV2FinancierPertesDeChanges' v-model='selectedBilanItemLocal.cpcV2FinancierPertesDeChanges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcV2FinancierPertesDeChangesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcV2FinancierPertesDeChanges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres charges financières</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV3FinancierAutresChargesFinancieresExercice' v-model='selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieresExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV3FinancierAutresChargesFinancieresPrecedent' v-model='selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieresPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcV3FinancierAutresChargesFinancieres' v-model='selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcV3FinancierAutresChargesFinancieresAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcV3FinancierAutresChargesFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Dotations financières</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV4FinancierDotationFinancieresExercice' v-model='selectedBilanItemLocal.cpcV4FinancierDotationFinancieresExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV4FinancierDotationFinancieresPrecedent' v-model='selectedBilanItemLocal.cpcV4FinancierDotationFinancieresPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcV4FinancierDotationFinancieres' v-model='selectedBilanItemLocal.cpcV4FinancierDotationFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcV4FinancierDotationFinancieresAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcV4FinancierDotationFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">VI RESULTAT FINANCIER ( IV - V )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIFinancierResultatFinancierExercice'  v-model='selectedBilanItemLocal.cpcVIFinancierResultatFinancierExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIFinancierResultatFinancierPrecedent'  v-model='selectedBilanItemLocal.cpcVIFinancierResultatFinancierPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVIFinancierResultatFinancier'  v-model='selectedBilanItemLocal.cpcVIFinancierResultatFinancier'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIFinancierResultatFinancier"
                                                :champCalcule="getCalculValue('cpcVIFinancierResultatFinancier')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIFinancierResultatFinancierAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcVIFinancierResultatFinancier'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIFinancierResultatFinancierAnneeN1"
                                                :champCalcule="getCalculValue('cpcVIFinancierResultatFinancierAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="1" class="bilanMargeVerticale">
                                            </td>
                                            <td class="titleCol titleGroupCol">VII RESULTAT COURANT ( III + VI)</td>        
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIICourantResultatCourantExercice'  v-model='selectedBilanItemLocal.cpcVIICourantResultatCourantExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIICourantResultatCourantPrecedent'  v-model='selectedBilanItemLocal.cpcVIICourantResultatCourantPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVIICourantResultatCourant'  v-model='selectedBilanItemLocal.cpcVIICourantResultatCourant'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIICourantResultatCourant"
                                                :champCalcule="getCalculValue('cpcVIICourantResultatCourant')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIICourantResultatCourantAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcVIICourantResultatCourant'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIICourantResultatCourantAnneeN1"
                                                :champCalcule="getCalculValue('cpcVIICourantResultatCourantAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="12" class="bilanMargeVerticale"> N<br>O<br>N<br> <br>C<br>O<br>U<br>R<br>A<br>N<br>T<br></td>
                                            <td class="titleCol titleGroupCol">VIII PRODUITS NON COURANTS</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIIINonCourantProduitsNonCourantsExercice'  v-model='selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIIINonCourantProduitsNonCourantsPrecedent'  v-model='selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVIIINonCourantProduitsNonCourants'  v-model='selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourants'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIIINonCourantProduitsNonCourants"
                                                :champCalcule="getCalculValue('cpcVIIINonCourantProduitsNonCourants')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIIINonCourantProduitsNonCourantsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcVIIINonCourantProduitsNonCourants'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIIINonCourantProduitsNonCourantsAnneeN1"
                                                :champCalcule="getCalculValue('cpcVIIINonCourantProduitsNonCourantsAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Produits des cessions d'immobilisations</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice' v-model='selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsPrecedent' v-model='selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVIII1NonCourantProduitsDesCessionsDImmobilisations' v-model='selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisations'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII1NonCourantProduitsDesCessionsDImmobilisations'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Subventions d'équilibre</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII2NonCourantSubventionsDEquilibreExercice' v-model='selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibreExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII2NonCourantSubventionsDEquilibrePrecedent' v-model='selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibrePrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVIII2NonCourantSubventionsDEquilibre' v-model='selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibre'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII2NonCourantSubventionsDEquilibreAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII2NonCourantSubventionsDEquilibre'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Reprises sur subventions d'investissement</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice' v-model='selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII3NonCourantReprisesSurSubventionsDInvestissementPrecedent' v-model='selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissementPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVIII3NonCourantReprisesSurSubventionsDInvestissement' v-model='selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII3NonCourantReprisesSurSubventionsDInvestissementAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII3NonCourantReprisesSurSubventionsDInvestissement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres produits non courants</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII4NonCourantAutresProduitsNonCourantsExercice' v-model='selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourantsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII4NonCourantAutresProduitsNonCourantsPrecedent' v-model='selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourantsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVIII4NonCourantAutresProduitsNonCourants' v-model='selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourants'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII4NonCourantAutresProduitsNonCourantsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII4NonCourantAutresProduitsNonCourants'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Reprises non courantes; transferts de charges</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice' v-model='selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesPrecedent' v-model='selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges' v-model='selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">IX CHARGES NON COURANTES</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIXNonCourantChargesNonCourantesExercice'  v-model='selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIXNonCourantChargesNonCourantesPrecedent'  v-model='selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIXNonCourantChargesNonCourantes'  v-model='selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantes'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIXNonCourantChargesNonCourantes"
                                                :champCalcule="getCalculValue('cpcIXNonCourantChargesNonCourantes')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIXNonCourantChargesNonCourantesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcIXNonCourantChargesNonCourantes'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIXNonCourantChargesNonCourantesAnneeN1"
                                                :champCalcule="getCalculValue('cpcIXNonCourantChargesNonCourantesAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Valeurs nettes d'amortissements des immobilisations cédées</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice' v-model='selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesPrecedent' v-model='selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees' v-model='selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Subventions accordées</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX2NonCourantSubventionsAccordeesExercice' v-model='selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordeesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX2NonCourantSubventionsAccordeesPrecedent' v-model='selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordeesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIX2NonCourantSubventionsAccordees' v-model='selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIX2NonCourantSubventionsAccordeesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIX2NonCourantSubventionsAccordees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres charges non courantes</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX3NonCourantAutresChargesNonCourantesExercice' v-model='selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX3NonCourantAutresChargesNonCourantesPrecedent' v-model='selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIX3NonCourantAutresChargesNonCourantes' v-model='selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIX3NonCourantAutresChargesNonCourantesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIX3NonCourantAutresChargesNonCourantes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Dotations non courantes aux amortiss. et provision</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice' v-model='selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionPrecedent' v-model='selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision' v-model='selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">X RESULTAT NON COURANT ( VIII- IV )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXResultatNonCourantExercice'  v-model='selectedBilanItemLocal.cpcXResultatNonCourantExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXResultatNonCourantPrecedent'  v-model='selectedBilanItemLocal.cpcXResultatNonCourantPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXResultatNonCourant'  v-model='selectedBilanItemLocal.cpcXResultatNonCourant'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXResultatNonCourantAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcXResultatNonCourant'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="7" class="bilanMargeVerticale"></td>
                                            <td class="titleCol titleGroupCol">XI RESULTAT AVANT IMPOTS ( VII+ X )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIResultatAvantImpotsExercice'   v-model='selectedBilanItemLocal.cpcXIResultatAvantImpotsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIResultatAvantImpotsPrecedent'   v-model='selectedBilanItemLocal.cpcXIResultatAvantImpotsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXIResultatAvantImpots'   v-model='selectedBilanItemLocal.cpcXIResultatAvantImpots'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXIResultatAvantImpotsAnneeN1'   v-model='selectedBilanItemLocalAnneeN1.cpcXIResultatAvantImpots'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">XII IMPOTS SUR LES RESULTATS </td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIIImpotsSurLesResultatsExercice' v-model='selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIIImpotsSurLesResultatsPrecedent' v-model='selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXIIImpotsSurLesResultats' v-model='selectedBilanItemLocal.cpcXIIImpotsSurLesResultats'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcXIIImpotsSurLesResultatsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcXIIImpotsSurLesResultats'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol">XIII RESULTAT NET ( XI - XII )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIIIResultatNetExercice'  v-model='selectedBilanItemLocal.cpcXIIIResultatNetExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIIIResultatNetPrecedent'  v-model='selectedBilanItemLocal.cpcXIIIResultatNetPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXIIIResultatNet'  v-model='selectedBilanItemLocal.cpcXIIIResultatNet'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXIIIResultatNet"
                                                :champCalcule="getCalculValue('cpcXIIIResultatNet')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcXIIIResultatNetAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcXIIIResultatNet'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXIIIResultatNetAnneeN1"
                                                :champCalcule="getCalculValue('cpcXIIIResultatNetAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">XIV TOTAL DES PRODUITS ( I + IV + VIII )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIVTotalDesProduitsExercice'  v-model='selectedBilanItemLocal.cpcXIVTotalDesProduitsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIVTotalDesProduitsPrecedent'  v-model='selectedBilanItemLocal.cpcXIVTotalDesProduitsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div>
                                                    <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXIVTotalDesProduits'  v-model='selectedBilanItemLocal.cpcXIVTotalDesProduits'  mode='decimal'  :minFractionDigits='2'  />
                                                    <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXIVTotalDesProduits"
                                                    :champCalcule="getCalculValue('cpcXIVTotalDesProduits')" 
                                                    :selectedBilanItem="selectedBilanItemLocal"
                                                    
                                                    :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcXIVTotalDesProduitsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.cpcXIVTotalDesProduits'  mode='decimal'  :minFractionDigits='2'  />
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">XV TOTAL DES CHARGES ( II + V + IX + XII )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXVTotalDesChargesExercice' v-model='selectedBilanItemLocal.cpcXVTotalDesChargesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXVTotalDesChargesPrecedent' v-model='selectedBilanItemLocal.cpcXVTotalDesChargesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div>
                                                    <InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXVTotalDesCharges' v-model='selectedBilanItemLocal.cpcXVTotalDesCharges'  mode='decimal'  :minFractionDigits='2'  />
                                                    <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXVTotalDesCharges"
                                                        :champCalcule="getCalculValue('cpcXVTotalDesCharges')" 
                                                        :selectedBilanItem="selectedBilanItemLocal"
                                                        
                                                        :refMappingBilanListeChamps="refMappingBilanListeChamps">
                                                    </InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcXVTotalDesChargesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcXVTotalDesCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol">XVI RESULTAT NET ( XIV - XV )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXVIResultatNETExercice' v-model='selectedBilanItemLocal.cpcXVIResultatNETExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXVIResultatNETPrecedent' v-model='selectedBilanItemLocal.cpcXVIResultatNETPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="!bilanFieldsEnabled" name='cpcXVIResultatNET' v-model='selectedBilanItemLocal.cpcXVIResultatNET'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXVIResultatNET"
                                                :champCalcule="getCalculValue('cpcXVIResultatNET')" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcXVIResultatNETAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcXVIResultatNET'  mode='decimal'  :minFractionDigits='2'  />
                                                <!-- <InformationExclamationVue v-if="selectedBilanItemLocalAnneeN1" :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXVIResultatNETAnneeN1"
                                                :champCalcule="getCalculValue('cpcXVIResultatNETAnneeN1')" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                        <!-- *********************************************************** -->
                                </div>
                            </component>

                        </div>
                    </div>
                    <!-- ========================== second Column =========================== -->
                    <!-- =================================================================== -->
                </div>

               <div class="p-d-flex p-jc-end" style="display: flex;">

                    &nbsp;<ClientBilanLoadCsv v-if="(createBilanFlag || selectedBilanItemLocal.id) && !inforiskLocal && bilanFieldsEnabled && currentUser.jsonUserRights.gestionBilan" @BilanLoaded="onBilanLoaded"></ClientBilanLoadCsv>
                    &nbsp;<P-Button v-if="(createBilanFlag || selectedBilanItemLocal.id) && !inforiskLocal && bilanFieldsEnabled && currentUser.jsonUserRights.gestionBilan" class="p-button-success p-mr-2" @click = "downloadItem('/bilanexemple.csv')"  icon="pi pi-question-circle" v-tooltip="'Télécharger un modèle CSV pour l\'import'"></P-Button>
                    &nbsp;<P-Button v-if="selectedBilanItemLocal.id && currentUser.jsonUserRights.gestionBilan" label="Supprimer" class="p-button-raised p-button-danger" @click.prevent="confirmDeleteSelected(selectedBilanItemLocal)"/>
                    &nbsp;<P-Button label="Annuler" class="p-button-raised p-button-secondary"  @click.prevent="closeDialog" />
                    &nbsp;<P-Button v-if="!printable && (((selectedBilanItemLocal.id>0 || createBilanFlag) && currentUser.jsonUserRights.gestionBilan) || (selectedBilanItemLocal.ompic && selectedBilanItemLocal.ompic==1))" :label="((selectedBilanItemLocal.ompic && selectedBilanItemLocal.ompic==1) || selectedBilanItemLocal.inforisk==1 || inforiskLocal)?'Dupliquer':'Enregistrer'" class="p-button-raised"  @click="onSubmit" :loading="isSaving"  />
                    &nbsp;<P-Button v-if="!printable && !ompicLocal && !inforiskLocal && (createBilanFlag || selectedBilanItemLocal.id)" label="Calculer bilan" class="p-button-raised"  :loading="isSaving"  @click="recalculateBilan"/>
                </div>

            </V-Form>
            
        </Dialog>

        <!-- Delete Dialog -->
</template>

<script>
import Header from "../Header.vue";
import LoadPdfBilans from "../LoadPdfBilans.vue";
import InputSwitch from 'primevue/inputswitch';
// import CRUDService from "../1-common-components/crud.service";
import {} from 'vue-underscore';
import Button from 'primevue/button';
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper';
import { TabStrip } from '@progress/kendo-layout-vue-wrapper';
import CRUDService from "../../1-common-components/crud.service";
import { DateInput } from '@progress/kendo-dateinputs-vue-wrapper';
import Axios from 'axios';

import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import LoadCsv from "../LoadCsv.vue";
import CsvToDb from "../ClientCSVtoDB.service";
import UploadBilan from '../UploadBilan.vue'

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import crudService from '../../1-common-components/crud.service';
import Dropdown from 'primevue/dropdown';
import Password from 'primevue/password'
import InformationExclamationVue from './InformationExclamation.vue';



export default {
    components:{
        Header, 
        Dropdown,
        InputSwitch,
        Password,
        'LoadPdfBilans':LoadPdfBilans,
        'kendo-dropdownlist': DropDownList,
        'kendo-tabstrip': TabStrip,
        'kendo-dateinput': DateInput,
        Dialog,
        Button,
        ConfirmDialog,
        'V-Field':Field,
        'V-Form':Form,
        'ClientBilanLoadCsv' : LoadCsv,
        UploadBilan, 
        TabView,
        TabPanel,
        InformationExclamationVue,
        ErrorMessage
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            refMappingBilanListeChamps:[],
            ompicLocal:false,
            createBilanFlag:false,
            showBilanDialogFlagLocal:false,
            selectedBilanItemLocal : {},//new Bilan(),
            selectedBilanItemLocalAnneeN1 : {},
            inforiskLocal:false,
            years:[],
            anneesbilan:[],
            companyInforisk:null,
            isMounted:false,
            isSaving:false,
            InforiskLoading:false,
            foundBilanN1:false,
            deleting:false,
            credentials:{username:"",password:""},
            actifMapping:{
                            'actifAImmobiliseImmobilisationEnNonValeurBrut':'_dActifA',
                            'actifA1ImmobiliseFraisPreliminairesBrut':'_dActifA1',
                            'actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut':'_dActifA2',
                            'actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut':'_dActifA3',
                            'actifBImmobiliseImmobilisationsIncorporellesBrut':'_dActifB',
                            'actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut':'_dActifB1',
                            'actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut':'_dActifB2',
                            'actifB3ImmobiliseFondsCommercialBrut':'_dActifB3',
                            'actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut':'_dActifB4',
                            'actifCImmobiliseImmobilisationsCorporellesBrut':'_dActifC',
                            'actifC1ImmobiliseTerrainsBrut':'_dActifC1',
                            'actifC2ImmobiliseConstructionsBrut':'_dActifC2',
                            'actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut':'_dActifC3',
                            'actifC4ImmobiliseMaterielDeTransportBrut':'_dActifC4',
                            'actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut':'_dActifC5',
                            'actifC6ImmobiliseAutresImmobilisationsCorporellesBrut':'_dActifC6',
                            'actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut':'_dActifC7',
                            'actifDImmobiliseImmobilisationsFinancieresBrut':'_dActifD',
                            'actifD1ImmobilisePretsImmobilisesBrut':'_dActifD1',
                            'actifD2ImmobiliseAutresCreancesFinancieresBrut':'_dActifD2',
                            'actifD3ImmobiliseTitresDeParticipationBrut':'_dActifD3',
                            'actifD4ImmobiliseAutresTitresImmobilisesBrut':'_dActifD4',
                            'actifEImmobiliseEcartsDeConversionBrut':'_dActifE',
                            'actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut':'_dActifE1',
                            'actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut':'_dActifE2',
                            'actifTotalIImmobiliseTotalBrut':'_dActifTotal_I',
                            'actifFCirculantStocksBrut':'_dActifF',
                            'actifF1CirculantMarchandisesBrut':'_dActifF1',
                            'actifF2CirculantMatieresEtFournituresConsommablesBrut':'_dActifF2',
                            'actifF3CirculantProduitsEnCoursBrut':'_dActifF3',
                            'actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut':'_dActifF4',
                            'actifF5CirculantProduitsFinisBrut':'_dActifF5',
                            'actifGCirculantCreancesCirculantBrut':'_dActifG',
                            'actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut':'_dActifG1',
                            'actifG2CirculantClientsEtComptesRattachesBrut':'_dActifG2',
                            'actifG3CirculantPersonnelBrut':'_dActifG3',
                            'actifG4CirculantEtatBrut':'_dActifG4',
                            'actifG5CirculantCompteAssociesBrut':'_dActifG5',
                            'actifG6CirculantAutresDebiteursBrut':'_dActifG6',
                            'actifG7CirculantCompteRegularisationBrut':'_dActifG7',
                            'actifHCirculantTitresEtValeursDePlacementBrut':'_dActifH',                            
                            'actifICirculantEcartDeConversionBrut':'_dActifI',
                            'actifTotalIICirculantTotalBrut':'_dActifTotal_II',
                            'actifTresor1TresorerieChequesEtValeursAEncaisserBrut':'_dActifTresor1',
                            'actifTresor2TresorerieBanquesTGEtCPBrut':'_dActifTresor2',
                            'actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut':'_dActifTresor3',
                            'actifTresorTresorerieTotalBrut':'_dActifTresor',
                            'actifTotalGeneralBrut':'_dActifTotalGeneral',

                            'actifAImmobiliseImmobilisationEnNonValeur':'_dActifA',
                            'actifA1ImmobiliseFraisPreliminaires':'_dActifA1',
                            'actifA2ImmobiliseChargesArepartirSurplusieursExercices':'_dActifA2',
                            'actifA3ImmobilisePrimesDeRemboursementDesObligations':'_dActifA3',
                            'actifBImmobiliseImmobilisationsIncorporelles':'_dActifB',
                            'actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement':'_dActifB1',
                            'actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires':'_dActifB2',
                            'actifB3ImmobiliseFondsCommercial':'_dActifB3',
                            'actifB4ImmobiliseAutresImmobilisationsIncorporelles':'_dActifB4',
                            'actifCImmobiliseImmobilisationsCorporelles':'_dActifC',
                            'actifC1ImmobiliseTerrains':'_dActifC1',
                            'actifC2ImmobiliseConstructions':'_dActifC2',
                            'actifC3ImmobiliseInstallationsTechniqueMaterielOutillage':'_dActifC3',
                            'actifC4ImmobiliseMaterielDeTransport':'_dActifC4',
                            'actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers':'_dActifC5',
                            'actifC6ImmobiliseAutresImmobilisationsCorporelles':'_dActifC6',
                            'actifC7ImmobiliseImmobilisationsCorporellesEnCours':'_dActifC7',
                            'actifDImmobiliseImmobilisationsFinancieres':'_dActifD',
                            'actifD1ImmobilisePretsImmobilises':'_dActifD1',
                            'actifD2ImmobiliseAutresCreancesFinancieres':'_dActifD2',
                            'actifD3ImmobiliseTitresDeParticipation':'_dActifD3',
                            'actifD4ImmobiliseAutresTitresImmobilises':'_dActifD4',
                            'actifEImmobiliseEcartsDeConversion':'_dActifE',
                            'actifE1ImmobiliseDiminutionDesCreancesImmobilisees':'_dActifE1',
                            'actifE2ImmobiliseAugmentationDesDettesDeFinancement':'_dActifE2',
                            'actifTotalIImmobiliseTotal':'_dActifTotal_I',
                            'actifFCirculantStocks':'_dActifF',
                            'actifF1CirculantMarchandises':'_dActifF1',
                            'actifF2CirculantMatieresEtFournituresConsommables':'_dActifF2',
                            'actifF3CirculantProduitsEnCours':'_dActifF3',
                            'actifF4CirculantProduitsIntermediairesEtProduitsResiduels':'_dActifF4',
                            'actifF5CirculantProduitsFinis':'_dActifF5',
                            'actifGCirculantCreancesCirculant':'_dActifG',
                            'actifG1CirculantFournisseursdebiteursAvancesAcomptes':'_dActifG1',
                            'actifG2CirculantClientsEtComptesRattaches':'_dActifG2',
                            'actifG3CirculantPersonnel':'_dActifG3',
                            'actifG4CirculantEtat':'_dActifG4',
                            'actifG5CirculantCompteAssocies':'_dActifG5',
                            'actifG6CirculantAutresDebiteurs':'_dActifG6',
                            'actifG7CirculantCompteRegularisation':'_dActifG7',
                            'actifHCirculantTitresEtValeursDePlacement':'_dActifH',                            
                            'actifICirculantEcartDeConversion':'_dActifI',
                            'actifTotalIICirculantTotal':'_dActifTotal_II',
                            'actifTresor1TresorerieChequesEtValeursAEncaisser':'_dActifTresor1',
                            'actifTresor2TresorerieBanquesTGEtCP':'_dActifTresor2',
                            'actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs':'_dActifTresor3',
                            'actifTresorTresorerieTotal':'_dActifTresor',
                            'actifTotalGeneral':'_dActifTotalGeneral'
                            },
                            passifMapping:{
                            'passifAFinancementPermanentCapitauxPropres':'_dPassifA',
                            'passifA2FinancementPermanentCapitalPersonnel':'_dPassifA2',
                            'passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse':'_dPassifA3',
                            'passifA6FinancementPermanentPrimeEmissionFusionApport':'_dPassifA6',
                            'passifA7FinancementPermanentEcartsDeReevaluation':'_dPassifA7',
                            'passifA8FinancementPermanentReserveLegale':'_dPassifA8',
                            'passifA9FinancementPermanentAutresReserves':'_dPassifA9',
                            'passifA10FinancementPermanentReportANouveau':'_dPassifA10',
                            'passifA11FinancementPermanentResultatNetsEnInstanceDAffectation':'_dPassifA11',
                            'passifA12FinancementPermanentResultatNetDeLexercice':'_dPassifA12',
                            'passifBFinancementPermanentCapitauxPropresAssimiles':'_dPassifB',
                            'passifB1FinancementPermanentSubventionInvestissement':'_dPassifB1',
                            'passifB2FinancementPermanentProvisionReglementees':'_dPassifB2',
                            'passifCFinancementPermanentDettesDeFinancement':'_dPassifC',
                            'passifC1FinancementPermanentEmpruntsObligataires':'_dPassifC1',
                            'passifC2FinancementPermanentAutresDettesDeFinancement':'_dPassifC2',
                            'passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges':'_dPassifD',
                            'passifD1FinancementPermanentProvisionsPourRisques':'_dPassifD1',
                            'passifD2FinancementPermanentProvisionsPourCharges':'_dPassifD2',
                            'passifEFinancementPermanentEcartsDeConversion':'_dPassifE',
                            'passifE1FinancementPermanentAugmentationDesCreancesImmobilisees':'_dPassifE1',
                            'passifE2FinancementPermanentDiminutionDesDettesDeFinancement':'_dPassifE2',
                            'passifTotalIFinancementPermanentTotal':'_dPassifTotal_I',
                            'passifFCirculantDettesDuCirculant':'_dPassifF',
                            'passifF1CirculantFournisseursEtComptesRattaches':'_dPassifF1',
                            'passifF2CirculantClientsCrediteursAvancesEtAcomptes':'_dPassifF2',
                            'passifF3CirculantPersonnel':'_dPassifF3',
                            'passifF4CirculantOrganismesSociaux':'_dPassifF4',
                            'passifF5CirculantEtat':'_dPassifF5',
                            'passifF6CirculantComptesAssocies':'_dPassifF6',
                            'passifF7CirculantAutresCreanciers':'_dPassifF7',
                            'passifF8CirculantComptesDeRegularisation':'_dPassifF8',
                            'passifGCirculantAutresProvisionsPourRisquesEtCharges':'_dPassifG',
                            'passifHCirculantEcartsDeConversion':'_dPassifH',
                            'passifTotalIICirculantTotal':'_dPassifTotal_II',
                            'passifTresorTresorerieTresoreriePassif':'_dPassifTresor',
                            'passifTresor1TresorerieCreditsEscompte':'_dPassifTresor1',
                            'passifTresor2TresorerieCreditDeTresorerie':'_dPassifTresor2',
                            'passifTresor3TresorerieBanques':'_dPassifTresor3',
                            'passifTresorTresorerieTotalIII':'_dPassifTresor',
                            'passifTotalGeneral':'_dPassifTotalGeneral'
                            },
                            cpcMapping:{
                            'cpcIExploitationProduitsExploitationExercice':'_dCPC_I',
                            'cpcI1ExploitationVentesDeMarchandisesExercice':'_dCPC_I_1',
                            'cpcI2ExploitationVentesBiensEtServicesProduitsExercice':'_dCPC_I_2',
                            'cpcI3ExploitationChiffresAffairesExercice':'_dCPC_I_3',
                            'cpcI4ExploitationVariationDeStockDeProduitsExercice':'_dCPC_I_4',
                            'cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice':'_dCPC_I_5',
                            'cpcI6ExploitationSubventionDExploitationExercice':'_dCPC_I_6',
                            'cpcI7ExploitationAutresProduitsExploitationExercice':'_dCPC_I_7',
                            'cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice':'_dCPC_I_8',
                            'cpcIIExploitationChargesExploitationExercice':'_dCPC_II',
                            'cpcII1ExploitationAchatsRevendusDeMarchandisesExercice':'_dCPC_II_1',
                            'cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice':'_dCPC_II_2',
                            'cpcII3ExploitationAutresChargesExternesExercice':'_dCPC_II_3',
                            'cpcII4ExploitationImpotsEtTaxesExercice':'_dCPC_II_4',
                            'cpcII5ExploitationChargesPersonnelExercice':'_dCPC_II_5',
                            'cpcII6ExploitationAutresChargesExploitationExercice':'_dCPC_II_6',
                            'cpcII7ExploitationDotationsExploitationExercice':'_dCPC_II_7',
                            'cpcIIIExploitationResultatExploitationExercice':'_dCPC_III',
                            'cpcIVFinancierProduitsFinanciersExercice':'_dCPC_IV',
                            'cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice':'_dCPC_IV_1',
                            'cpcIV2FinancierGainsDeChangeExercice':'_dCPC_IV_2',
                            'cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice':'_dCPC_IV_3',
                            'cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice':'_dCPC_IV_4',
                            'cpcVFinancierChargesFinancieresExercice':'_dCPC_V',
                            'cpcV1FinancierChargesInteretExercice':'_dCPC_V_1',
                            'cpcV2FinancierPertesDeChangesExercice':'_dCPC_V_2',
                            'cpcV3FinancierAutresChargesFinancieresExercice':'_dCPC_V_3',
                            'cpcV4FinancierDotationFinancieresExercice':'_dCPC_V_4',
                            'cpcVIFinancierResultatFinancierExercice':'_dCPC_VI',
                            'cpcVIICourantResultatCourantExercice':'_dCPC_VII',
                            'cpcVIIINonCourantProduitsNonCourantsExercice':'_dCPC_VIII',
                            'cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice':'_dCPC_VIII_1',
                            'cpcVIII2NonCourantSubventionsDEquilibreExercice':'_dCPC_VIII_2',
                            'cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice':'_dCPC_VIII_3',
                            'cpcVIII4NonCourantAutresProduitsNonCourantsExercice':'_dCPC_VIII_4',
                            'cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice':'_dCPC_VIII_5',
                            'cpcIXNonCourantChargesNonCourantesExercice':'_dCPC_IX',
                            'cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice':'_dCPC_IX_1',
                            'cpcIX2NonCourantSubventionsAccordeesExercice':'_dCPC_IX_2',
                            'cpcIX3NonCourantAutresChargesNonCourantesExercice':'_dCPC_IX_3',
                            'cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice':'_dCPC_IX_4',
                            'cpcXResultatNonCourantExercice':'_dCPC_X',
                            'cpcXIResultatAvantImpotsExercice':'_dCPC_XI',
                            'cpcXIIImpotsSurLesResultatsExercice':'_dCPC_XII',
                            'cpcXIIIResultatNetExercice':'_dCPC_XIII',

                            'cpcIExploitationProduitsExploitation':'_dCPC_I',
                            'cpcI1ExploitationVentesDeMarchandises':'_dCPC_I_1',
                            'cpcI2ExploitationVentesBiensEtServicesProduits':'_dCPC_I_2',
                            'cpcI3ExploitationChiffresAffaires':'_dCPC_I_3',
                            'cpcI4ExploitationVariationDeStockDeProduits':'_dCPC_I_4',
                            'cpcI5ExploitationImmobilisationsProduitesPourElleMeme':'_dCPC_I_5',
                            'cpcI6ExploitationSubventionDExploitation':'_dCPC_I_6',
                            'cpcI7ExploitationAutresProduitsExploitation':'_dCPC_I_7',
                            'cpcI8ExploitationReprisesExploitationTransfertDeCharges':'_dCPC_I_8',
                            'cpcIIExploitationChargesExploitation':'_dCPC_II',
                            'cpcII1ExploitationAchatsRevendusDeMarchandises':'_dCPC_II_1',
                            'cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures':'_dCPC_II_2',
                            'cpcII3ExploitationAutresChargesExternes':'_dCPC_II_3',
                            'cpcII4ExploitationImpotsEtTaxes':'_dCPC_II_4',
                            'cpcII5ExploitationChargesPersonnel':'_dCPC_II_5',
                            'cpcII6ExploitationAutresChargesExploitation':'_dCPC_II_6',
                            'cpcII7ExploitationDotationsExploitation':'_dCPC_II_7',
                            'cpcIIIExploitationResultatExploitation':'_dCPC_III',
                            'cpcIVFinancierProduitsFinanciers':'_dCPC_IV',
                            'cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises':'_dCPC_IV_1',
                            'cpcIV2FinancierGainsDeChange':'_dCPC_IV_2',
                            'cpcIV3FinancierInteretsEtAutresProduitsFinanciers':'_dCPC_IV_3',
                            'cpcIV4FinancierReprisesFinancieresTransfertDeCharges':'_dCPC_IV_4',
                            'cpcVFinancierChargesFinancieres':'_dCPC_V',
                            'cpcV1FinancierChargesInteret':'_dCPC_V_1',
                            'cpcV2FinancierPertesDeChanges':'_dCPC_V_2',
                            'cpcV3FinancierAutresChargesFinancieres':'_dCPC_V_3',
                            'cpcV4FinancierDotationFinancieres':'_dCPC_V_4',
                            'cpcVIFinancierResultatFinancier':'_dCPC_VI',
                            'cpcVIICourantResultatCourant':'_dCPC_VII',
                            'cpcVIIINonCourantProduitsNonCourants':'_dCPC_VIII',
                            'cpcVIII1NonCourantProduitsDesCessionsDImmobilisations':'_dCPC_VIII_1',
                            'cpcVIII2NonCourantSubventionsDEquilibre':'_dCPC_VIII_2',
                            'cpcVIII3NonCourantReprisesSurSubventionsDInvestissement':'_dCPC_VIII_3',
                            'cpcVIII4NonCourantAutresProduitsNonCourants':'_dCPC_VIII_4',
                            'cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges':'_dCPC_VIII_5',
                            'cpcIXNonCourantChargesNonCourantes':'_dCPC_IX',
                            'cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees':'_dCPC_IX_1',
                            'cpcIX2NonCourantSubventionsAccordees':'_dCPC_IX_2',
                            'cpcIX3NonCourantAutresChargesNonCourantes':'_dCPC_IX_3',
                            'cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision':'_dCPC_IX_4',
                            'cpcXResultatNonCourant':'_dCPC_X',
                            'cpcXIResultatAvantImpots':'_dCPC_XI',
                            'cpcXIIImpotsSurLesResultats':'_dCPC_XII',
                            'cpcXIIIResultatNet':'_dCPC_XIII',
                            },
        }
    }, //end data()
    //------------------------------ created ---------------------------
    created()
    {
    },
    //------------------------------ Mounted ---------------------------
    async mounted() {
        this.jsonResult = null;
        this.selectedBilanItemLocal = {}
        this.selectedBilanItemLocal = {...this.selectedBilanItemProp}
        this.showBilanDialogFlagLocal = this.showBilanDialogFlag
        this.inforiskLocal = this.inforisk
        this.blockedDialog = true;

        this.ompicLocal = this.ompic;
        var ref = this;

        if(this.ompic && !this.disabledButtons)
        {
            ref.years = [];
            CRUDService.list("AnneesOmpicDisponibles/"+this.societe.id)
                .then(function(response){
                    if(response.data)
                    {
                        ref.years = response.data
                    }
                })
        }

        // 
        if(this.selectedBilanItemLocal.id==0)
            {
                this.selectedBilanItemLocal.dateBilan = ''
            }
    
        if(ref.showBilanDialogFlag && !this.isMounted)
            {    
            
            if(!this.ompic)
            {
                ref.years = [];
                CRUDService.list("anneesbilandisponibles/"+this.societe.id)
                    .then(function(response){
                        if(response.data)
                        {
                            ref.blockedDialog = false;
                            
                            ref.years = response.data.map(function(d){
                                d['value'] = d.annee;
                                if(d['disabled'])
                                {
                                    var strDisabledInforisk = d['inforisk']?'inforisk':'manuel'
                                    d['annee'] += ' ('+strDisabledInforisk+')'
                                }
                                return d;
                            })

                            /*if(!ref.selectedBilanItemLocal.id)
                            {
                                ref.selectedBilanItemLocal.dateBilan = ref.years[0].annee
                            }*/
                        }
                    })
            }
                
            if(this.selectedBilanItemLocal.id > 0 && this.selectedBilanItemLocal.dateBilan)
            {
                var anneeN1 = parseInt(this.selectedBilanItemLocal.dateBilan)-1
                ref.blockedDialog = true;
                crudService.list("bilanfinancierbyannee/"+anneeN1+"/"+this.societe.id)
                .then(function(response){
                    if(response.data)
                    {
                        ref.blockedDialog = false;
                        
                        ref.selectedBilanItemLocalAnneeN1 = response.data;
                        ref.foundBilanN1 = true
                        ref.toastSuccess("Bilan N-1 chargé (" + anneeN1 +') depuis '+ (ref.selectedBilanItemLocalAnneeN1.inforisk?"inforisk":"manuel"));
                    }
                    else
                    {
                        ref.foundBilanN1 = false
                    }
                })
            }

        }
        // ;

        // let props = {...this.selectedBilanItemProp}
        // for(var o in props)
        // {
        //     if(o!='dateBilan')
        //     {
        //         this.selectedBilanItemLocal[o[0].toUpperCase() + o.substring(1)] = this.selectedBilanItemProp[o]
        //     }
            
        // }
        // ;
        var ref = this;
        var response = await CRUDService.list('BilanListeChamps')
        if(response.data)
            { 
                ref.refMappingBilanListeChamps = response.data
            }
        else
            {
                alert(response.data)
            }

        this.isMounted = true;
        
    }, //end mounted
    computed:{
        titleHeader()
            {
                //selectedBilanItemLocal.id)?('Edition du bilan '+selectedBilanItemLocal.dateBilan+' de '+societe.raisonSociale):'Création d\'un nouveau bilan: '+societe.raisonSociale + ' - ' + selectedBilanItemLocal.dateBilan
            if( this.selectedBilanItemLocal.id){
                if(this.selectedBilanItemLocal.inforisk == 1)
                {
                    return ('Consultation du bilan '+this.selectedBilanItemLocal.dateBilan+' de '+this.societe.raisonSociale)
                }
                return ('Edition du bilan '+this.selectedBilanItemLocal.dateBilan+' de '+this.societe.raisonSociale)
            }
            else{
                return 'Création d\'un nouveau bilan: '+this.societe.raisonSociale + ' - ' + this.selectedBilanItemLocal.dateBilan
            }
        },
        /// disabled rules for actif/passif/cpc
        bilanFieldsEnabled()
            {
                // Le bilan ne doit pas etre ompic ni etre inforisk et doit l'utilisateur avoir les droits
                // debugger;
                return this.selectedBilanItemLocal.ompic!=1 && ((!this.inforiskLocal && !this.selectedBilanItemLocal.inforisk) || this.selectedBilanItemLocal.inforisk == 2) && this.currentUser.jsonUserRights.gestionBilan;
            }   ,
        bilanFieldsN1Enabled()
            {
                // en mode creation
            if( !this.selectedBilanItemLocalAnneeN1.id && this.bilanFieldsEnabled) {
                return true;
            }
        
            return this.selectedBilanItemLocalAnneeN1.estAnneePrecedente && this.selectedBilanItemLocalAnneeN1.inforisk!=1 && this.selectedBilanItemLocalAnneeN1.ompic!=1 ;
        },
        // BilanNHasValues
        bilanNHasValues()
            {
                return this.selectedBilanItemLocal.actifTotalGeneral != 0 || this.selectedBilanItemLocal.passifTotalGeneral != 0 || this.selectedBilanItemLocal.cpcXVIResultatNET != 0;
        }   ,
        // BilanN1HasValues
        bilanN1HasValues()
            {
                return (this.selectedBilanItemLocalAnneeN1.actifTotalGeneral && this.selectedBilanItemLocalAnneeN1.actifTotalGeneral > 0) || (this.selectedBilanItemLocalAnneeN1.passifTotalGeneral && this.selectedBilanItemLocalAnneeN1.passifTotalGeneral > 0) || (this.selectedBilanItemLocalAnneeN1.cpcXVIResultatNET && this.selectedBilanItemLocalAnneeN1.cpcXVIResultatNET > 0);
        }   ,
        /// computed for actif

        actifA1ImmobiliseFraisPreliminaires(){
            return this.getComputedValue('actifA1ImmobiliseFraisPreliminairesBrut') - this.getComputedValue('actifA1ImmobiliseFraisPreliminairesAmrtProv');
        },
        actifA2ImmobiliseChargesArepartirSurplusieursExercices(){
            return this.getComputedValue('actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut') - this.getComputedValue('actifA2ImmobiliseChargesArepartirSurplusieursExercicesAmrtProv');
        },
        actifA3ImmobilisePrimesDeRemboursementDesObligations(){
            return this.getComputedValue('actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut') - this.getComputedValue('actifA3ImmobilisePrimesDeRemboursementDesObligationsAmrtProv');
        },
        actifBImmobiliseImmobilisationsIncorporelles(){
            return this.getComputedValue('actifBImmobiliseImmobilisationsIncorporellesBrut') - this.getComputedValue('actifBImmobiliseImmobilisationsIncorporellesAmrtProv');
        },
        actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement(){
            return this.getComputedValue('actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut') - this.getComputedValue('actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAmrtProv');
        },
        actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires(){
            return this.getComputedValue('actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut') - this.getComputedValue('actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAmrtProv');
        },
        actifAImmobiliseImmobilisationEnNonValeur(){
            return this.getComputedValue('actifAImmobiliseImmobilisationEnNonValeurBrut') - this.getComputedValue('actifAImmobiliseImmobilisationEnNonValeurAmrtProv');
        },
        actifB3ImmobiliseFondsCommercial(){
            return this.getComputedValue('actifB3ImmobiliseFondsCommercialBrut') - this.getComputedValue('actifB3ImmobiliseFondsCommercialAmrtProv');
        },
        actifB4ImmobiliseAutresImmobilisationsIncorporelles(){
            return this.getComputedValue('actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut') - this.getComputedValue('actifB4ImmobiliseAutresImmobilisationsIncorporellesAmrtProv');
        },
        actifCImmobiliseImmobilisationsCorporelles(){
            return this.getComputedValue('actifCImmobiliseImmobilisationsCorporellesBrut') - this.getComputedValue('actifCImmobiliseImmobilisationsCorporellesAmrtProv');
        },
        actifC1ImmobiliseTerrains(){
            return this.getComputedValue('actifC1ImmobiliseTerrainsBrut') - this.getComputedValue('actifC1ImmobiliseTerrainsAmrtProv');
        },
        actifC2ImmobiliseConstructions(){
            return this.getComputedValue('actifC2ImmobiliseConstructionsBrut') - this.getComputedValue('actifC2ImmobiliseConstructionsAmrtProv');
        },
        actifC3ImmobiliseInstallationsTechniqueMaterielOutillage(){
            return this.getComputedValue('actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut') - this.getComputedValue('actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAmrtProv');
        },
        actifC4ImmobiliseMaterielDeTransport(){
            return this.getComputedValue('actifC4ImmobiliseMaterielDeTransportBrut') - this.getComputedValue('actifC4ImmobiliseMaterielDeTransportAmrtProv');
        },
        actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers(){
            return this.getComputedValue('actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut') - this.getComputedValue('actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAmrtProv');
        },
        actifC6ImmobiliseAutresImmobilisationsCorporelles(){
            return this.getComputedValue('actifC6ImmobiliseAutresImmobilisationsCorporellesBrut') - this.getComputedValue('actifC6ImmobiliseAutresImmobilisationsCorporellesAmrtProv');
        },
        actifC7ImmobiliseImmobilisationsCorporellesEnCours(){
            return this.getComputedValue('actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut') - this.getComputedValue('actifC7ImmobiliseImmobilisationsCorporellesEnCoursAmrtProv');
        },
        actifDImmobiliseImmobilisationsFinancieres(){
            return this.getComputedValue('actifDImmobiliseImmobilisationsFinancieresBrut') - this.getComputedValue('actifDImmobiliseImmobilisationsFinancieresAmrtProv');
        },
        actifD1ImmobilisePretsImmobilises(){
            return this.getComputedValue('actifD1ImmobilisePretsImmobilisesBrut') - this.getComputedValue('actifD1ImmobilisePretsImmobilisesAmrtProv');
        },
        actifD2ImmobiliseAutresCreancesFinancieres(){
            return this.getComputedValue('actifD2ImmobiliseAutresCreancesFinancieresBrut') - this.getComputedValue('actifD2ImmobiliseAutresCreancesFinancieresAmrtProv');
        },
        actifD3ImmobiliseTitresDeParticipation(){
            return this.getComputedValue('actifD3ImmobiliseTitresDeParticipationBrut') - this.getComputedValue('actifD3ImmobiliseTitresDeParticipationAmrtProv');
        },
        actifD4ImmobiliseAutresTitresImmobilises(){
            return this.getComputedValue('actifD4ImmobiliseAutresTitresImmobilisesBrut') - this.getComputedValue('actifD4ImmobiliseAutresTitresImmobilisesAmrtProv');
        },
        actifEImmobiliseEcartsDeConversion(){
            return this.getComputedValue('actifEImmobiliseEcartsDeConversionBrut') - this.getComputedValue('actifEImmobiliseEcartsDeConversionAmrtProv');
        },
        actifE1ImmobiliseDiminutionDesCreancesImmobilisees(){
            return this.getComputedValue('actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut') - this.getComputedValue('actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAmrtProv');
        },
        actifE2ImmobiliseAugmentationDesDettesDeFinancement(){
            return this.getComputedValue('actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut') - this.getComputedValue('actifE2ImmobiliseAugmentationDesDettesDeFinancementAmrtProv');
        },
        actifTotalIImmobiliseTotal(){
            return this.getComputedValue('actifTotalIImmobiliseTotalBrut') - this.getComputedValue('actifTotalIImmobiliseTotalAmrtProv');
        },
        actifFCirculantStocks(){
            return this.getComputedValue('actifFCirculantStocksBrut') - this.getComputedValue('actifFCirculantStocksAmrtProv');
        },
        actifF1CirculantMarchandises(){
            return this.getComputedValue('actifF1CirculantMarchandisesBrut') - this.getComputedValue('actifF1CirculantMarchandisesAmrtProv');
        },
        actifF2CirculantMatieresEtFournituresConsommables(){
            return this.getComputedValue('actifF2CirculantMatieresEtFournituresConsommablesBrut') - this.getComputedValue('actifF2CirculantMatieresEtFournituresConsommablesAmrtProv');
        },
        actifF3CirculantProduitsEnCours(){
            return this.getComputedValue('actifF3CirculantProduitsEnCoursBrut') - this.getComputedValue('actifF3CirculantProduitsEnCoursAmrtProv');
        },
        actifF4CirculantProduitsIntermediairesEtProduitsResiduels(){
            return this.getComputedValue('actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut') - this.getComputedValue('actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAmrtProv');
        },
        actifF5CirculantProduitsFinis(){
            return this.getComputedValue('actifF5CirculantProduitsFinisBrut') - this.getComputedValue('actifF5CirculantProduitsFinisAmrtProv');
        },
        actifGCirculantCreancesCirculant(){
            return this.getComputedValue('actifGCirculantCreancesCirculantBrut') - this.getComputedValue('actifGCirculantCreancesCirculantAmrtProv');
        },
        actifG1CirculantFournisseursdebiteursAvancesAcomptes(){
            return this.getComputedValue('actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut') - this.getComputedValue('actifG1CirculantFournisseursdebiteursAvancesAcomptesAmrtProv');
        },
        actifG2CirculantClientsEtComptesRattaches(){
            return this.getComputedValue('actifG2CirculantClientsEtComptesRattachesBrut') - this.getComputedValue('actifG2CirculantClientsEtComptesRattachesAmrtProv');
        },
        actifG3CirculantPersonnel(){
            return this.getComputedValue('actifG3CirculantPersonnelBrut') - this.getComputedValue('actifG3CirculantPersonnelAmrtProv');
        },
        actifG4CirculantEtat(){
            return this.getComputedValue('actifG4CirculantEtatBrut') - this.getComputedValue('actifG4CirculantEtatAmrtProv');
        },
        actifG5CirculantCompteAssocies(){
            return this.getComputedValue('actifG5CirculantCompteAssociesBrut') - this.getComputedValue('actifG5CirculantCompteAssociesAmrtProv');
        },
        actifG6CirculantAutresDebiteurs(){
            return this.getComputedValue('actifG6CirculantAutresDebiteursBrut') - this.getComputedValue('actifG6CirculantAutresDebiteursAmrtProv');
        },
        actifG7CirculantCompteRegularisation(){
            return this.getComputedValue('actifG7CirculantCompteRegularisationBrut') - this.getComputedValue('actifG7CirculantCompteRegularisationAmrtProv');
        },
        actifHCirculantTitresEtValeursDePlacement(){
            return this.getComputedValue('actifHCirculantTitresEtValeursDePlacementBrut') - this.getComputedValue('actifHCirculantTitresEtValeursDePlacementAmrtProv');
        },
        actifICirculantEcartDeConversion(){
            return this.getComputedValue('actifICirculantEcartDeConversionBrut') - this.getComputedValue('actifICirculantEcartDeConversionAmrtProv');
        },
        actifTotalIICirculantTotal(){
            return this.getComputedValue('actifTotalIICirculantTotalBrut') - this.getComputedValue('actifTotalIICirculantTotalAmrtProv');
        },
        actifTresor1TresorerieChequesEtValeursAEncaisser(){
            return this.getComputedValue('actifTresor1TresorerieChequesEtValeursAEncaisserBrut') - this.getComputedValue('actifTresor1TresorerieChequesEtValeursAEncaisserAmrtProv');
        },
        actifTresor2TresorerieBanquesTGEtCP(){
            return this.getComputedValue('actifTresor2TresorerieBanquesTGEtCPBrut') - this.getComputedValue('actifTresor2TresorerieBanquesTGEtCPAmrtProv');
        },
        actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs(){
            return this.getComputedValue('actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut') - this.getComputedValue('actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAmrtProv');
        },
        actifTresorTresorerieTotal(){
            return this.getComputedValue('actifTresorTresorerieTotalBrut') - this.getComputedValue('actifTresorTresorerieTotalAmrtProv');
        },
        actifTotalGeneral(){
            return this.getComputedValue('actifTotalGeneralBrut') - this.getComputedValue('actifTotalGeneralAmrtProv');
        },


        /// Brut

        actifAImmobiliseImmobilisationEnNonValeurBrut()
        {
            return this.getComputedValue('actifA1ImmobiliseFraisPreliminairesBrut') + this.getComputedValue('actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut') + this.getComputedValue('actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut')
        },
        
        actifBImmobiliseImmobilisationsIncorporellesBrut(){
            return this.getComputedValue('actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut') + this.getComputedValue('actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut') + this.getComputedValue('actifB3ImmobiliseFondsCommercialBrut') + this.getComputedValue('actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut')
        },
        actifCImmobiliseImmobilisationsCorporellesBrut(){
            return this.getComputedValue('actifC1ImmobiliseTerrainsBrut') + this.getComputedValue('actifC2ImmobiliseConstructionsBrut') + this.getComputedValue('actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut') + this.getComputedValue('actifC4ImmobiliseMaterielDeTransportBrut') + this.getComputedValue('actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut') + this.getComputedValue('actifC6ImmobiliseAutresImmobilisationsCorporellesBrut') + this.getComputedValue('actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut')
        },
        actifDImmobiliseImmobilisationsFinancieresBrut(){
            return this.getComputedValue('actifD1ImmobilisePretsImmobilisesBrut') + this.getComputedValue('actifD2ImmobiliseAutresCreancesFinancieresBrut') + this.getComputedValue('actifD3ImmobiliseTitresDeParticipationBrut') + this.getComputedValue('actifD4ImmobiliseAutresTitresImmobilisesBrut')
        },
        actifEImmobiliseEcartsDeConversionBrut(){
            return this.getComputedValue('actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut') + this.getComputedValue('actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut')
        },
        actifTotalIImmobiliseTotalBrut(){
            return this.getComputedValue('actifAImmobiliseImmobilisationEnNonValeurBrut') + this.getComputedValue('actifBImmobiliseImmobilisationsIncorporellesBrut') + this.getComputedValue('actifCImmobiliseImmobilisationsCorporellesBrut') + this.getComputedValue('actifDImmobiliseImmobilisationsFinancieresBrut') + this.getComputedValue('actifEImmobiliseEcartsDeConversionBrut')
        },
        actifFCirculantStocksBrut(){
            return this.getComputedValue('actifF1CirculantMarchandisesBrut') + this.getComputedValue('actifF2CirculantMatieresEtFournituresConsommablesBrut') + this.getComputedValue('actifF3CirculantProduitsEnCoursBrut') + this.getComputedValue('actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut') + this.getComputedValue('actifF5CirculantProduitsFinisBrut')
        },
        actifGCirculantCreancesCirculantBrut(){
            return this.getComputedValue('actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut') + this.getComputedValue('actifG2CirculantClientsEtComptesRattachesBrut') + this.getComputedValue('actifG3CirculantPersonnelBrut') + this.getComputedValue('actifG4CirculantEtatBrut') + this.getComputedValue('actifG5CirculantCompteAssociesBrut') + this.getComputedValue('actifG6CirculantAutresDebiteursBrut') + this.getComputedValue('actifG7CirculantCompteRegularisationBrut')
        },
        actifTotalIICirculantTotalBrut(){
            return this.getComputedValue('actifFCirculantStocksBrut') + this.getComputedValue('actifGCirculantCreancesCirculantBrut') + this.getComputedValue('actifHCirculantTitresEtValeursDePlacementBrut') + this.getComputedValue('actifICirculantEcartDeConversionBrut')
        },
        actifTresorTresorerieTotalBrut(){
            return this.getComputedValue('actifTresor1TresorerieChequesEtValeursAEncaisserBrut') + this.getComputedValue('actifTresor2TresorerieBanquesTGEtCPBrut') + this.getComputedValue('actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut')
        },
        
        actifTotalGeneralBrut(){
            return this.getComputedValue('actifTotalIImmobiliseTotalBrut') + this.getComputedValue('actifTotalIICirculantTotalBrut') + this.getComputedValue('actifTresorTresorerieTotalBrut')
        },

        actifAImmobiliseImmobilisationEnNonValeurCalcul()
        {
            return this.selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminaires + this.selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercices + this.selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligations
        },
        
        actifBImmobiliseImmobilisationsIncorporellesCalcul(){
            return this.selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement + this.selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires + this.selectedBilanItemLocal.actifB3ImmobiliseFondsCommercial + this.selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporelles
        },
        actifCImmobiliseImmobilisationsCorporellesCalcul(){
            return this.selectedBilanItemLocal.actifC1ImmobiliseTerrains + this.selectedBilanItemLocal.actifC2ImmobiliseConstructions + this.selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillage + this.selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransport + this.selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers + this.selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporelles + this.selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCours
        },
        actifDImmobiliseImmobilisationsFinancieresCalcul(){
            return this.selectedBilanItemLocal.actifD1ImmobilisePretsImmobilises + this.selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieres + this.selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipation + this.selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilises
        },
        actifEImmobiliseEcartsDeConversionCalcul(){
            return this.selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobilisees + this.selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancement
        },
        actifTotalIImmobiliseTotalCalcul(){
            return this.selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeur + this.selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporelles + this.selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporelles + this.selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieres + this.selectedBilanItemLocal.actifEImmobiliseEcartsDeConversion
        },
        actifFCirculantStocksCalcul(){
            return this.selectedBilanItemLocal.actifF1CirculantMarchandises + this.selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommables + this.selectedBilanItemLocal.actifF3CirculantProduitsEnCours + this.selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduels + this.selectedBilanItemLocal.actifF5CirculantProduitsFinis
        },
        actifGCirculantCreancesCirculantCalcul(){
            return this.selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptes + this.selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattaches + this.selectedBilanItemLocal.actifG3CirculantPersonnel + this.selectedBilanItemLocal.actifG4CirculantEtat + this.selectedBilanItemLocal.actifG5CirculantCompteAssocies + this.selectedBilanItemLocal.actifG6CirculantAutresDebiteurs + this.selectedBilanItemLocal.actifG7CirculantCompteRegularisation
        },
        actifTotalIICirculantTotalCalcul(){
            return this.selectedBilanItemLocal.actifFCirculantStocks + this.selectedBilanItemLocal.actifGCirculantCreancesCirculant + this.selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacement + this.selectedBilanItemLocal.actifICirculantEcartDeConversion
        },
        actifTresorTresorerieTotalCalcul(){
            return this.selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisser + this.selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCP + this.selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs
        },
        
        actifTotalGeneralCalcul(){
            return this.selectedBilanItemLocal.actifTotalIImmobiliseTotal + this.selectedBilanItemLocal.actifTotalIICirculantTotal + this.selectedBilanItemLocal.actifTresorTresorerieTotal
        },

        actifAImmobiliseImmobilisationEnNonValeurAnneeN1Calcul()
        {
            return this.selectedBilanItemLocalAnneeN1.actifA1ImmobiliseFraisPreliminaires + this.selectedBilanItemLocalAnneeN1.actifA2ImmobiliseChargesArepartirSurplusieursExercices + this.selectedBilanItemLocalAnneeN1.actifA3ImmobilisePrimesDeRemboursementDesObligations
        },
        
        actifBImmobiliseImmobilisationsIncorporellesAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement + this.selectedBilanItemLocalAnneeN1.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires + this.selectedBilanItemLocalAnneeN1.actifB3ImmobiliseFondsCommercial + this.selectedBilanItemLocalAnneeN1.actifB4ImmobiliseAutresImmobilisationsIncorporelles
        },
        actifCImmobiliseImmobilisationsCorporellesAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifC1ImmobiliseTerrains + this.selectedBilanItemLocalAnneeN1.actifC2ImmobiliseConstructions + this.selectedBilanItemLocalAnneeN1.actifC3ImmobiliseInstallationsTechniqueMaterielOutillage + this.selectedBilanItemLocalAnneeN1.actifC4ImmobiliseMaterielDeTransport + this.selectedBilanItemLocalAnneeN1.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers + this.selectedBilanItemLocalAnneeN1.actifC6ImmobiliseAutresImmobilisationsCorporelles + this.selectedBilanItemLocalAnneeN1.actifC7ImmobiliseImmobilisationsCorporellesEnCours
        },
        actifDImmobiliseImmobilisationsFinancieresAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifD1ImmobilisePretsImmobilises + this.selectedBilanItemLocalAnneeN1.actifD2ImmobiliseAutresCreancesFinancieres + this.selectedBilanItemLocalAnneeN1.actifD3ImmobiliseTitresDeParticipation + this.selectedBilanItemLocalAnneeN1.actifD4ImmobiliseAutresTitresImmobilises
        },
        actifEImmobiliseEcartsDeConversionAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifE1ImmobiliseDiminutionDesCreancesImmobilisees + this.selectedBilanItemLocalAnneeN1.actifE2ImmobiliseAugmentationDesDettesDeFinancement
        },
        actifTotalIImmobiliseTotalAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifAImmobiliseImmobilisationEnNonValeur + this.selectedBilanItemLocalAnneeN1.actifBImmobiliseImmobilisationsIncorporelles + this.selectedBilanItemLocalAnneeN1.actifCImmobiliseImmobilisationsCorporelles + this.selectedBilanItemLocalAnneeN1.actifDImmobiliseImmobilisationsFinancieres + this.selectedBilanItemLocalAnneeN1.actifEImmobiliseEcartsDeConversion
        },
        actifFCirculantStocksAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifF1CirculantMarchandises + this.selectedBilanItemLocalAnneeN1.actifF2CirculantMatieresEtFournituresConsommables + this.selectedBilanItemLocalAnneeN1.actifF3CirculantProduitsEnCours + this.selectedBilanItemLocalAnneeN1.actifF4CirculantProduitsIntermediairesEtProduitsResiduels + this.selectedBilanItemLocalAnneeN1.actifF5CirculantProduitsFinis
        },
        actifGCirculantCreancesCirculantAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifG1CirculantFournisseursdebiteursAvancesAcomptes + this.selectedBilanItemLocalAnneeN1.actifG2CirculantClientsEtComptesRattaches + this.selectedBilanItemLocalAnneeN1.actifG3CirculantPersonnel + this.selectedBilanItemLocalAnneeN1.actifG4CirculantEtat + this.selectedBilanItemLocalAnneeN1.actifG5CirculantCompteAssocies + this.selectedBilanItemLocalAnneeN1.actifG6CirculantAutresDebiteurs + this.selectedBilanItemLocalAnneeN1.actifG7CirculantCompteRegularisation
        },
        actifTotalIICirculantTotalAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifFCirculantStocks + this.selectedBilanItemLocalAnneeN1.actifGCirculantCreancesCirculant + this.selectedBilanItemLocalAnneeN1.actifHCirculantTitresEtValeursDePlacement + this.selectedBilanItemLocalAnneeN1.actifICirculantEcartDeConversion
        },
        actifTresorTresorerieTotalAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifTresor1TresorerieChequesEtValeursAEncaisser + this.selectedBilanItemLocalAnneeN1.actifTresor2TresorerieBanquesTGEtCP + this.selectedBilanItemLocalAnneeN1.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs
        },
        
        actifTotalGeneralAnneeN1Calcul(){
            return this.selectedBilanItemLocalAnneeN1.actifTotalIImmobiliseTotal + this.selectedBilanItemLocalAnneeN1.actifTotalIICirculantTotal + this.selectedBilanItemLocalAnneeN1.actifTresorTresorerieTotal
        },

        actifAImmobiliseImmobilisationEnNonValeurAmrtProv()
        {
            return this.getComputedValue('actifA1ImmobiliseFraisPreliminairesAmrtProv') + this.getComputedValue('actifA2ImmobiliseChargesArepartirSurplusieursExercicesAmrtProv') + this.getComputedValue('actifA3ImmobilisePrimesDeRemboursementDesObligationsAmrtProv')
        },
        
        actifBImmobiliseImmobilisationsIncorporellesAmrtProv(){
            return this.getComputedValue('actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAmrtProv') + this.getComputedValue('actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAmrtProv') + this.getComputedValue('actifB3ImmobiliseFondsCommercialAmrtProv') + this.getComputedValue('actifB4ImmobiliseAutresImmobilisationsIncorporellesAmrtProv')
        },
        actifCImmobiliseImmobilisationsCorporellesAmrtProv(){
            return this.getComputedValue('actifC1ImmobiliseTerrainsAmrtProv') + this.getComputedValue('actifC2ImmobiliseConstructionsAmrtProv') + this.getComputedValue('actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAmrtProv') + this.getComputedValue('actifC4ImmobiliseMaterielDeTransportAmrtProv') + this.getComputedValue('actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAmrtProv') + this.getComputedValue('actifC6ImmobiliseAutresImmobilisationsCorporellesAmrtProv') + this.getComputedValue('actifC7ImmobiliseImmobilisationsCorporellesEnCoursAmrtProv')
        },
        actifDImmobiliseImmobilisationsFinancieresAmrtProv(){
            return this.getComputedValue('actifD1ImmobilisePretsImmobilisesAmrtProv') + this.getComputedValue('actifD2ImmobiliseAutresCreancesFinancieresAmrtProv') + this.getComputedValue('actifD3ImmobiliseTitresDeParticipationAmrtProv') + this.getComputedValue('actifD4ImmobiliseAutresTitresImmobilisesAmrtProv')
        },
        actifEImmobiliseEcartsDeConversionAmrtProv(){
            return this.getComputedValue('actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAmrtProv') + this.getComputedValue('actifE2ImmobiliseAugmentationDesDettesDeFinancementAmrtProv')
        },
        actifTotalIImmobiliseTotalAmrtProv(){
            return this.getComputedValue('actifAImmobiliseImmobilisationEnNonValeurAmrtProv') + this.getComputedValue('actifBImmobiliseImmobilisationsIncorporellesAmrtProv') + this.getComputedValue('actifCImmobiliseImmobilisationsCorporellesAmrtProv') + this.getComputedValue('actifDImmobiliseImmobilisationsFinancieresAmrtProv') + this.getComputedValue('actifEImmobiliseEcartsDeConversionAmrtProv')
        },
        actifFCirculantStocksAmrtProv(){
            return this.getComputedValue('actifF1CirculantMarchandisesAmrtProv') + this.getComputedValue('actifF2CirculantMatieresEtFournituresConsommablesAmrtProv') + this.getComputedValue('actifF3CirculantProduitsEnCoursAmrtProv') + this.getComputedValue('actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAmrtProv') + this.getComputedValue('actifF5CirculantProduitsFinisAmrtProv')
        },
        actifGCirculantCreancesCirculantAmrtProv(){
            return this.getComputedValue('actifG1CirculantFournisseursdebiteursAvancesAcomptesAmrtProv') + this.getComputedValue('actifG2CirculantClientsEtComptesRattachesAmrtProv') + this.getComputedValue('actifG3CirculantPersonnelAmrtProv') + this.getComputedValue('actifG4CirculantEtatAmrtProv') + this.getComputedValue('actifG5CirculantCompteAssociesAmrtProv') + this.getComputedValue('actifG6CirculantAutresDebiteursAmrtProv') + this.getComputedValue('actifG7CirculantCompteRegularisationAmrtProv')
        },
        actifTotalIICirculantTotalAmrtProv(){
            return this.getComputedValue('actifFCirculantStocksAmrtProv') + this.getComputedValue('actifGCirculantCreancesCirculantAmrtProv') + this.getComputedValue('actifHCirculantTitresEtValeursDePlacementAmrtProv') + this.getComputedValue('actifICirculantEcartDeConversionAmrtProv')
        },
        actifTresorTresorerieTotalAmrtProv(){
            return this.getComputedValue('actifTresor1TresorerieChequesEtValeursAEncaisserAmrtProv') + this.getComputedValue('actifTresor2TresorerieBanquesTGEtCPAmrtProv') + this.getComputedValue('actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAmrtProv')
        },
        
        actifTotalGeneralAmrtProv(){
            return this.getComputedValue('actifTotalIImmobiliseTotalAmrtProv') + this.getComputedValue('actifTotalIICirculantTotalAmrtProv') + this.getComputedValue('actifTresorTresorerieTotalAmrtProv')
        },
        
        actifAImmobiliseImmobilisationEnNonValeurAnneeN1()
        {
            return this.getComputedValue('actifA1ImmobiliseFraisPreliminairesAnneeN1') + this.getComputedValue('actifA2ImmobiliseChargesArepartirSurplusieursExercicesAnneeN1') + this.getComputedValue('actifA3ImmobilisePrimesDeRemboursementDesObligationsAnneeN1')
        },
        
        actifBImmobiliseImmobilisationsIncorporellesAnneeN1(){
            return this.getComputedValue('actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAnneeN1') + this.getComputedValue('actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAnneeN1') + this.getComputedValue('actifB3ImmobiliseFondsCommercialAnneeN1') + this.getComputedValue('actifB4ImmobiliseAutresImmobilisationsIncorporellesAnneeN1')
        },
        actifCImmobiliseImmobilisationsCorporellesAnneeN1(){
            return this.getComputedValue('actifC1ImmobiliseTerrainsAnneeN1') + this.getComputedValue('actifC2ImmobiliseConstructionsAnneeN1') + this.getComputedValue('actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAnneeN1') + this.getComputedValue('actifC4ImmobiliseMaterielDeTransportAnneeN1') + this.getComputedValue('actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAnneeN1') + this.getComputedValue('actifC6ImmobiliseAutresImmobilisationsCorporellesAnneeN1') + this.getComputedValue('actifC7ImmobiliseImmobilisationsCorporellesEnCoursAnneeN1')
        },
        actifDImmobiliseImmobilisationsFinancieresAnneeN1(){
            return this.getComputedValue('actifD1ImmobilisePretsImmobilisesAnneeN1') + this.getComputedValue('actifD2ImmobiliseAutresCreancesFinancieresAnneeN1') + this.getComputedValue('actifD3ImmobiliseTitresDeParticipationAnneeN1') + this.getComputedValue('actifD4ImmobiliseAutresTitresImmobilisesAnneeN1')
        },
        actifEImmobiliseEcartsDeConversionAnneeN1(){
            return this.getComputedValue('actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAnneeN1') + this.getComputedValue('actifE2ImmobiliseAugmentationDesDettesDeFinancementAnneeN1')
        },
        actifHCirculantTitresEtValeursDePlacementAnneeN1(){
            return this.getComputedValue('actifHCirculantTitresEtValeursDePlacementBrutAnneeN1') - this.getComputedValue('actifHCirculantTitresEtValeursDePlacementAmrtProvAnneeN1');
        },
        actifTotalIImmobiliseTotalAnneeN1(){
            return this.getComputedValue('actifAImmobiliseImmobilisationEnNonValeurAnneeN1') + this.getComputedValue('actifBImmobiliseImmobilisationsIncorporellesAnneeN1') + this.getComputedValue('actifCImmobiliseImmobilisationsCorporellesAnneeN1') + this.getComputedValue('actifDImmobiliseImmobilisationsFinancieresAnneeN1') + this.getComputedValue('actifEImmobiliseEcartsDeConversionAnneeN1')
        },
        actifFCirculantStocksAnneeN1(){
            return this.getComputedValue('actifF1CirculantMarchandisesAnneeN1') + this.getComputedValue('actifF2CirculantMatieresEtFournituresConsommablesAnneeN1') + this.getComputedValue('actifF3CirculantProduitsEnCoursAnneeN1') + this.getComputedValue('actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAnneeN1') + this.getComputedValue('actifF5CirculantProduitsFinisAnneeN1')
        },
        actifGCirculantCreancesCirculantAnneeN1(){
            return this.getComputedValue('actifG1CirculantFournisseursdebiteursAvancesAcomptesAnneeN1') + this.getComputedValue('actifG2CirculantClientsEtComptesRattachesAnneeN1') + this.getComputedValue('actifG3CirculantPersonnelAnneeN1') + this.getComputedValue('actifG4CirculantEtatAnneeN1') + this.getComputedValue('actifG5CirculantCompteAssociesAnneeN1') + this.getComputedValue('actifG6CirculantAutresDebiteursAnneeN1') + this.getComputedValue('actifG7CirculantCompteRegularisationAnneeN1')
        },
        actifHCirculantTitresEtValeursDePlacementAnneeN1(){
            return this.getComputedValue('actifHCirculantTitresEtValeursDePlacementBrutAnneeN1') - this.getComputedValue('actifHCirculantTitresEtValeursDePlacementAmrtProvAnneeN1');
        },
        actifICirculantEcartDeConversionAnneeN1()
        {
            return this.getComputedValue('actifICirculantEcartDeConversionBrutAnneeN1') - this.getComputedValue('actifICirculantEcartDeConversionAmrtProvAnneeN1');
        },
        actifTotalIICirculantTotalAnneeN1(){
            return this.getComputedValue('actifFCirculantStocksAnneeN1') + this.getComputedValue('actifGCirculantCreancesCirculantAnneeN1') + this.getComputedValue('actifHCirculantTitresEtValeursDePlacementAnneeN1') + this.getComputedValue('actifICirculantEcartDeConversionAnneeN1')
        },
        actifTresorTresorerieTotalAnneeN1(){
            return this.getComputedValue('actifTresor1TresorerieChequesEtValeursAEncaisserAnneeN1') + this.getComputedValue('actifTresor2TresorerieBanquesTGEtCPAnneeN1') + this.getComputedValue('actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAnneeN1')
        },
        actifTotalGeneralAnneeN1(){
            return this.getComputedValue('actifTotalIImmobiliseTotalAnneeN1') + this.getComputedValue('actifTotalIICirculantTotalAnneeN1') + this.getComputedValue('actifTresorTresorerieTotalAnneeN1')
        },

        // computed for passif

        passifAFinancementPermanentCapitauxPropres(){
            return this.getComputedValue('passifA2FinancementPermanentCapitalPersonnel') + this.getComputedValue('passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse') + this.getComputedValue('passifA6FinancementPermanentPrimeEmissionFusionApport') + this.getComputedValue('passifA7FinancementPermanentEcartsDeReevaluation') + this.getComputedValue('passifA8FinancementPermanentReserveLegale') + this.getComputedValue('passifA9FinancementPermanentAutresReserves') + this.getComputedValue('passifA10FinancementPermanentReportANouveau') + this.getComputedValue('passifA11FinancementPermanentResultatNetsEnInstanceDAffectation') + this.getComputedValue('passifA12FinancementPermanentResultatNetDeLexercice')
        },

        passifBFinancementPermanentCapitauxPropresAssimiles(){
            return this.getComputedValue('passifB1FinancementPermanentSubventionInvestissement') + this.getComputedValue('passifB2FinancementPermanentProvisionReglementees')
        },

        passifCFinancementPermanentDettesDeFinancement(){
            return this.getComputedValue('passifC1FinancementPermanentEmpruntsObligataires') + this.getComputedValue('passifC2FinancementPermanentAutresDettesDeFinancement')
        },
        passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges(){
            return this.getComputedValue('passifD1FinancementPermanentProvisionsPourRisques') + this.getComputedValue('passifD2FinancementPermanentProvisionsPourCharges')
        },
        passifEFinancementPermanentEcartsDeConversion(){
            return this.getComputedValue('passifE1FinancementPermanentAugmentationDesCreancesImmobilisees') + this.getComputedValue('passifE2FinancementPermanentDiminutionDesDettesDeFinancement')
        },
        passifTotalIFinancementPermanentTotal(){
            return this.getComputedValue('passifAFinancementPermanentCapitauxPropres') + this.getComputedValue('passifBFinancementPermanentCapitauxPropresAssimiles') + this.getComputedValue('passifCFinancementPermanentDettesDeFinancement') + this.getComputedValue('passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges') + this.getComputedValue('passifEFinancementPermanentEcartsDeConversion')
        },
        passifFCirculantDettesDuCirculant(){
            return this.getComputedValue('passifF1CirculantFournisseursEtComptesRattaches') + this.getComputedValue('passifF2CirculantClientsCrediteursAvancesEtAcomptes') + this.getComputedValue('passifF3CirculantPersonnel') + this.getComputedValue('passifF4CirculantOrganismesSociaux') + this.getComputedValue('passifF5CirculantEtat') + this.getComputedValue('passifF6CirculantComptesAssocies') + this.getComputedValue('passifF7CirculantAutresCreanciers') + this.getComputedValue('passifF8CirculantComptesDeRegularisation') 
        },
        passifTotalIICirculantTotal(){
            return this.getComputedValue('passifFCirculantDettesDuCirculant') + this.getComputedValue('passifGCirculantAutresProvisionsPourRisquesEtCharges') + this.getComputedValue('passifHCirculantEcartsDeConversion')
        },
        passifTresorTresorerieTresoreriePassif(){
            return this.getComputedValue('passifTresor1TresorerieCreditsEscompte') + this.getComputedValue('passifTresor2TresorerieCreditDeTresorerie') + this.getComputedValue('passifTresor3TresorerieBanques')
        },
        passifTresorTresorerieTotalIII()
        {
            return this.getComputedValue('passifTresor1TresorerieCreditsEscompte') + this.getComputedValue('passifTresor2TresorerieCreditDeTresorerie') + this.getComputedValue('passifTresor3TresorerieBanques')
        },
        TresorTresorerieTotalIII(){
            return this.getComputedValue('passifTresor1TresorerieCreditsEscompte') + this.getComputedValue('passifTresor2TresorerieCreditDeTresorerie') + this.getComputedValue('passifTresor3TresorerieBanques')
        },
        passifTotalGeneral(){
            return this.getComputedValue('passifTotalIFinancementPermanentTotal') + this.getComputedValue('passifTotalIICirculantTotal') + this.getComputedValue('passifTresorTresorerieTresoreriePassif')
        },

        passifAFinancementPermanentCapitauxPropresAnneeN1(){
            return this.getComputedValue('passifA2FinancementPermanentCapitalPersonnelpassifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonversepassifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifA6FinancementPermanentPrimeEmissionFusionApportpassifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifA7FinancementPermanentEcartsDeReevaluationpassifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifA8FinancementPermanentReserveLegalepassifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifA9FinancementPermanentAutresReservespassifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifA10FinancementPermanentReportANouveaupassifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifA11FinancementPermanentResultatNetsEnInstanceDAffectationpassifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifA12FinancementPermanentResultatNetDeLexercicepassifAFinancementPermanentCapitauxPropresAnneeN1')
        },

        passifBFinancementPermanentCapitauxPropresAssimilesAnneeN1(){
            return this.getComputedValue('passifB1FinancementPermanentSubventionInvestissementAnneeN1') + this.getComputedValue('passifB2FinancementPermanentProvisionReglementeesAnneeN1')
        },

        passifCFinancementPermanentDettesDeFinancementAnneeN1(){
            return this.getComputedValue('passifC1FinancementPermanentEmpruntsObligatairesAnneeN1') + this.getComputedValue('passifC2FinancementPermanentAutresDettesDeFinancementAnneeN1')
        },
        passifDFinancementPermanentProvisionsDurablesPourRisquesEtChargesAnneeN1(){
            return this.getComputedValue('passifD1FinancementPermanentProvisionsPourRisquesAnneeN1') + this.getComputedValue('passifD2FinancementPermanentProvisionsPourChargesAnneeN1')
        },
        passifEFinancementPermanentEcartsDeConversionAnneeN1(){
            return this.getComputedValue('passifE1FinancementPermanentAugmentationDesCreancesImmobiliseesAnneeN1') + this.getComputedValue('passifE2FinancementPermanentDiminutionDesDettesDeFinancementAnneeN1')
        },
        passifTotalIFinancementPermanentTotalAnneeN1(){
            return this.getComputedValue('passifAFinancementPermanentCapitauxPropresAnneeN1') + this.getComputedValue('passifBFinancementPermanentCapitauxPropresAssimilesAnneeN1') + this.getComputedValue('passifCFinancementPermanentDettesDeFinancementAnneeN1') + this.getComputedValue('passifDFinancementPermanentProvisionsDurablesPourRisquesEtChargesAnneeN1') + this.getComputedValue('passifEFinancementPermanentEcartsDeConversionAnneeN1')
        },
        passifFCirculantDettesDuCirculantAnneeN1(){
            return this.getComputedValue('passifF1CirculantFournisseursEtComptesRattachesAnneeN1') + this.getComputedValue('passifF2CirculantClientsCrediteursAvancesEtAcomptesAnneeN1') + this.getComputedValue('passifF3CirculantPersonnelAnneeN1') + this.getComputedValue('passifF4CirculantOrganismesSociauxAnneeN1') + this.getComputedValue('passifF5CirculantEtatAnneeN1') + this.getComputedValue('passifF6CirculantComptesAssociesAnneeN1') + this.getComputedValue('passifF7CirculantAutresCreanciersAnneeN1') + this.getComputedValue('passifF8CirculantComptesDeRegularisationAnneeN1') 
        },
        passifTotalIICirculantTotalAnneeN1(){
            return this.getComputedValue('passifFCirculantDettesDuCirculantAnneeN1') + this.getComputedValue('passifGCirculantAutresProvisionsPourRisquesEtChargesAnneeN1') + this.getComputedValue('passifHCirculantEcartsDeConversionAnneeN1')
        },
        passifTresorTresorerieTresoreriePassifAnneeN1(){
            return this.getComputedValue('passifTresor1TresorerieCreditsEscompteAnneeN1') + this.getComputedValue('passifTresor2TresorerieCreditDeTresorerieAnneeN1') + this.getComputedValue('passifTresor3TresorerieBanquesAnneeN1')
        },
        TresorTresorerieTotalIIIAnneeN1(){
            return this.getComputedValue('passifTresor1TresorerieCreditsEscompteAnneeN1') + this.getComputedValue('passifTresor2TresorerieCreditDeTresorerieAnneeN1') + this.getComputedValue('passifTresor3TresorerieBanquesAnneeN1')
        },
        passifTresorTresorerieTotalIIIAnneeN1()
        {
            this.getComputedValue('passifTresorTresorerieTresoreriePassifAnneeN1');
        },
        passifTotalGeneralAnneeN1(){
            return this.getComputedValue('passifTotalIFinancementPermanentTotalAnneeN1') + this.getComputedValue('passifTotalIICirculantTotalAnneeN1') + this.getComputedValue('passifTresorTresorerieTresoreriePassifAnneeN1')
        },
        /// computed for cpc
        cpcIExploitationProduitsExploitation() {
            return this.getComputedValue('cpcIExploitationProduitsExploitationExercice') - this.getComputedValue('cpcIExploitationProduitsExploitationPrecedent');
        },
        cpcI1ExploitationVentesDeMarchandises() {
            return this.getComputedValue('cpcI1ExploitationVentesDeMarchandisesExercice') - this.getComputedValue('cpcI1ExploitationVentesDeMarchandisesPrecedent');
        },
        cpcI2ExploitationVentesBiensEtServicesProduits() {
            return this.getComputedValue('cpcI2ExploitationVentesBiensEtServicesProduitsExercice') - this.getComputedValue('cpcI2ExploitationVentesBiensEtServicesProduitsPrecedent');
        },
        cpcI3ExploitationChiffresAffaires() {
            return this.getComputedValue('cpcI3ExploitationChiffresAffairesExercice') - this.getComputedValue('cpcI3ExploitationChiffresAffairesPrecedent');
        },
        cpcI4ExploitationVariationDeStockDeProduits() {
            return this.getComputedValue('cpcI4ExploitationVariationDeStockDeProduitsExercice') - this.getComputedValue('cpcI4ExploitationVariationDeStockDeProduitsPrecedent');
        },
        cpcI5ExploitationImmobilisationsProduitesPourElleMeme() {
            return this.getComputedValue('cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice') - this.getComputedValue('cpcI5ExploitationImmobilisationsProduitesPourElleMemePrecedent');
        },
        cpcI6ExploitationSubventionDExploitation() {
            return this.getComputedValue('cpcI6ExploitationSubventionDExploitationExercice') - this.getComputedValue('cpcI6ExploitationSubventionDExploitationPrecedent');
        },
        cpcI7ExploitationAutresProduitsExploitation() {
            return this.getComputedValue('cpcI7ExploitationAutresProduitsExploitationExercice') - this.getComputedValue('cpcI7ExploitationAutresProduitsExploitationPrecedent');
        },
        cpcI8ExploitationReprisesExploitationTransfertDeCharges() {
            return this.getComputedValue('cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice') - this.getComputedValue('cpcI8ExploitationReprisesExploitationTransfertDeChargesPrecedent');
        },
        cpcIIExploitationChargesExploitation() {
            return this.getComputedValue('cpcIIExploitationChargesExploitationExercice') - this.getComputedValue('cpcIIExploitationChargesExploitationPrecedent');
        },
        cpcII1ExploitationAchatsRevendusDeMarchandises() {
            return this.getComputedValue('cpcII1ExploitationAchatsRevendusDeMarchandisesExercice') - this.getComputedValue('cpcII1ExploitationAchatsRevendusDeMarchandisesPrecedent');
        },
        cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures() {
            return this.getComputedValue('cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice') - this.getComputedValue('cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresPrecedent');
        },
        cpcII3ExploitationAutresChargesExternes() {
            return this.getComputedValue('cpcII3ExploitationAutresChargesExternesExercice') - this.getComputedValue('cpcII3ExploitationAutresChargesExternesPrecedent');
        },
        cpcII4ExploitationImpotsEtTaxes() {
            return this.getComputedValue('cpcII4ExploitationImpotsEtTaxesExercice') - this.getComputedValue('cpcII4ExploitationImpotsEtTaxesPrecedent');
        },
        cpcII5ExploitationChargesPersonnel() {
            return this.getComputedValue('cpcII5ExploitationChargesPersonnelExercice') - this.getComputedValue('cpcII5ExploitationChargesPersonnelPrecedent');
        },
        cpcII6ExploitationAutresChargesExploitation() {
            return this.getComputedValue('cpcII6ExploitationAutresChargesExploitationExercice') - this.getComputedValue('cpcII6ExploitationAutresChargesExploitationPrecedent');
        },
        cpcII7ExploitationDotationsExploitation() {
            return this.getComputedValue('cpcII7ExploitationDotationsExploitationExercice') - this.getComputedValue('cpcII7ExploitationDotationsExploitationPrecedent');
        },
        cpcIIIExploitationResultatExploitation() {
            return this.getComputedValue('cpcIIIExploitationResultatExploitationExercice') - this.getComputedValue('cpcIIIExploitationResultatExploitationPrecedent');
        },
        cpcIVFinancierProduitsFinanciers() {
            return this.getComputedValue('cpcIVFinancierProduitsFinanciersExercice') - this.getComputedValue('cpcIVFinancierProduitsFinanciersPrecedent');
        },
        cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises() {
            return this.getComputedValue('cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice') - this.getComputedValue('cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesPrecedent');
        },
        cpcIV2FinancierGainsDeChange() {
            return this.getComputedValue('cpcIV2FinancierGainsDeChangeExercice') - this.getComputedValue('cpcIV2FinancierGainsDeChangePrecedent');
        },
        cpcIV3FinancierInteretsEtAutresProduitsFinanciers() {
            return this.getComputedValue('cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice') - this.getComputedValue('cpcIV3FinancierInteretsEtAutresProduitsFinanciersPrecedent');
        },
        cpcIV4FinancierReprisesFinancieresTransfertDeCharges() {
            return this.getComputedValue('cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice') - this.getComputedValue('cpcIV4FinancierReprisesFinancieresTransfertDeChargesPrecedent');
        },
        cpcVFinancierChargesFinancieres() {
            return this.getComputedValue('cpcVFinancierChargesFinancieresExercice') - this.getComputedValue('cpcVFinancierChargesFinancieresPrecedent');
        },
        cpcV1FinancierChargesInteret() {
            return this.getComputedValue('cpcV1FinancierChargesInteretExercice') - this.getComputedValue('cpcV1FinancierChargesInteretPrecedent');
        },
        cpcV2FinancierPertesDeChanges() {
            return this.getComputedValue('cpcV2FinancierPertesDeChangesExercice') - this.getComputedValue('cpcV2FinancierPertesDeChangesPrecedent');
        },
        cpcV3FinancierAutresChargesFinancieres() {
            return this.getComputedValue('cpcV3FinancierAutresChargesFinancieresExercice') - this.getComputedValue('cpcV3FinancierAutresChargesFinancieresPrecedent');
        },
        cpcV4FinancierDotationFinancieres() {
            return this.getComputedValue('cpcV4FinancierDotationFinancieresExercice') - this.getComputedValue('cpcV4FinancierDotationFinancieresPrecedent');
        },
        cpcVIFinancierResultatFinancier() {
            return this.getComputedValue('cpcVIFinancierResultatFinancierExercice') - this.getComputedValue('cpcVIFinancierResultatFinancierPrecedent');
        },
        cpcVIICourantResultatCourant() {
            return this.getComputedValue('cpcVIICourantResultatCourantExercice') - this.getComputedValue('cpcVIICourantResultatCourantPrecedent');
        },
        cpcVIIINonCourantProduitsNonCourants() {
            return this.getComputedValue('cpcVIIINonCourantProduitsNonCourantsExercice') - this.getComputedValue('cpcVIIINonCourantProduitsNonCourantsPrecedent');
        },
        cpcVIII1NonCourantProduitsDesCessionsDImmobilisations() {
            return this.getComputedValue('cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice') - this.getComputedValue('cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsPrecedent');
        },
        cpcVIII2NonCourantSubventionsDEquilibre() {
            return this.getComputedValue('cpcVIII2NonCourantSubventionsDEquilibreExercice') - this.getComputedValue('cpcVIII2NonCourantSubventionsDEquilibrePrecedent');
        },
        cpcVIII3NonCourantReprisesSurSubventionsDInvestissement() {
            return this.getComputedValue('cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice') - this.getComputedValue('cpcVIII3NonCourantReprisesSurSubventionsDInvestissementPrecedent');
        },
        cpcVIII4NonCourantAutresProduitsNonCourants() {
            return this.getComputedValue('cpcVIII4NonCourantAutresProduitsNonCourantsExercice') - this.getComputedValue('cpcVIII4NonCourantAutresProduitsNonCourantsPrecedent');
        },
        cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges() {
            return this.getComputedValue('cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice') - this.getComputedValue('cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesPrecedent');
        },
        cpcIXNonCourantChargesNonCourantes() {
            return this.getComputedValue('cpcIXNonCourantChargesNonCourantesExercice') - this.getComputedValue('cpcIXNonCourantChargesNonCourantesPrecedent');
        },
        cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees() {
            return this.getComputedValue('cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice') - this.getComputedValue('cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesPrecedent');
        },
        cpcIX2NonCourantSubventionsAccordees() {
            return this.getComputedValue('cpcIX2NonCourantSubventionsAccordeesExercice') - this.getComputedValue('cpcIX2NonCourantSubventionsAccordeesPrecedent');
        },
        cpcIX3NonCourantAutresChargesNonCourantes() {
            return this.getComputedValue('cpcIX3NonCourantAutresChargesNonCourantesExercice') - this.getComputedValue('cpcIX3NonCourantAutresChargesNonCourantesPrecedent');
        },
        cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision() {
            return this.getComputedValue('cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice') - this.getComputedValue('cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionPrecedent');
        },
        cpcXResultatNonCourant() {
            return this.getComputedValue('cpcXResultatNonCourantExercice') - this.getComputedValue('cpcXResultatNonCourantPrecedent');
        },
        cpcXIResultatAvantImpots() {
            return this.getComputedValue('cpcXIResultatAvantImpotsExercice') - this.getComputedValue('cpcXIResultatAvantImpotsPrecedent');
        },
        cpcXIIImpotsSurLesResultats() {
            return this.getComputedValue('cpcXIIImpotsSurLesResultatsExercice') - this.getComputedValue('cpcXIIImpotsSurLesResultatsPrecedent');
        },
        cpcXIIIResultatNet() {
            return this.getComputedValue('cpcXIIIResultatNetExercice') - this.getComputedValue('cpcXIIIResultatNetPrecedent');
        },
        cpcXIVTotalDesProduits() {
            return this.getComputedValue('cpcXIVTotalDesProduitsExercice') - this.getComputedValue('cpcXIVTotalDesProduitsPrecedent');
        },
        cpcXVTotalDesCharges() {
            return this.getComputedValue('cpcXVTotalDesChargesExercice') - this.getComputedValue('cpcXVTotalDesChargesPrecedent');
        },
        cpcXVIResultatNET() {
            return this.getComputedValue('cpcXVIResultatNETExercice') - this.getComputedValue('cpcXVIResultatNETPrecedent');
        },
        

        //
        cpcI3ExploitationChiffresAffairesExercice()
        {
            return this.getComputedValue('cpcI1ExploitationVentesDeMarchandisesExercice') + this.getComputedValue('cpcI2ExploitationVentesBiensEtServicesProduitsExercice')
        },
        cpcIExploitationProduitsExploitationExercice(){
            return  this.getComputedValue('cpcI3ExploitationChiffresAffairesExercice') + this.getComputedValue('cpcI4ExploitationVariationDeStockDeProduitsExercice') + this.getComputedValue('cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice') + this.getComputedValue('cpcI6ExploitationSubventionDExploitationExercice') + this.getComputedValue('cpcI7ExploitationAutresProduitsExploitationExercice') + this.getComputedValue('cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice')
        },
        cpcIIExploitationChargesExploitationExercice() {
            return this.getComputedValue('cpcII1ExploitationAchatsRevendusDeMarchandisesExercice') + this.getComputedValue('cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice') + this.getComputedValue('cpcII3ExploitationAutresChargesExternesExercice') + this.getComputedValue('cpcII4ExploitationImpotsEtTaxesExercice') + this.getComputedValue('cpcII5ExploitationChargesPersonnelExercice') + this.getComputedValue('cpcII6ExploitationAutresChargesExploitationExercice') + this.getComputedValue('cpcII7ExploitationDotationsExploitationExercice')
        },
        cpcIIIExploitationResultatExploitationExercice() {
            return this.getComputedValue('cpcIExploitationProduitsExploitationExercice') - this.getComputedValue('cpcIIExploitationChargesExploitationExercice')
        },
        cpcIVFinancierProduitsFinanciersExercice() {
            return this.getComputedValue('cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice') +  this.getComputedValue('cpcIV2FinancierGainsDeChangeExercice') + this.getComputedValue('cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice') + this.getComputedValue('cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice')
        },
        cpcVFinancierChargesFinancieresExercice() {
            return this.getComputedValue('cpcV1FinancierChargesInteretExercice') + this.getComputedValue('cpcV2FinancierPertesDeChangesExercice') + this.getComputedValue('cpcV3FinancierAutresChargesFinancieresExercice') + this.getComputedValue('cpcV4FinancierDotationFinancieresExercice')
        },
        cpcVIFinancierResultatFinancierExercice() {
            return this.getComputedValue('cpcIVFinancierProduitsFinanciersExercice') - this.getComputedValue('cpcVFinancierChargesFinancieresExercice')
        },
        cpcVIICourantResultatCourantExercice() {
            return this.getComputedValue('cpcIIIExploitationResultatExploitationExercice') + this.getComputedValue('cpcVIFinancierResultatFinancierExercice')
        },
        cpcVIIINonCourantProduitsNonCourantsExercice() {
            return this.getComputedValue('cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice') + this.getComputedValue('cpcVIII2NonCourantSubventionsDEquilibreExercice') + this.getComputedValue('cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice') + this.getComputedValue('cpcVIII4NonCourantAutresProduitsNonCourantsExercice') + this.getComputedValue('cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice')
        },
        cpcIXNonCourantChargesNonCourantesExercice() {
            return this.getComputedValue('cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice') + this.getComputedValue('cpcIX2NonCourantSubventionsAccordeesExercice') + this.getComputedValue('cpcIX3NonCourantAutresChargesNonCourantesExercice') + this.getComputedValue('cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice')
        },
        cpcXResultatNonCourantExercice() {
            return this.getComputedValue('cpcVIIINonCourantProduitsNonCourantsExercice') - this.getComputedValue('cpcIXNonCourantChargesNonCourantesExercice')
        },
        cpcXIResultatAvantImpotsExercice() {
            return this.getComputedValue('cpcVIICourantResultatCourantExercice') + this.getComputedValue('cpcXResultatNonCourantExercice')
        },
        cpcXIIIResultatNetExercice() {
            return this.getComputedValue('cpcXIResultatAvantImpotsExercice') - this.getComputedValue('cpcXIIImpotsSurLesResultatsExercice')
        },
        cpcXIVTotalDesProduitsExercice() {
            return this.getComputedValue('cpcIExploitationProduitsExploitationExercice') + this.getComputedValue('cpcIVFinancierProduitsFinanciersExercice') + this.getComputedValue('cpcVIIINonCourantProduitsNonCourantsExercice')
        },
        cpcXVTotalDesChargesExercice() {
            return this.getComputedValue('cpcIIExploitationChargesExploitationExercice') + this.getComputedValue('cpcVFinancierChargesFinancieresExercice') + this.getComputedValue('cpcIXNonCourantChargesNonCourantesExercice') + this.getComputedValue('cpcXIIImpotsSurLesResultatsExercice')
        },
        cpcXVIResultatNETExercice() {
            return this.getComputedValue('cpcXIVTotalDesProduitsExercice') - this.getComputedValue('cpcXVTotalDesChargesExercice')
        },

        //  --------------------Cpc Calculs

        cpcI3ExploitationChiffresAffairesCalcul()
        {
            return this.selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandises + this.selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduits
        },
        cpcIExploitationProduitsExploitationCalcul(){
            return  this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffaires + this.selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduits + this.selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMeme + this.selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitation + this.selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitation + this.selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeCharges
        },
        cpcIIExploitationChargesExploitationCalcul() {
            return this.selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandises + this.selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures + this.selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternes + this.selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxes + this.selectedBilanItemLocal.cpcII5ExploitationChargesPersonnel + this.selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitation + this.selectedBilanItemLocal.cpcII7ExploitationDotationsExploitation
        },
        cpcIIIExploitationResultatExploitationCalcul() {
            return this.selectedBilanItemLocal.cpcIExploitationProduitsExploitation - this.selectedBilanItemLocal.cpcIIExploitationChargesExploitation
        },
        cpcIVFinancierProduitsFinanciersCalcul() {
            return this.selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises +  this.selectedBilanItemLocal.cpcIV2FinancierGainsDeChange + this.selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciers + this.selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeCharges
        },
        cpcVFinancierChargesFinancieresCalcul() {
            return this.selectedBilanItemLocal.cpcV1FinancierChargesInteret + this.selectedBilanItemLocal.cpcV2FinancierPertesDeChanges + this.selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieres + this.selectedBilanItemLocal.cpcV4FinancierDotationFinancieres
        },
        cpcVIFinancierResultatFinancierCalcul() {
            return this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciers - this.selectedBilanItemLocal.cpcVFinancierChargesFinancieres
        },
        cpcVIICourantResultatCourantCalcul() {
            return this.selectedBilanItemLocal.cpcIIIExploitationResultatExploitation + this.selectedBilanItemLocal.cpcVIFinancierResultatFinancier
        },
        cpcVIIINonCourantProduitsNonCourantsCalcul() {
            return this.selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisations + this.selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibre + this.selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissement + this.selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourants + this.selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges
        },
        cpcIXNonCourantChargesNonCourantesCalcul() {
            return this.selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees + this.selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordees + this.selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantes + this.selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision
        },
        cpcXResultatNonCourantCalcul() {
            return this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourants - this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantes
        },
        cpcXIResultatAvantImpotsCalcul() {
            return this.selectedBilanItemLocal.cpcVIICourantResultatCourant + this.selectedBilanItemLocal.cpcXResultatNonCourant
        },
        cpcXIIIResultatNetCalcul() {
            return this.selectedBilanItemLocal.cpcXIResultatAvantImpots - this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultats
        },
        cpcXIVTotalDesProduitsCalcul() {
            return this.selectedBilanItemLocal.cpcIExploitationProduitsExploitation + this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciers + this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourants
        },
        cpcXVTotalDesChargesCalcul() {
            return this.selectedBilanItemLocal.cpcIIExploitationChargesExploitation + this.selectedBilanItemLocal.cpcVFinancierChargesFinancieres + this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantes + this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultats
        },
        cpcXVIResultatNETCalcul() {
            return this.selectedBilanItemLocal.cpcXIVTotalDesProduits - this.selectedBilanItemLocal.cpcXVTotalDesCharges
        },

        cpcI3ExploitationChiffresAffairesAnneeN1Calcul()
        {
            return this.selectedBilanItemLocalAnneeN1.cpcI1ExploitationVentesDeMarchandises + this.selectedBilanItemLocalAnneeN1.cpcI2ExploitationVentesBiensEtServicesProduits
        },
        cpcIExploitationProduitsExploitationAnneeN1Calcul(){
            return  this.selectedBilanItemLocalAnneeN1.cpcI3ExploitationChiffresAffaires + this.selectedBilanItemLocalAnneeN1.cpcI4ExploitationVariationDeStockDeProduits + this.selectedBilanItemLocalAnneeN1.cpcI5ExploitationImmobilisationsProduitesPourElleMeme + this.selectedBilanItemLocalAnneeN1.cpcI6ExploitationSubventionDExploitation + this.selectedBilanItemLocalAnneeN1.cpcI7ExploitationAutresProduitsExploitation + this.selectedBilanItemLocalAnneeN1.cpcI8ExploitationReprisesExploitationTransfertDeCharges
        },
        cpcIIExploitationChargesExploitationAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcII1ExploitationAchatsRevendusDeMarchandises + this.selectedBilanItemLocalAnneeN1.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures + this.selectedBilanItemLocalAnneeN1.cpcII3ExploitationAutresChargesExternes + this.selectedBilanItemLocalAnneeN1.cpcII4ExploitationImpotsEtTaxes + this.selectedBilanItemLocalAnneeN1.cpcII5ExploitationChargesPersonnel + this.selectedBilanItemLocalAnneeN1.cpcII6ExploitationAutresChargesExploitation + this.selectedBilanItemLocalAnneeN1.cpcII7ExploitationDotationsExploitation
        },
        cpcIIIExploitationResultatExploitationAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcIExploitationProduitsExploitation - this.selectedBilanItemLocalAnneeN1.cpcIIExploitationChargesExploitation
        },
        cpcIVFinancierProduitsFinanciersAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises +  this.selectedBilanItemLocalAnneeN1.cpcIV2FinancierGainsDeChange + this.selectedBilanItemLocalAnneeN1.cpcIV3FinancierInteretsEtAutresProduitsFinanciers + this.selectedBilanItemLocalAnneeN1.cpcIV4FinancierReprisesFinancieresTransfertDeCharges
        },
        cpcVFinancierChargesFinancieresAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcV1FinancierChargesInteret + this.selectedBilanItemLocalAnneeN1.cpcV2FinancierPertesDeChanges + this.selectedBilanItemLocalAnneeN1.cpcV3FinancierAutresChargesFinancieres + this.selectedBilanItemLocalAnneeN1.cpcV4FinancierDotationFinancieres
        },
        cpcVIFinancierResultatFinancierAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcIVFinancierProduitsFinanciers - this.selectedBilanItemLocalAnneeN1.cpcVFinancierChargesFinancieres
        },
        cpcVIICourantResultatCourantAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcIIIExploitationResultatExploitation + this.selectedBilanItemLocalAnneeN1.cpcVIFinancierResultatFinancier
        },
        cpcVIIINonCourantProduitsNonCourantsAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcVIII1NonCourantProduitsDesCessionsDImmobilisations + this.selectedBilanItemLocalAnneeN1.cpcVIII2NonCourantSubventionsDEquilibre + this.selectedBilanItemLocalAnneeN1.cpcVIII3NonCourantReprisesSurSubventionsDInvestissement + this.selectedBilanItemLocalAnneeN1.cpcVIII4NonCourantAutresProduitsNonCourants + this.selectedBilanItemLocalAnneeN1.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges
        },
        cpcIXNonCourantChargesNonCourantesAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees + this.selectedBilanItemLocalAnneeN1.cpcIX2NonCourantSubventionsAccordees + this.selectedBilanItemLocalAnneeN1.cpcIX3NonCourantAutresChargesNonCourantes + this.selectedBilanItemLocalAnneeN1.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision
        },
        cpcXResultatNonCourantAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcVIIINonCourantProduitsNonCourants - this.selectedBilanItemLocalAnneeN1.cpcIXNonCourantChargesNonCourantes
        },
        cpcXIResultatAvantImpotsAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcVIICourantResultatCourant + this.selectedBilanItemLocalAnneeN1.cpcXResultatNonCourant
        },
        cpcXIIIResultatNetAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcXIResultatAvantImpots - this.selectedBilanItemLocalAnneeN1.cpcXIIImpotsSurLesResultats
        },
        cpcXIVTotalDesProduitsAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcIExploitationProduitsExploitation + this.selectedBilanItemLocalAnneeN1.cpcIVFinancierProduitsFinanciers + this.selectedBilanItemLocalAnneeN1.cpcVIIINonCourantProduitsNonCourants
        },
        cpcXVTotalDesChargesAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcIIExploitationChargesExploitation + this.selectedBilanItemLocalAnneeN1.cpcVFinancierChargesFinancieres + this.selectedBilanItemLocalAnneeN1.cpcIXNonCourantChargesNonCourantes + this.selectedBilanItemLocalAnneeN1.cpcXIIImpotsSurLesResultats
        },
        cpcXVIResultatNETAnneeN1Calcul() {
            return this.selectedBilanItemLocalAnneeN1.cpcXIVTotalDesProduits - this.selectedBilanItemLocalAnneeN1.cpcXVTotalDesCharges
        },

        cpcI3ExploitationChiffresAffairesPrecedent()
        {
            return this.getComputedValue('cpcI1ExploitationVentesDeMarchandisesPrecedent') + this.getComputedValue('cpcI2ExploitationVentesBiensEtServicesProduitsPrecedent')
        },
        cpcIExploitationProduitsExploitationPrecedent(){
            return this.getComputedValue('cpcI3ExploitationChiffresAffairesPrecedent') + this.getComputedValue('cpcI4ExploitationVariationDeStockDeProduitsPrecedent') + this.getComputedValue('cpcI5ExploitationImmobilisationsProduitesPourElleMemePrecedent') + this.getComputedValue('cpcI6ExploitationSubventionDExploitationPrecedent') + this.getComputedValue('cpcI7ExploitationAutresProduitsExploitationPrecedent') + this.getComputedValue('cpcI8ExploitationReprisesExploitationTransfertDeChargesPrecedent')
        },
        cpcIIExploitationChargesExploitationPrecedent() {
            return this.getComputedValue('cpcII1ExploitationAchatsRevendusDeMarchandisesPrecedent') + this.getComputedValue('cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresPrecedent') + this.getComputedValue('cpcII3ExploitationAutresChargesExternesPrecedent') + this.getComputedValue('cpcII4ExploitationImpotsEtTaxesPrecedent') + this.getComputedValue('cpcII5ExploitationChargesPersonnelPrecedent') + this.getComputedValue('cpcII6ExploitationAutresChargesExploitationPrecedent') + this.getComputedValue('cpcII7ExploitationDotationsExploitationPrecedent')
        },
        cpcIIIExploitationResultatExploitationPrecedent() {
            return this.getComputedValue('cpcIExploitationProduitsExploitationPrecedent') - this.getComputedValue('cpcIIExploitationChargesExploitationPrecedent')
        },
        cpcIVFinancierProduitsFinanciersPrecedent() {
            return this.getComputedValue('cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesPrecedent') +  this.getComputedValue('cpcIV2FinancierGainsDeChangePrecedent') + this.getComputedValue('cpcIV3FinancierInteretsEtAutresProduitsFinanciersPrecedent') + this.getComputedValue('cpcIV4FinancierReprisesFinancieresTransfertDeChargesPrecedent')
        },
        cpcVFinancierChargesFinancieresPrecedent() {
            return this.getComputedValue('cpcV1FinancierChargesInteretPrecedent') + this.getComputedValue('cpcV2FinancierPertesDeChangesPrecedent') + this.getComputedValue('cpcV3FinancierAutresChargesFinancieresPrecedent') + this.getComputedValue('cpcV4FinancierDotationFinancieresPrecedent')
        },
        cpcVIFinancierResultatFinancierPrecedent() {
            return this.getComputedValue('cpcIVFinancierProduitsFinanciersPrecedent') - this.getComputedValue('cpcVFinancierChargesFinancieresPrecedent')
        },
        cpcVIICourantResultatCourantPrecedent() {
            return this.getComputedValue('cpcIIIExploitationResultatExploitationPrecedent') + this.getComputedValue('cpcVIFinancierResultatFinancierPrecedent')
        },
        cpcVIIINonCourantProduitsNonCourantsPrecedent() {
            return this.getComputedValue('cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsPrecedent') + this.getComputedValue('cpcVIII2NonCourantSubventionsDEquilibrePrecedent') + this.getComputedValue('cpcVIII3NonCourantReprisesSurSubventionsDInvestissementPrecedent') + this.getComputedValue('cpcVIII4NonCourantAutresProduitsNonCourantsPrecedent') + this.getComputedValue('cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesPrecedent')
        },
        cpcIXNonCourantChargesNonCourantesPrecedent() {
            return this.getComputedValue('cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesPrecedent') + this.getComputedValue('cpcIX2NonCourantSubventionsAccordeesPrecedent') + this.getComputedValue('cpcIX3NonCourantAutresChargesNonCourantesPrecedent') + this.getComputedValue('cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionPrecedent')
        },
        cpcXResultatNonCourantPrecedent() {
            return this.getComputedValue('cpcVIIINonCourantProduitsNonCourantsPrecedent') - this.getComputedValue('cpcIXNonCourantChargesNonCourantesPrecedent')
        },
        cpcXIResultatAvantImpotsPrecedent() {
            return this.getComputedValue('cpcVIICourantResultatCourantPrecedent') + this.getComputedValue('cpcXResultatNonCourantPrecedent')
        },
        cpcXIIIResultatNetPrecedent() {
            return this.getComputedValue('cpcXIResultatAvantImpotsPrecedent') - this.getComputedValue('cpcXIIImpotsSurLesResultatsPrecedent')
        },
        cpcXIVTotalDesProduitsPrecedent() {
            return this.getComputedValue('cpcIExploitationProduitsExploitationPrecedent') + this.getComputedValue('cpcIVFinancierProduitsFinanciersPrecedent') + this.getComputedValue('cpcVIIINonCourantProduitsNonCourantsPrecedent')
        },
        cpcXVTotalDesChargesPrecedent() {
            return this.getComputedValue('cpcIIExploitationChargesExploitationPrecedent') + this.getComputedValue('cpcVFinancierChargesFinancieresPrecedent') + this.getComputedValue('cpcIXNonCourantChargesNonCourantesPrecedent') + this.getComputedValue('cpcXIIImpotsSurLesResultatsPrecedent')
        },
        cpcXVIResultatNETPrecedent() {
            return this.getComputedValue('cpcXIVTotalDesProduitsPrecedent') - this.getComputedValue('cpcXVTotalDesChargesPrecedent')
        },

        cpcI3ExploitationChiffresAffairesAnneeN1()
        {
            return this.getComputedValue('cpcI1ExploitationVentesDeMarchandisesAnneeN1') + this.getComputedValue('cpcI2ExploitationVentesBiensEtServicesProduitsAnneeN1')
        },
        cpcIExploitationProduitsExploitationAnneeN1(){
            return this.getComputedValue('cpcI3ExploitationChiffresAffairesAnneeN1') + this.getComputedValue('cpcI4ExploitationVariationDeStockDeProduitsAnneeN1') + this.getComputedValue('cpcI5ExploitationImmobilisationsProduitesPourElleMemeAnneeN1') + this.getComputedValue('cpcI6ExploitationSubventionDExploitationAnneeN1') + this.getComputedValue('cpcI7ExploitationAutresProduitsExploitationAnneeN1') + this.getComputedValue('cpcI8ExploitationReprisesExploitationTransfertDeChargesAnneeN1')
        },
        cpcIIExploitationChargesExploitationAnneeN1() {
            return this.getComputedValue('cpcII1ExploitationAchatsRevendusDeMarchandisesAnneeN1') + this.getComputedValue('cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresAnneeN1') + this.getComputedValue('cpcII3ExploitationAutresChargesExternesAnneeN1') + this.getComputedValue('cpcII4ExploitationImpotsEtTaxesAnneeN1') + this.getComputedValue('cpcII5ExploitationChargesPersonnelAnneeN1') + this.getComputedValue('cpcII6ExploitationAutresChargesExploitationAnneeN1') + this.getComputedValue('cpcII7ExploitationDotationsExploitationAnneeN1')
        },
        cpcIIIExploitationResultatExploitationAnneeN1() {
            return this.getComputedValue('cpcIExploitationProduitsExploitationAnneeN1') - this.getComputedValue('cpcIIExploitationChargesExploitationAnneeN1')
        },
        cpcIVFinancierProduitsFinanciersAnneeN1() {
            return this.getComputedValue('cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesAnneeN1') +  this.getComputedValue('cpcIV2FinancierGainsDeChangeAnneeN1') + this.getComputedValue('cpcIV3FinancierInteretsEtAutresProduitsFinanciersAnneeN1') + this.getComputedValue('cpcIV4FinancierReprisesFinancieresTransfertDeChargesAnneeN1')
        },
        cpcVFinancierChargesFinancieresAnneeN1() {
            return this.getComputedValue('cpcV1FinancierChargesInteretAnneeN1') + this.getComputedValue('cpcV2FinancierPertesDeChangesAnneeN1') + this.getComputedValue('cpcV3FinancierAutresChargesFinancieresAnneeN1') + this.getComputedValue('cpcV4FinancierDotationFinancieresAnneeN1')
        },
        cpcVIFinancierResultatFinancierAnneeN1() {
            return this.getComputedValue('cpcIVFinancierProduitsFinanciersAnneeN1') - this.getComputedValue('cpcVFinancierChargesFinancieresAnneeN1')
        },
        cpcVIICourantResultatCourantAnneeN1() {
            return this.getComputedValue('cpcIIIExploitationResultatExploitationAnneeN1') + this.getComputedValue('cpcVIFinancierResultatFinancierAnneeN1')
        },
        cpcVIIINonCourantProduitsNonCourantsAnneeN1() {
            return this.getComputedValue('cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsAnneeN1') + this.getComputedValue('cpcVIII2NonCourantSubventionsDEquilibreAnneeN1') + this.getComputedValue('cpcVIII3NonCourantReprisesSurSubventionsDInvestissementAnneeN1') + this.getComputedValue('cpcVIII4NonCourantAutresProduitsNonCourantsAnneeN1') + this.getComputedValue('cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesAnneeN1')
        },
        cpcIXNonCourantChargesNonCourantesAnneeN1() {
            return this.getComputedValue('cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesAnneeN1') + this.getComputedValue('cpcIX2NonCourantSubventionsAccordeesAnneeN1') + this.getComputedValue('cpcIX3NonCourantAutresChargesNonCourantesAnneeN1') + this.getComputedValue('cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionAnneeN1')
        },
        cpcXResultatNonCourantAnneeN1() {
            return this.getComputedValue('cpcVIIINonCourantProduitsNonCourantsAnneeN1') - this.getComputedValue('cpcIXNonCourantChargesNonCourantesAnneeN1')
        },
        cpcXIResultatAvantImpotsAnneeN1() {
            return this.getComputedValue('cpcVIICourantResultatCourantAnneeN1') + this.getComputedValue('cpcXResultatNonCourantAnneeN1')
        },
        cpcXIIIResultatNetAnneeN1() {
            return this.getComputedValue('cpcXIResultatAvantImpotsAnneeN1') - this.getComputedValue('cpcXIIImpotsSurLesResultatsAnneeN1')
        },
        cpcXIVTotalDesProduitsAnneeN1() {
            return this.getComputedValue('cpcIExploitationProduitsExploitationAnneeN1') + this.getComputedValue('cpcIVFinancierProduitsFinanciersAnneeN1') + this.getComputedValue('cpcVIIINonCourantProduitsNonCourantsAnneeN1')
        },
        cpcXVTotalDesChargesAnneeN1() {
            return this.getComputedValue('cpcIIExploitationChargesExploitationAnneeN1') + this.getComputedValue('cpcVFinancierChargesFinancieresAnneeN1') + this.getComputedValue('cpcIXNonCourantChargesNonCourantesAnneeN1') + this.getComputedValue('cpcXIIImpotsSurLesResultatsAnneeN1')
        },
        cpcXVIResultatNETAnneeN1() {
            return this.getComputedValue('cpcXIVTotalDesProduitsAnneeN1') - this.getComputedValue('cpcXVTotalDesChargesAnneeN1')
        },

        myValidationSchema() {
             // Using yup to generate a validation schema
            // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
            // if(!this.isMounted)
            // {
            //     return 0;
            // }
            return yup.object().shape({
                    // ca: yup.number().typeError("Veuillez saisir un montant valide"),
                    // dateBilan: yup.string(),
                    // cashEtEquivalent: yup.number().typeError("Veuillez saisir un montant valide"),
                    // achatsRevendusEtAchatsConsommes: yup.number('Champs non numérique').typeError("Veuillez saisir un montant valide"),
                    // actifNonCirculantAuto: yup.number().typeError("Veuillez saisir un montant valide"),
                    // ca: yup.number().typeError("Veuillez saisir un montant valide"),
                    // cashEtEquivalent: yup.number().typeError("Veuillez saisir un montant valide"),
                    // chargesInteretNet: yup.number().typeError("Veuillez saisir un montant valide"),
                    // clientsEtComptesRattaches: yup.number().typeError("Veuillez saisir un montant valide"),
                    // dettesACourtTerme: yup.number().typeError("Veuillez saisir un montant valide"),
                    // dettesAMoyenEtLongTermeAuto: yup.number().typeError("Veuillez saisir un montant valide"),
                    // dotationExploitation: yup.number().typeError("Veuillez saisir un montant valide"),
                    // fondDeRoulementAuto: yup.number().typeError("Veuillez saisir un montant valide"),
                    // fondsPropres: yup.number().typeError("Veuillez saisir un montant valide"),
                    // fournisseursEtComptesRattaches: yup.number().typeError("Veuillez saisir un montant valide"),
                    // pourcentageEbitdaAuto: yup.number().typeError("Veuillez saisir un montant valide"),
                    // resultatAvantImpots: yup.number().typeError("Veuillez saisir un montant valide"),
                    // resultatExploitation: yup.number().typeError("Veuillez saisir un montant valide"),
                    // resultatNonCourant: yup.number().typeError("Veuillez saisir un montant valide"),
                    // stock: yup.number().typeError("Veuillez saisir un montant valide"),
                    // totalActif: yup.number().typeError("Veuillez saisir un montant valide"),
                    // totalActifCirculant: yup.number().typeError("Veuillez saisir un montant valide"),
            });
        },
    },
    props:['selectedBilanItemProp','showBilanDialogFlag','societe','inforisk','ompic','disabledButtons','printable'],
    emits:['close','saved'],
    methods:{
        createBilanEmpty()
        {
            var ref = this;

            Object.keys(this.actifMapping).forEach(function(attr){
                ref.selectedBilanItemLocal[attr] = 0
            })

            Object.keys(this.passifMapping).forEach(function(attr){
                ref.selectedBilanItemLocal[attr] = 0
            })

            Object.keys(this.cpcMapping).forEach(function(attr){
                ref.selectedBilanItemLocal[attr] = 0
            })

            this.createBilanFlag = true;
        },
        recalculateBilan()
        {
            var ref = this;
            // needs preprocessing of calculations before the main one
            this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffaires = this.cpcI3ExploitationChiffresAffairesCalcul

            Object.keys(this.selectedBilanItemLocal).forEach(function(attr){
                if(attr + 'Calcul' in ref && !isNaN(ref[attr + 'Calcul']) && ref[attr + 'Calcul'])
                {
                    ref.selectedBilanItemLocal[attr] = ref.getCalculValue(attr)
                }

                if(attr in ref && !isNaN(ref[attr]) && (attr.includes('passif') || attr=="TresorTresorerieTotalIII") && ref[attr])
                {
                    ref.selectedBilanItemLocal[attr] = ref.getComputedValue(attr)
                }
                
            })

            //needs post processing for the last to be calculated
            this.selectedBilanItemLocal.cpcXIVTotalDesProduits = this.cpcXIVTotalDesProduitsCalcul
            this.selectedBilanItemLocal.cpcXVTotalDesCharges = this.cpcXVTotalDesChargesCalcul
            this.selectedBilanItemLocal.cpcXVIResultatNET = this.cpcXVIResultatNETCalcul

            
        },
        getCalculValue(propertyName)
        {
            if(propertyName + 'Calcul' in this && !isNaN(this[propertyName + 'Calcul']) && this[propertyName + 'Calcul'])
            {
                return this[propertyName + 'Calcul']
            }
            return this.getComputedValue(propertyName)

        },
        getComputedValue(propertyName) {

            // if((propertyName.includes('actif') && propertyName.includes('Brut')) || (propertyName.includes('cpc') && propertyName.includes('Exercice')))
            // {
            //     let propertyNameSpliced
            //     if((propertyName.includes('actif') && propertyName.includes('Brut')))
            //     {
            //         propertyNameSpliced = propertyName.substring(0,propertyName.lastIndexOf('Brut'))
            //     }

            //     if((propertyName.includes('cpc') && propertyName.includes('Exercice')))
            //     {
            //         propertyNameSpliced = propertyName.substring(0,propertyName.lastIndexOf('Exercice'))
            //     }
                
            //     if(propertyNameSpliced + 'Calcul' in this && !isNaN(this[propertyNameSpliced + 'Calcul']) && this[propertyNameSpliced + 'Calcul'])
            //     {
            //         return this[propertyNameSpliced + 'Calcul']
            //     }
            // }

            // if(propertyName + 'Calcul' in this && !isNaN(this[propertyName + 'Calcul']) && this[propertyName + 'Calcul'])
            // {
            //     return this[propertyName + 'Calcul']
            // }

            if(!propertyName.includes('AnneeN1'))
            {
                if(propertyName in this && !isNaN(this[propertyName]))
                {
                    return this[propertyName]
                }
                else
                {
                    return isNaN(this.selectedBilanItemLocal[propertyName])?0:this.selectedBilanItemLocal[propertyName]
                }
            }
            else
            {
                if(propertyName in this && !isNaN(this[propertyName]))
                {
                    return this[propertyName]
                }
                else
                {
                    return isNaN(this.selectedBilanItemLocalAnneeN1[propertyName])?0:this.selectedBilanItemLocalAnneeN1[propertyName]
                }
            }
        },
        switchToManuel()
            {
                this.inforiskLocal = false;
                this.selectedBilanItemLocal.inforisk = 0
            },
        async loadCompanyICE()
            {
                this.InforiskLoading = true
                var  response = await CRUDService.listData('companyFromICE/'+this.societe.id+'/'+this.societe.ice,this.credentials)
                
                if(response.data)
                {
                    this.companyInforisk =  response.data;
                }
                else
                {
                    alert("identifiants erronés ou bilan introuvable,veuillez vérifier vos identifiants et l'ICE de votre client")
                    this.InforiskLoading = false
                }
        },
        async ImporterBilanInforiskAvecEntreprisevalidee()
            {
                
            var  response = await CRUDService.listData('GetBilanFromSociete/'+this.companyInforisk._iIdBil+'/'+this.selectedBilanItemLocal.dateBilan+'/'+this.societe.id,this.credentials)
            var recordObject = response.data;
            this.jsonResult = recordObject;
            this.companyInforisk=null

            if(this.jsonResult == "BilanNotFound")
            {
                alert("Bilan "+ this.selectedBilanItemLocal.dateBilan +" introuvable sur Inforisk")
                this.InforiskLoading = false
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }

            if(this.jsonResult == "ErreurRecherche")
            {
                alert("Erreur dans la recherche inforisk, Vos identifiant sont erronnés veuillez contacter l'administrateur")
                this.InforiskLoading = false
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }

            if(this.jsonResult == "ErreurSaisieIdentifiants")
            {
                alert("Vos identifiant ne sont pas saisies ou sont saisies incorrectement. Veuillez contacter l'administrateur")
                this.InforiskLoading = false
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }

            if(this.jsonResult == "ErreurSocieteVide")
            {
                alert("La societe selectionnée n'a pas d'ICE, veuillez en saisir au moins un")
                this.InforiskLoading = false
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }
            

            if(!this.jsonResult['_stActif'] || !this.jsonResult['_stPassif'] || !this.jsonResult['_stPassif'])
            {
                alert("Document introuvable sur Inforisk");
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }

            for(var prop in this.actifMapping)
            {
                if(this.jsonResult['_stActif'])
                {
                    this.selectedBilanItemLocal[prop] = this.jsonResult['_stActif'][this.actifMapping[prop]]
                }
                // else
                // {
                //     alert("Actif introuvable sur Inforisk");
                // }
            }

            for(var prop in this.passifMapping)
            {
                if(this.jsonResult['_stPassif'])
                {
                    this.selectedBilanItemLocal[prop] = this.jsonResult['_stPassif'][this.passifMapping[prop]]
                }
            //    else
            //    {
            //         alert("Passif introuvable sur Inforisk");
            //    }
                
            }

            for(var prop in this.cpcMapping)
            {
                if(this.jsonResult['_stCPC'])
                {
                    this.selectedBilanItemLocal[prop] = this.jsonResult['_stCPC'][this.cpcMapping[prop]]
                }
                // else
                // {
                //     alert("CPC introuvable sur Inforisk");
                // }
                
            }

            this.selectedBilanItemLocal["inforisk"] = 1
            this.isSaving = true;
            this.InforiskLoading = false;
            
            if(this.selectedBilanItemLocal.inforisk && this.jsonResult['_stCPC'] && this.jsonResult['_stActif'] && this.jsonResult['_stPassif'])
            {
                var yearN1 = parseInt(this.selectedBilanItemLocal.dateBilan)-1;
                this.selectedBilanItemLocal['inforiskJson'] = JSON.stringify(this.jsonResult);
                var response = await this.createBilan(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan);
                this.selectedBilanItemLocal = response

                var bilanN1 = await this.loadBilan(yearN1.toString());
                if(bilanN1)
                {
                    this.selectedBilanItemLocalAnneeN1 = bilanN1;
                }

            }
        },
        // returns an mapped item from Inforisk Json cache/log
        async loadCachedAndMappedBilanFinancierFromLogBilanInforisk(baseSelectedItem,year)
            {
                var selectedBilan=baseSelectedItem
            this.InforiskLoading = true
            //Loads bilan from cache
            var response = await CRUDService.list('InforiskCached/'+this.societe.id+'/'+year)
            if(!response.data)
            {
                return {id:0,dateBilan:selectedBilan.dateBilan};
                // response = await CRUDService.listData('Inforisk/'+this.societe.id+'/'+year,this.credentials)
                // var recordObject = response.data;
                // this.jsonResult = recordObject;
            }
            else
            {
                this.toastSuccess("Bilan chargé depuis le cache")
                var recordObject = response.data;
                this.jsonResult = JSON.parse(recordObject.Json);
                
                selectedBilan.inforiskJson = recordObject.Json; // set the cached json in the item
            }

            if(response.data)
            {

                    if(this.jsonResult == "BilanNotFound")
                    {
                        alert("Bilan "+ selectedBilan.dateBilan +" introuvable sur Inforisk")
                        this.InforiskLoading = false
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }

                    if(this.jsonResult == "ErreurRecherche")
                    {
                        alert("Erreur dans la recherche inforisk, Vos identifiant sont erronnés veuillez contacter l'administrateur")
                        this.InforiskLoading = false
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }

                    if(this.jsonResult == "ErreurSaisieIdentifiants")
                    {
                        alert("Vos identifiant ne sont pas saisies ou sont saisies incorrectement. Veuillez contacter l'administrateur")
                        this.InforiskLoading = false
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }

                    if(this.jsonResult == "ErreurSocieteVide")
                    {
                        alert("La societe selectionnée n'a ni RC ni ICE, veuillez en saisir au moins un")
                        this.InforiskLoading = false
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }
                    

                    if(!this.jsonResult['_stActif'] || !this.jsonResult['_stPassif'] || !this.jsonResult['_stPassif'])
                    {
                        alert("Document introuvable sur Inforisk");
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }

                    for(var prop in this.actifMapping)
                    {
                        if(this.jsonResult['_stActif'])
                        {
                            selectedBilan[prop] = this.jsonResult['_stActif'][this.actifMapping[prop]]
                        }
                        // else
                        // {
                        //     alert("Actif introuvable sur Inforisk");
                        // }
                    }

                    for(var prop in this.passifMapping)
                    {
                        if(this.jsonResult['_stPassif'])
                        {
                            selectedBilan[prop] = this.jsonResult['_stPassif'][this.passifMapping[prop]]
                        }
                    //    else
                    //    {
                    //         alert("Passif introuvable sur Inforisk");
                    //    }
                        
                    }

                    for(var prop in this.cpcMapping)
                    {
                        if(this.jsonResult['_stCPC'])
                        {
                            selectedBilan[prop] = this.jsonResult['_stCPC'][this.cpcMapping[prop]]
                        }
                        // else
                        // {
                        //     alert("CPC introuvable sur Inforisk");
                        // }
                        
                    }

                    selectedBilan["inforisk"] = 1
                    this.isSaving = true;
                    this.InforiskLoading = false;
                    
                    
            }
            else
            {
                this.InforiskLoading = true
                alert("Société introuvable veuillez saisir un ICE correct ou un RC et code tribunal correspondants")
                this.errorMessage = response
                return {id:0}
                        
            }
            
            this.InforiskLoading = false
            return selectedBilan
            
        },
        async loadBilan(annee)
            {
                var response = ""
                try
                {
                    var response = await crudService.list("bilanfinancierbyannee/"+annee+"/"+this.societe.id)
                }
                catch(error)
                {
                    return error
                }
            
            if(response.data)
            {
                this.blockedDialog = false;
                ;
                this.selectedBilanItemLocalAnneeN1 = response.data;
                this.foundBilanN1 = true
                // this.toastSuccess("Bilan N-1 chargé (" + annee +') depuis '+ (this.selectedBilanItemLocalAnneeN1.inforisk?"inforisk":"manuel"));
                return response.data
            }
            else
            {
                this.foundBilanN1 = false
                return {}
            }
                    
        },
        async createBilan(selectedItem,annee)
            {
            this.isSaving = true;
            this.log("saveGridItem");
            this.log(selectedItem);
            selectedItem.societeId = this.societe.id
            this.selectedBilanItemLocal.dateBilan = annee

            var response = ""
            try
            {
                var response = await CRUDService.create(selectedItem,'bilanfinancierclient')
                if(response.data)
                {
                    this.log(response.data);
                    this.toastSuccess("Le bilan "+ response.data.dateBilan +" a été créé avec succès");
                    this.$emit('saved');
                    this.isSaving = false;
                    return response.data
                }
                else
                {
                    return {id:0};
                }
                
                
            }
            catch(error)
            {
                this.toastError("Echec création de l''enregistrement !");
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                this.isSaving = false;
            }
        
        },
        async CreerBilanBoutonAction()
            {
                var yearN1 = parseInt(this.selectedBilanItemLocal.dateBilan)-1;
                

            this.selectedBilanItemLocal = await this.createBilan(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan);
            
            var bilanN1 = await this.loadBilan(yearN1.toString());
            
            if(!bilanN1)
            {
                this.selectedBilanItemLocalAnneeN1 = bilanN1;
            }

        },
        async ImporterBilanOmpic()
        {
                try
                {
                    var response = await CRUDService.listData('AddOrUpdateNewBilan/'+this.societe.id+'/'+this.selectedBilanItemLocal.dateBilan.substring(0,4) ,{})
                    var recordObject = response.data;
                    this.jsonResult = recordObject;
                    this.toastSuccess("Entreprise crée veuillez la consulter dans les bilans existants.");
                }
                catch(e)
                {
                    console.log(e)
                    this.toastError("Echec création de l''enregistrement !");
                }

        },
        //Button to import from cache or 
        //to load Company and validates the client and bilan brought from inforisk
        async ImporterBilanInforiskBoutonAction()
            {
                if(!this.credentials.username && !this.credentials.password)
                {
                alert("Veuillez saisir vos identifiants Inforisk")
                return;
            }
            var yearN1 = parseInt(this.selectedBilanItemLocal.dateBilan)-1;
            
            
            //Loads bilan from cache
            this.selectedBilanItemLocal = await this.loadCachedAndMappedBilanFinancierFromLogBilanInforisk(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan)
            
            if(this.selectedBilanItemLocal.inforisk)
            {
                var response = await this.createBilan(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan);
                this.selectedBilanItemLocal = response

                var bilanN1 = await this.loadBilan(yearN1.toString());
                if(bilanN1)
                {
                    this.selectedBilanItemLocalAnneeN1 = bilanN1;
                }

            }
            else
            {
                //load company into window then 
                await this.loadCompanyICE()
            }

        },
        onSubmit(values) {
            this.log("onSubmit");
            this.isSaving = true;
            this.log(values);
            this.saveBilan();
        },
        closeDialog()
            {
                this.$emit('close');
        }   ,
        async saveBilan()
            {
                
            var  isUpdating = false;
            this.log("saveGridItem");
            this.log(this.selectedBilanItemLocal);
            var ref = this;

            if(this.selectedBilanItemLocal && this.selectedBilanItemLocal.ompic==1)
            {
                this.selectedBilanItemLocal.id = 0
                this.selectedBilanItemLocal.ompic=2
                this.createBilanFlag = true;
                this.ompicLocal = false
                this.isSaving = false;
                return;
                // 
            }

            if(this.selectedBilanItemLocal.inforisk == 1 || this.inforiskLocal)
            {
                this.selectedBilanItemLocal.id = 0
                this.selectedBilanItemLocal.inforisk=2
                this.createBilanFlag = true;
                this.isSaving = false;
                return;
            }

            // ==================== Save Bilan N ===================
            // cas mode edition;
            if(this.selectedBilanItemLocal.id > 0)
            {
                this.selectedBilanItemLocal.estAnneePrecedente = 0
                try
                {
                    var response = await CRUDService.update(this.selectedBilanItemLocal,'bilanfinancierclient')
                    isUpdating = true
                    this.log(response.data);
                    this.toastSuccess("Le bilan "+ response.data.dateBilan +" a été enregistré avec succès");
                    this.isSaving = false;
                }
                catch(error) {
                    alert("Echec sauvegarde de l''enregistrement !");
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    this.isSaving = false;
                    return;
                }
                
            }
            //cas mode création
            else
            {
                //on crée seulement le bilan si il a des valeurs 
                //sinon on informe le client que le bilan n'a pas été créer
                if(this.bilanNHasValues)
                {
                    this.selectedBilanItemLocal['inforiskJson'] = JSON.stringify(this.jsonResult);
                    this.selectedBilanItemLocal = await this.createBilan(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan)
                    this.createBilanFlag = false;
                }
                else
                {
                    this.toastWarn("Veuillez saisir au moins une valeur dans le bilan actuel!");
                    this.isSaving = false;
                    //le return empeche la création de l'année N-1 tant que l'année N n'a pas été créée
                    return;
                }
                
            }

            // ==================== Save Bilan N-1 ===================
            // Mode edition du N-1
            if(this.selectedBilanItemLocalAnneeN1.id)
            { 
                // Autorise la MAJ du N1 uniquement s'il a été créé en N1 (le flag EstAnneePrecedente a été créé pour ce besoin), 
                // si le bilan N-1 vient de "inforisk" ou il a été créé dans la partie N (avec brut/amortProv/Precedent), il faut interdire la MAJ 
                if(this.selectedBilanItemLocalAnneeN1.estAnneePrecedente)
                {
                    try{
                        var response = await CRUDService.update(this.selectedBilanItemLocalAnneeN1,'bilanfinancierclient')
                        this.log(response.data)
                        this.toastSuccess("Le bilan "+ response.data.dateBilan +" a été enregistré avec succès");
                        this.isSaving = false;
                    }
                    catch(error)
                    {
                        this.toastError("Echec sauvegarde de l''enregistrement !");
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                        this.toastError(this.errorMessage);
                        this.isSaving = false;
                        return;
                    }
                    
                }
            }
            // mode creation
            else if(this.bilanN1HasValues)
            {
                // Si le bilan N1 n existe pas, il faut le creer uniquement s'il a des valeurs saisies
                this.selectedBilanItemLocalAnneeN1.societeId = this.selectedBilanItemLocal.societeId
                this.selectedBilanItemLocalAnneeN1.dateBilan = (parseInt(this.selectedBilanItemLocal.dateBilan)-1).toString();
                this.selectedBilanItemLocalAnneeN1.estAnneePrecedente = 1
                var ref = this;

                try
                {
                    var response = await CRUDService.create(this.selectedBilanItemLocalAnneeN1,'bilanfinancierclient')

                    this.log("create N1 response:" + response.data);
                            
                    this.toastSuccess("Le bilan "+ response.data.dateBilan +" a été créé avec succès");
                    
                    this.isSaving = false;
                            
                }
                catch(error)
                {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    this.toastSuccess("Echec sauvegarde de l''enregistrement !");
                    this.toastSuccess(this.errorMessage);
                    this.isSaving = false;
                }
                // 
                
            }

            // inform the upper component of the end of the save to close the modal or not
            this.$emit('saved',isUpdating);

        },
        confirmDeleteSelected(selectedItem){
            var ref = this;
            if(this.deleting)
            {
                
                return
            }
            
            this.log("confirmDeleteSelected: " + selectedItem);

            this.$confirm.require({
                message: 'Etes-vous sûr de vouloir supprimer le bilan ?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptLabel:'Oui',
                rejectLabel:'Non',
                acceptClass: 'p-button-danger',
                accept: async() => {
                    await this.deleteBilanItem();
                    ref.deleting = true
                },
                reject: () => {
                    this.$confirm.close();
                    ref.deleting = true
                }
            });
        },
        deleteBilanItem()
            {
                this.log("deleteBilanItem");
            CRUDService.delete(this.selectedBilanItemLocal,'bilanfinancierclient').then(
                (response) => {
                    this.log(response.data);
                    this.toastSuccess("Bilan supprimé avec succès");
                    this.$emit('saved',true);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        onBilanLoaded: function(e)
            {
                var event = e;
            var  ref = this;
            var jsonToAdd = {};
            this.log("this.myValidationSchema")
            // var validation = Object.keys(this.myValidationSchema.fields)
            // var validationsToRemove = ["actifNonCirculantAuto","fondDeRoulementAuto","pourcentageEbitdaAuto"]
            // for(var i=0;i<validationsToRemove.length;i++)
            // {
            //     var elemToRemove = validationsToRemove[i];
            //     var ind = validation.indexOf(elemToRemove)
            //     if(ind!=-1)
            //     {
            //     validation.splice(ind,1);
            //     }
            // }

            var validation = null

            CsvToDb.Upload(e.files[0],jsonToAdd,validation).then((response) => { //@todo nouveau parametre non accepté
                $(event.target).val("");
                console.log(response);

                for (const property in jsonToAdd) {
                    var propertyName = property.charAt(0).toLowerCase() + property.slice(1);
                    // if(propertyName == "dateBilan")
                    // {
                    //     var dateBilanString = jsonToAdd[property];
                    //     // var dateBilanMoment = this.parseDateAsMoment(dateBilanString);

                    //     // ;
                    //     // var dateBilan = new Date(dateBilanMoment.toDate());

                    //     // jsonToAdd[property] = dateBilan;

                    //     // if(dateBilanMoment.toString()=='Invalid date')
                    //     // {
                    //     //     jsonToAdd[property] = new Date()
                    //     // }
                        
                        
                    // }
                    if(propertyName != 'dateBilan')
                    {
                        ref.selectedBilanItemLocal[propertyName] = jsonToAdd[property]
                    }
                    
                }

            },
            (error) => {
                console.log(error)
                if(error && error.title == "validation")
                {
                    ref.toastWarn("Validations manquantes: "+error.message);
                }
            })
        },
        downloadItem (url) {
            Axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = "ModeleImportBilan.csv"
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }
    }
}
</script>

<style>
    .p-inputnumber-input
    {
        height:25px
    }

    .bilanHead
    {
        background-color: rgb(227, 227, 227);

    }

    .bilanHead .p-inputnumber-input,.bilanTotal .p-inputnumber-input
    {
        font-weight:bold
    }

    .bilanTotal
    {
        background-color: gray;
        color: white;
    }
    .bilanTotalGeneral
    {
        background-color: black;
        color: white;

    }
    .bilanMargeVerticale
    {
        border: solid 1px;
        width:5%;
        font-weight: bold;
        color: white;
        font-size: 0.7rem;
    }

    .bilanActif .YearN1
    {
        border-left: solid 2px;
        border-right: solid 2px;
        border-color:#2195f3;
        
    }

    .bilanPassif .YearN1
    {
        border-left: solid 2px;
        border-right: solid 2px;
        border-color:rgb(243, 23, 0);
        
    }

    .bilanCPC .YearN1
    {
        border-left: solid 2px;
        border-right: solid 2px;
        border-color:rgb(55, 180, 0);
        
    }

    /* First Col */

    .bilanActif .titleCol
    {
        border-right: solid 2px;
        border-color:#2195f3;
    }

    .bilanPassif .titleCol
    {
        border-right: solid 2px;
        border-color:rgb(243, 23, 0);
    }

    .bilanCPC .titleCol
    {
        border-right: solid 2px;
        border-color:rgb(55, 180, 0);
    }

    /* ----------- */

    .bilanActif .bilanMargeVerticale{
        background-color: #2195f3;
        border-color: gray !important;
    }

    .bilanPassif .bilanMargeVerticale{
        background-color: rgb(243, 23, 0);
        border-color: gray !important;
    }

    .bilanCPC .bilanMargeVerticale{
        background-color: rgb(55, 180, 0);
        border-color: gray !important;
    }

    .titleGroupCol
    {
        font-weight:bold; 
    }

    .titleCol{
        padding-left:10px;
    }

    .k-tabstrip-items .k-state-active
    {
        background-color:lightgrey !important;
        border-color:none !important;
        border-top-width:0px !important;
        border-left-width:0px !important;
        border-right-width:0px !important;
    }

    .p-password .pi-eye
    {
        margin-right:20px
    }

    .p-password .pi-eye-slash
    {
        margin-right:20px
    }

    .YearN1 div, .YearN div{
        display:flex;
    }
</style>