<!-- ########################## <Template> ########################## -->
<template>
 

    
    <Dialog ref="refInforiskLogs"  @hide="fnClose" :closable="false"
                   v-bind:visible="true" :style="{width: '1200px',initialHeight:'380px'}" 
                   :header="'Logs Inforisk'" :modal="true">
                   <template #header>
                    <button label="Exporter en excel" tooltip="Export scores/Societes" icon="pi pi-file-excel" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click.prevent="exportExcel" >Exporter en excel</button>
                    <div class="p-d-flex p-jc-end" style="width:100%">
                        <button class="btn btn-danger btn-block"  @click.prevent="fnClose" style="float:right">
                            Fermer
                        </button> &nbsp;

                    </div>
                   </template>
                   <div v-if="showJson">
                    <button class="btn btn-danger btn-block"  @click.prevent="showJson = false">
                        Revenir
                    </button> &nbsp;
                    <TabView>
                        <TabPanel :header="'Vue Bilan'">
                            <LoadPdfBilans>
                                <div v-for="(bilanType,index) in bilanTypes" :key="index">
                                    <hr v-if="index>0">
                                <h5>{{ bilanType.name }}</h5>
                                    <div :class="'bilan'+bilanType.name" style="font-size: 12px;">
                                        <table>
                                            <tr>
                                                <th style="width:85%"></th>
                                                <th style="width:50px"></th>
                                            </tr>
                                            <tr v-for="property in formuleInfo.filter(function(d){
                                                return d.bilanType == bilanType.name
                                            })" :key="property.id">
                                                <td style="border:solid 1px lightgrey;" :style="property.style" >{{property.nom}}</td>
                                                <td style="border:solid 1px lightgrey;text-align: center;cursor:pointer" :key="property.codeTechnique" flow="left"  >
                                                    <!-- {{ bilanType.value }}
                                                    {{ property.codeInforisk }}
                                                    {{ jsonData[bilanType.value] }} -->
                                                    <span v-if="jsonData[bilanType.value] && jsonData[bilanType.value].hasOwnProperty(property.codeInforisk)">{{jsonData[bilanType.value][property.codeInforisk]}}</span>
                                                    <!-- <span v-else>0</span> -->
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </LoadPdfBilans>
                        </TabPanel>
                        <TabPanel :header="'Vue json'">
                            <JsonViewer :value="jsonData" copyable boxed sort theme="dark"/>
                        </TabPanel>
                    </TabView>
                    
                   </div>
                   <div v-else>

                    <div style="display: inline-block; width:500px" v-if="isAdmin">
                            Filtre client: <Dropdown   v-model="selectedFilter" 
                                        :options="clients" 
                                        optionLabel="raisonSociale" 
                                        optionValue="id" 
                                        placeholder="" 
                                        @change="search"/>
                    </div>
                    <br/>

                   <TabView>
                        <TabPanel :header="'Logs bilans ('+logs.length+')'">
                            <DataTable :value="logs" responsiveLayout="scroll">
                                
                                <Column field="client" header="Client"></Column>
                                <Column field="societeascorer" header="Societe"></Column>
                                <Column field="societeInforisk" header="Societe Inforisk"></Column>
                                <Column field="ice" header="ICE"></Column>
                                <Column field="anneeBilan" header="Annee bilan"></Column>
                                <Column field="existe" header="Existe">
                                    <template #body="slotProps">
                                        <Tag @click="showJsonDialogBilan(slotProps.data)" v-if="slotProps.data.existe==true" severity="success" value="Bilan existant" style="text-align: center;cursor: pointer" rounded></Tag>
                                        <Tag v-else severity="danger" value="Bilan non existant" style="text-align: center;" rounded></Tag>
                                        
                                    </template>
                                </Column>
                                <Column field="dateCreation" header="Date creation">
                                <template #body="slotProps">
                                    {{formatDatetime(slotProps.data.dateCreation)}}
                                </template>
                                </Column>
                                <Column field="createur" header="Demandeur"></Column>
                            </DataTable>
                        </TabPanel>
                        <TabPanel :header="'Logs recherches entreprise ('+logsRecherche.length+')'">
                            <DataTable :value="logsRecherche" responsiveLayout="scroll">
                                <Column field="client" header="Client"></Column>
                                <Column field="denomination" header="Dénomination"></Column>
                                <Column field="ice" header="ICE"></Column>
                                <Column field="valide" header="Validé">
                                    <template #body="slotProps">
                                        <Tag @click="showJsonDialogEntreprise(slotProps.data)" v-if="slotProps.data.valide==true" severity="success" value="Entreprise validée" style="text-align: center;cursor:pointer" rounded></Tag>
                                        <Tag v-else severity="danger" value="Entreprise non validée" style="text-align: center;" rounded></Tag>
                                        
                                    </template>
                                </Column>
                                <Column field="dateCreation" header="Date creation">
                                <template #body="slotProps">
                                    {{formatDatetime(slotProps.data.dateCreation)}}
                                </template>
                                </Column>
                                <Column field="createur" header="Demandeur"></Column>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                    </div>
                    <!--content here -->

                    <!--  -->
                    <div>{{errorMessage}}</div>
                    <!--  -->
                    

                <br/>
                <div class="p-d-flex p-jc-end">
                    
                    <button class="btn btn-danger btn-block"  @click.prevent="fnClose">
                        Fermer
                    </button> &nbsp;

                </div>

    </Dialog>

</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------

import { Button } from '@progress/kendo-vue-buttons';

import LoadPdfBilans from "../3-client-components/LoadPdfBilans.vue";
import Password from 'primevue/password';
import AuthService from '../0-auth-components/auth.service';
import CRUDService from "../1-common-components/crud.service";
import Tag from 'primevue/tag';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import {JsonViewer} from "vue3-json-viewer"
// if you used v1.0.5 or latster ,you should add import "vue3-json-viewer/dist/index.css"
import "vue3-json-viewer/dist/index.css";
import { saveExcel } from '@progress/kendo-vue-excel-export';

export default {
    //---------------------------- props ------------------------------
    
    //--------------------------- Components --------------------------
    components: {
        Password,
        Tag,
        TabView,
        TabPanel,
        JsonViewer,
        LoadPdfBilans
    },
    //--------------------------- Data --------------------------
    data() {
        return {
            logs:[],
            logsRecherche:[],
            errorMessage:"",
            showJson:false,
            jsonData:{},
            selectedFilter:0,
            clients:[],
            formuleInfo:[],
            bilanTypes:[
                {name:"Actif",value:"_stActif"},
                {name:"Passif",value:"_stPassif"},
                {name:"CPC",value:"_stCPC"}]
        } // end result
    }, //end data()
    props:['isAdmin'],
    //--------------------------- emits -----------------------------
    emits: ["close"],
    //--------------------------- computed --------------------------
    computed:
    {
        
    },
    //--------------------------- Mounted --------------------------
    mounted() {
        this.log("----------- LogInforisk  mounted()");

        if(this.isAdmin)
        {
            CRUDService.list('clientscustom').then( //ActiverWorkflowValidation
                (response) => {
                    this.log(response.data);
                    this.clients = response.data;
                    this.clients.push({id:0,raisonSociale:"Tout"})
                    
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
            var ref = this;
            CRUDService.list('BilanListeChamps').then(function(response){
                if(response.data)
                {
                    // 
                    ref.formuleInfo = response.data
                }
                else
                {
                    alert(response.data)
                }
            }).catch(function(err){
                console.log(err)
                alert("Erreur modéle Vue bilan,Veuillez consulter la vue json")
            })
            
        }
        
        
        CRUDService.list('currentclientLogs/0').then(
                (response) => {
                    var ref = this;
                    this.log(response.data);
                    this.logs = response.data.map(function(d){
                        var obj = {...d}
                        obj['dateCreation'] = ref.convertUTCDateToLocalDate(obj['dateCreation'])
                        return obj
                    });
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        
        CRUDService.list('currentclientLogsRecherche/0').then(
            (response) => {
                var ref = this;
                this.log(response.data);
                this.logsRecherche = response.data.map(function(d){
                        var obj = {...d}
                        obj['dateCreation'] = ref.convertUTCDateToLocalDate(obj['dateCreation'])
                        return obj
                    });
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
        );

            

  }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- LogInforisk created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        search() {
            this.log("----------- LogInforisk  mounted()");

            CRUDService.list('currentclientLogs/'+this.selectedFilter).then(
                    (response) => {
                        ;
                        this.log(response.data);
                        this.logs = response.data;
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
            
            CRUDService.list('currentclientLogsRecherche/'+this.selectedFilter).then(
                (response) => {
                    ;
                    this.log(response.data);
                    this.logsRecherche = response.data;
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );

            

        },
        exportExcel : function() {
          let ref=this;
          
        //   CRUDService.list("currentclientLogs/0").then(function(result){
       
              let resultData = this.logs;
              var CountResults = resultData.length;

              if(CountResults >0)
              {
                const data = resultData.map(function(d){
                  Object.keys(d).forEach(function(attr){

                    if(attr.includes('date'))
                    {
                      d[attr] = new Date(d[attr])
                    }
                    
                  })
                  return d

                })

                const fileName = 'ConsultationLogsInforisk';
                // const exportType =  exportFromJSON.types.xls;

                // exportFromJSON({ data, fileName, exportType });
                
                var columns = Object.keys(resultData[0]).map(function(d){
                  return { field: d, title: d}
                });

                saveExcel({
                    data: data,
                    fileName: fileName,
                    columns: columns
                });
                
              }
              else
              {
                console.error("ExportExcel data failed loaded: "+resultData);
                ref.toastError(resultData);
              }
            // })
        },
        showJsonDialogEntreprise: function(selectedItem)
        {
            if(this.isAdmin)
            {
                this.showJson = true
                CRUDService.get(selectedItem.id,'getLogRechercheById').then(
                    (response) => {
                        ;
                        this.log(response.data);
                        this.jsonData = JSON.parse(response.data.json);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
            }
        },  
        showJsonDialogBilan: function(selectedItem)
        {
            if(this.isAdmin)
            {
                this.showJson = true
                CRUDService.get(selectedItem.id,'getLogBilanById').then(
                    (response) => {
                        ;
                        this.log(response.data);
                        this.jsonData = JSON.parse(response.data.json);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
            }
            
        },
        fnClose: function()
        {
            this.$emit('close');
        },
      

    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >

.green
{
    color:green;
}

.red
{
    color:red;
}

</style>