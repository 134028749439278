<template>
    Export PDF:
    <div id="pdfTemplateBilan" class="container">
        <slot></slot>
    </div>
    &nbsp;<P-Button label="Imprimer" icon="pi pi-print" class="p-button-raised p-button-secondary"  @click.prevent="drawDom('Bilan.pdf')" />
</template>

<script>
import { PDFExport, savePDF } from "@progress/kendo-vue-pdf";

export default {
    
  name: "LoadPdfBilans",
  components: {
    PDFExport
  },
  emits: [''],
  data() {
    return {
    };
  },
  mounted()
  {
  },
  computed:{
  },
  methods:{
    exportPDFWithComponent: function() {
      // this.$refs.defineFont({
      //   "Camaro": "./assets/fonts/Camaro.otf",
      // });
      this.$refs.PdfExport.save();
    },
    drawDom: function(filename)
    {
      kendo.drawing.drawDOM($("#pdfTemplateBilan")).then(function (group) {
        return kendo.drawing.exportPDF(group, {
            // margin: { left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" },
            // paperSize:"A4",
            // scale:0.5,
            // multiPage:true,
            // forcePageBreak:'.pageBreak',
            landscape:false
        });
      })
      .done(function (data) {
            // Save the PDF file
            kendo.saveAs({
                dataURI: data,
                fileName: filename,
            });
      })

    },
    
  }
};
</script>

<style>


</style>