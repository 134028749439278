<!-- ########################## <Template> ########################## -->
<template>
 
 <ClientBilanDialog :inforisk="false" :key="selectedBilanItem.id+showBilanDialogFlag" :selectedBilanItemProp="selectedBilanItem" :showBilanDialogFlag="showBilanDialogFlag" @saved="savedBilanDialog" @close="closeBilanDialog" :societe="selectedSociete"></ClientBilanDialog>
                    <!--content here -->
                    <DataTable :value="logs" responsiveLayout="scroll"
                    :selection="selectedBilanItem" selectionMode="single" 
                    dataKey="id"
                    @rowSelect="onRowSelect">

                        <!-- <Column header="">
                        <template #body="slotProps">
                            <i style="cursor:pointer" class="pi pi-pencil" @click="openBilan(slotProps.data)"></i>
                        </template>
                        </Column> -->

                        <Column field="dateBilan" header="Année">
                        </Column>

                        <Column field="dateCreation" header="Date creation">
                        <template #body="slotProps">
                            {{formatDatetime(slotProps.data.dateCreation)}}
                        </template>
                        </Column>

                        <Column field="dateModification" header="Date modification">
                        <template #body="slotProps">
                            {{formatDatetime(slotProps.data.dateModification)}}
                        </template>
                        </Column>

                        <Column field="createur" header="Créé par"></Column>
                        <Column field="modificateur" header="Modifié par"></Column>
                        
                        <Column field="inforisk" header="Source">
                            <template #body="slotProps">
                                <Tag v-if="slotProps.data.inforisk==1" severity="success" value="Inforisk" rounded></Tag>
                                <Tag v-else-if="slotProps.data.ompic==1" severity="secondary" value="Ompic" rounded></Tag>
                                <!-- <Tag v-else-if="slotProps.data.ompic==2" severity="secondary" value="Dupliqué Ompic" rounded></Tag>
                                <Tag v-else-if="slotProps.data.inforisk==2" severity="secondary" value="Dupliqué inforisk" rounded></Tag> -->
                                <Tag v-else severity="primary" value="Manuel" rounded></Tag>
                            </template>
                        </Column>

                        <!-- <Column>
                            <template #body="slotProps">
                                <P-Button icon="pi pi-trash" class="p-button-outlined p-button-rounded p-button-danger p-mr-2" @click="deleteBilan(slotProps.data)" />
                            </template>
                        </Column> -->
                        
                    </DataTable>

                    <!--  -->
                    <div>{{errorMessage}}</div>
                    <!--  -->
                    

</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------

import CRUDService from "../.././1-common-components/crud.service";
import Tag from 'primevue/tag';
import ClientBilanDialog from "../ClientScoring/ClientBilanDialog.vue";

export default {
    //---------------------------- props ------------------------------
    
    //--------------------------- Components --------------------------
    props:['selectedSociete'],
    components: {
        Tag,
        ClientBilanDialog
    },
    //--------------------------- Data --------------------------
    data() {
        return {
            showBilanDialogFlag:false,
            selectedBilanItem:{id:0},
            logs:[],
            errorMessage:""
        } // end result
    }, //end data()
    //--------------------------- emits -----------------------------
    emits: ["close"],
    //--------------------------- computed --------------------------
    computed:
    {
        
    },
    //--------------------------- Mounted --------------------------
    mounted() {
        this.log("----------- LogInforisk  mounted()");
        var ref = this;
        CRUDService.get(this.selectedSociete.id,'allbilanfinancierbysociete').then(
                (response) => {
                    
                    this.log(response.data);
                    this.logs = response.data.map(function(d){
                        var obj = {...d}
                        obj['dateCreation'] = ref.convertUTCDateToLocalDate(obj['dateCreation'])
                        return obj
                    });
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );

  }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- LogInforisk created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        deleteBilan(selectedBilan)
        {
            CRUDService.delete(selectedBilan,'bilanfinancierclient').then(
                (response) => {
                    this.log(response.data);
                    this.toastSuccess("Bilan supprimé avec succès");
                    this.loadGrid()
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        loadGrid()
        {
            CRUDService.get(this.selectedSociete.id,'allbilanfinancierbysociete').then(
                (response) => {
                    
                    this.log(response.data);
                    this.logs = response.data;
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        openBilan(selectedBilan)
        {
            this.showBilanDialogFlag = true;
            // this.selectedBilanItem = selectedBilan;
        },
        onRowSelect(e)
        {
            
            this.selectedBilanItem = e.data
            this.showBilanDialogFlag = true;
        },
        fnClose: function()
        {
            this.$emit('close');
        },
        closeBilanDialog()
        {
            this.selectedBilanItem = {id:0}
            this.showBilanDialogFlag = false
        },
        savedBilanDialog()
        {
            this.selectedBilanItem = {id:0}
            this.showBilanDialogFlag = false
            debugger;
            this.loadGrid();
        },
      

    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >

.green
{
    color:green;
}

.red
{
    color:red;
}

</style>